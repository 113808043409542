
import { Dialog, DialogContent, IconButton, makeStyles, Slide, Typography } from "@material-ui/core"
import React, {useEffect, useState} from "react"

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import { CalculadoraCreate } from "../calculadoraCreate/steps/calculadoraCreate";
import { StartOfCalc } from "../calculadoraCreate";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

//My style
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperModal: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #2c8aaf',
        width: 450,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 4, 5),
    },
    seeMore: {
        marginTop: theme.spacing(3),
    },
    div: {
        margin: '0 auto',
        minWidth: '40%',
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        overflow: 'auto',
        margin: '0 auto',
        flexDirection: 'column',
    },
    paperPhoto: {
        padding: theme.spacing(0),
        display: 'flex',
        margin: '0 auto',
        maxWidth: '500px',
        overflow: 'auto',
        flexDirection: 'column',
    },
    btnFactura: {
        margin: '0px !important',
        textAlign: 'center',
        justifycontent: 'center'
    },
    button: {
        color: '#ff0000 !important',
    },
    img: {
        maxWidth: '1000px',
    },
    inputPerson: {
        marginTop: '15px',
        fontSize: "18px"
    },
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    errorLabel: {
        color: "red",
        padding: 0,
        paddingLeft: 34,
        paddingTop: 5
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(2),
        position: 'relative',
        // paddingBottom: 20

    },
}));

const DialogTitle = ((props) => {
    const classes = useStyles();
    const { children, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{ textAlign: "center" }}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function ModalCalculadoraCreate({ setopenMondal, isOpen, isCatalogo, catalogos=[] }) {

    return (
        <div>
            <Dialog
                onClose={(e) => {  setopenMondal(false) }}
                aria-labelledby="customized-dialog-title"
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                style={{ maxHeight: "500%" }}
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle id="customized-dialog-title" onClose={(e) => { setopenMondal(false) }} >
                    Calculadora
                </DialogTitle>
                <DialogContent style={{paddingBottom: '30px'}}>
                <StartOfCalc 
                    setopenMondal={setopenMondal}
                    isCatalogo={isCatalogo}
                    catalogos={catalogos}
                />
                </DialogContent>
            </Dialog>
        </div>
    )
}

