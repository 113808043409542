import React from 'react';
import PropTypes from 'prop-types';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';

import useStyles from './UseStyle';

import MenuItem from './MenuItem';
import withAuthentication from '../../../user/componet/session/withAuthentication';



function ListMenuItem({_menus}) {
    const classes = useStyles();

    return (

        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                </ListSubheader>
            }
            className={classes.root}
        >
            {_menus.map((m) => {
                return (
                    <MenuItem {...m} key={m.id}/>
                );
            })}

        </List>

    );
}

ListMenuItem.propTypes = {

}

export default ListMenuItem

