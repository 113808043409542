import React, { useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { store } from 'react-notifications-component';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { useDispatch } from 'react-redux';
import { HelpValidate } from '../../user/utils/helpValidate';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Modal,
  Backdrop,
  TextField,
  Button,
  Fade,
  InputAdornment
} from '@material-ui/core';
import {
  reporteRecudo,
} from '../domain/api/PrestapolisApi'
//My style
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #2c8aaf',
    width: 400,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 4, 3),
  },
}));

export default function ModaleExportarReporte({ isOpen, setopenMondal, fecha_Ini, fecha_Fin, id_sucursal, nameSucursal, page, size, id_comercio, filter, }) {

  const classes = useStyles();
  const [isLoadUser, setisLoadUser] = useState(false)
  const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
  const [correo, setCorreo] = useState("");
  const dispatch = useDispatch();
  const handleSubmit2 = (event) => {
    event.preventDefault();
  };
  let intervalRef = useRef();
  const onCerrar = () => {
    clearInterval(intervalRef.current)
    setopenMondal(false)
  }
  const ClearForm = () => {
    setCorreo("")
  }
  //Metodo para exportar excel
  const postExportarReporteRecaudo = () => {
    setisLoadUser(true);
    let request = {
      comercio: id_comercio,
      page: page,
      size: "1000",
      sucursal: id_sucursal,
      fechaInicial: fecha_Ini,
      fechaFinal: fecha_Fin,
      email: correo,
      filter: filter
    }
    reporteRecudo(dispatch, request)
      .then((response) => {
        if (response.codigoRespuesta === "0000") {
          setisLoadUser(false);
          store.addNotification({
            title: "El reporte será enviado a su correo cuando se encuentre listo",
            message: "----------------",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 6000,
            }
          });
          onCerrar();
          ClearForm();
        } else {
          store.addNotification({
            title: "Error",
            message: response.mensaje.replace("codigo", "código"),
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 7500,
            }
          });
          setisLoadUser(false)
        }
      })
      .catch((err) => {
        
        store.addNotification({
          title: "Error",
          message: "Error de envío de reporte, por favor comunicarce con soporte@prestapolils.com",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 9500,
          }
        });
        setisLoadUser(false)
      })
  }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={(e) => {
          onCerrar();
        }}
        className={classes.modal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paperModal}>
            <Grid container spacing={1} alignItems="center" textAlign="center">
              <Grid item xs={12}>
                <form autocomplete="off" onSubmit={handleSubmit2}>

                  <Grid container spacing={1} alignItems="center" className={classes.inputPerson} >
                    <Grid container justify="center" style={{ marginTop: '10px' }}>
                      <label style={{ fontWeight: 700, fontSize: 25 }}>Envío de reporte</label>
                      <div>
                        <p>
                          Indique en que correo desea recibír su reporte
                        </p>
                      </div>
                    </Grid>
                    <Grid container justify="center">
                      <TextField
                        id="correo"
                        startAdornment={<InputAdornment position="start"><AlternateEmailIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                        labelWidth={150}
                        fullWidth
                        autocomplete="off"
                        name="correo"
                        value={correo}
                        variant="outlined"
                        type="email"
                        {...HelpValidate().formatEmailGeneric(register('correo', {
                          required: "Email requierido.",
                          minLength: {
                            value: 2,
                            message: 'Por favor ingresa un email valido'
                          },
                          pattern: { value: /^\S+@\S+$/i, message: "Formato no valido" }
                        }), setError, clearErrors, setCorreo)}
                        helperText={errors.correo?.message}

                      />

                    </Grid>
                  </Grid>

                  <Grid container xs spacing={2} textAlign="center" justifyContent="space-between" style={{ marginTop: '10px' }}>
                    <Grid item xs={6} spacing={1}  >
                      <Button
                        fullWidth={true}
                        variant="contained"
                        size="large"
                        className={classes.margin}
                        color="secondary"
                        onClick={(e) => {
                          onCerrar()
                        }}
                        style={{ textTransform: "none", fontSize: 14, color: "white" }}
                      >
                        Cerrar
                      </Button>
                    </Grid>
                    <Grid item xs={6} >
                      <Button
                        type="submit"
                        fullWidth={true}
                        variant="contained"
                        size="large"
                        className={classes.margin}
                        color="primary"
                        style={{ textTransform: "none", fontSize: 14 }}
                        onClick={(e) => {
                          postExportarReporteRecaudo();
                        }}
                      >
                        Enviar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
