//React 
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';

//Redux
import { ID_USER, ID_SUCURSAL, ID_COMERCIO, NAME_COMERCIO, BAAS } from "../../app/api-client/constants";
import { useDispatch } from 'react-redux';

//Icons Material
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

//Components Material
import { makeStyles } from '@material-ui/core/styles';
import SearchBar from "material-ui-search-bar";
import {
    Paper,
    Grid,
    Button,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    MenuItem,
    InputLabel,
    Input,
    FormControl,
    NativeSelect,
    Select,
    TableContainer,
} from '@material-ui/core';

//Local Component
import Paginator from '../utils/paginator';
import Title from '../../app/orussystem/component/Title';
import MigaPan from '../../app/orussystem/component/MigaPan';
import { getVendedoresSucursal, updateVendedores, createVendedor, createUserVendedor, getUsersByIdBranch, geListSucuralById } from '../domain/api/PrestapolisApi'
import ModalVendedor from '../modals/modalVendedor';
import ModalVendedorEdit from '../modals/modalVendedorEdit'


const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        paddingTop: 10,
        display: "flex",

    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center",
    }
}));

const listRol = [
    {
        id: 3,
        nombre: "Vendedor",
        name: "ROLE_SELLER",
    },
    {
        id: 1,
        nombre: "Admin Comercio",
        name: "ROLE_COMMERCE_ADMIN",
    },
    {
        id: 2,
        nombre: "Admin Sucursal",
        name: "ROLE_BRANCH_ADMIN",
    },
]




export const ListaVendedores = ({ }) => {

    const classes = useStyles();
    const [isLoadUser, setisLoadUser] = useState(false)
    const [data, setData] = useState([])
    const [totalElements, settotalElements] = useState(0)
    const [totalPage, settotalPage] = useState(0)
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(10)
    const [filter, setFilter] = useState("")
    const [isOpenModalEdit, setisOpenModalEdit] = useState(false)
    const [isOpenModalCreate, setisOpenModalCreate] = useState(false)
    const [dataEdit, setdataEdit] = useState({})
    const [listIdSucursal, setListIdSucursal] = useState([])
    const [idSucursalSelect, setidSucursalSelect] = useState("")
    const [listSucursalSelect, setlistSucursalSelect] = useState("")
    let nameComercio = localStorage.getItem(NAME_COMERCIO)
    let baas = localStorage.getItem(BAAS)
    let namecomerce = baas != "true" ? 'Home' : nameComercio
    let { id } = useParams();
    let idSucursal = id != ":id" && id != null && id != undefined ? id : localStorage.getItem(ID_SUCURSAL);
    let idComercio = localStorage.getItem(ID_COMERCIO);

 


    const history = useHistory();
    const dispatch = useDispatch();

    const handleRowClick = (row) => {
        history.push(`/vendedores/editar/${row.numerodocumento}`);
    }

    const getVendedor = (id, page, size, filter) => {
        setisLoadUser(true);
        getUsersByIdBranch(dispatch, id, page, size, filter)
            .then((response) => {
                if (response.code === "0000") {
                    let totalPages = response.totalPages;
                    let totalElements = response.totalElements;
                    let data = response.content;
                    setData(data);
                    settotalElements(totalElements);
                    settotalPage(totalPages);

                    setisLoadUser(false);
                } else {
                   
                    setisLoadUser(false)
                }
            })
            .catch((error) => {

                setisLoadUser(false)
                store.addNotification({
                    title: "Alerta",
                    message: "Por favor selecciona unsa sucursal ",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeOut"],
                    animationOut: ["animate__animated", "animate__fadeIn"],
                    dismiss: {
                        duration: 3500,
                        // onScreen: true
                    }
                });
            });

    };

    const getSucursalList = () => {
        geListSucuralById(dispatch, idSucursal)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    setListIdSucursal(response.respuesta)
                }
            }).catch((err) => {
                
            })
    }

    const SetDataAsync = (setState, item) => {
        return new Promise((resolve, reject) => {
            resolve(setState(item));
        })
    }

    const EditVendor = (data) => {
        SetDataAsync(setdataEdit, data).then((response) => {
            setisOpenModalEdit(true)
        })
    }

    //Metodos para editar vendedor
    const onSubmiEdit = (data) => {
       
        let request = {
            
                id: dataEdit.id,
                apellidos: data.apellidos,
                primerNombre: data.names,
                nombrecompleto: data.names,
                login:data.names,
                numerocedula: data.idNumber,
                email: data.email,
                celular: data.cellPhone,
                estado: data.estado,
                recaudo:data.recaudo               
        
           

        }

        updateVendedor(request)
    }

    //Metodo para crear vendedores
    const onSubmiCreate = (data) => {


        let nameRol = ""
        let nameCargo = ''

        listRol.map((item) => {
            if (item.id === 3) {
                nameRol = item.name;
                nameCargo = item.nombre;
            }
        })


        let request = {
            usuariosComercio: {
                nombreCompleto: data.names + " " + data.apellidos,
                celular: data.cellPhone,
                email: data.email,
                numerocedula: data.idNumber,
                comercio: {
                    id: idComercio
                },
                sucursales: idSucursalSelect.toString(),
                primerNombre: data.names,
                segundoNombre: "",
                apellidos: data.apellidos,
                tipoDocumento: "CC",
                estado: "A",
                rol: {
                    id: 3,
                    rol: nameRol
                },
                cargo: nameCargo
            },
            user: {
                username: data.names,
                name: data.names,
                email: data.email,
                password: data.idNumber,
                rol: nameRol,
                celular: data.cellPhone,
                numerodocumento: data.idNumber,
                comercio: idComercio,
                primernombres: data.names,
                segundonombre: "",
                apellido: data.apellidos,
                tipoDocumento: "CC"
            },
        }

        CreateVendor(request)
    }

    const CreateVendor = (data) => {
        createUserVendedor(dispatch, data)
            .then((response) => {
                if (response.respuesta) {
                    store.addNotification({
                        title: "Exito!",
                        message: "Vendedor creado con exito",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        setisOpenModalCreate(false)
                        getVendedor(idSucursalSelect, 0, size, "")
                    }, 2800);

                } else {
                    store.addNotification({
                        title: "Error",
                        message: response.mensaje,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3500,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        // setisOpenModalCreate(false)
                        getVendedor(idSucursalSelect, 0, size, "")
                    }, 3500);
                }
            })
            .catch((err) => {
               
            });
    }


    const updateVendedor = (recuest) => {
        updateVendedores(dispatch, recuest)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    store.addNotification({
                        title: "Exito!",
                        message: "Se han editado los datos satisfctoriamente",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3500,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        setisOpenModalCreate(false)
                        getVendedor(idSucursalSelect, 0, size, "")
                    }, 3500);
                } else {
                    store.addNotification({
                        title: "Error",
                        message: "Error al editar el vendedor revise los datos",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3500,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        setisOpenModalCreate(false)
                        getVendedor(idSucursalSelect, 0, size, "")
                    }, 3500);
                }
            })
            .catch((err) => {
            
            });
    }

    const filtrar = (e) => {
        if (e === "") {
            getVendedor(idSucursalSelect, page, size, filter)
        } else {
            getVendedor(idSucursalSelect, page, size, e)
        }
    }


    useEffect(async () => {

        if(idSucursal){
            getVendedor(idSucursal, page, size, filter)
            setidSucursalSelect(idSucursal);
        }else{
            getVendedor(idSucursalSelect, page, size, filter)
        }
        
        getSucursalList();
    }, [page, idSucursalSelect]);

    return (
        <>

           {isOpenModalEdit &&  <ModalVendedorEdit
                isOpen={isOpenModalEdit}
                setopenMondal={setisOpenModalEdit}
                onSubmit={onSubmiEdit}
                data={dataEdit}
                setData={setdataEdit}
                id_sucursal={idSucursal}
            />
            }

            <ModalVendedor
                isOpen={isOpenModalCreate}
                setopenMondal={setisOpenModalCreate}
                onSubmit={onSubmiCreate}
            >
            </ModalVendedor>
            <Grid item xs={12} >
                <MigaPan namePage="Vendedores" to="/" >
                {namecomerce}
                </MigaPan>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6} ></Grid>
                <Grid item xs={6} sm={4} md={4} style={{ textAlign: "right", paddingBottom: "25px" }}>
                    <FormControl variant="outlined" align="left" style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-outlined-label">Sucursal</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            name="sucursal"
                            align="Left"
                            autocomplete="off"
                            fullWidth
                            value={idSucursalSelect}
                            defaultValue={idSucursalSelect}
                            onChange={(e) => { setidSucursalSelect(e.target.value) }}
                            label="Sucursal"
                        >
                            <MenuItem value="">
                                <em>Seleccione sucursal</em>
                            </MenuItem>

                            {listIdSucursal.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={2} md={2} style={{ textAlign: "right"}}>
                    <Button
                     style={{color:'#FFFFFF',
                     background:'#2BAEB3'}}
                        variant="contained"
                        color="primary"
                        size="large"
                        // disabled={row.estadoSolicitud.id != 10? true: false}
                        onClick={(e) => {
                            setisOpenModalCreate(true)
                        }}
                    >
                        Crear Vendedor
                    </Button>

                </Grid>

            </Grid>
            <Grid container spacing={1}>
                <Grid xs={2} sm={6} md={6} style={{ textAlign: 'right' }}>
                    <IconButton
                        size="large"
                        color="Primary"
                        onClick={(e) => { getVendedor(idSucursal, 0, size, "") }}
                    >
                        <RotateLeftIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={10} sm={6} md={6}>
                    <SearchBar
                        value={filter}
                        onChange={(e) => filtrar(e)}
                        onCancelSearch={() => filtrar("")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Box
                            m={2}
                            component="span"
                            className={`${classes.spreadBox} ${classes.box}`}
                        >
                            <Title >Vendedores</Title>
                        </Box>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow alignItems="center">
                                        <TableCell align="center">Número de documento</TableCell>
                                        <TableCell align="center">Nombre Completo</TableCell>
                                        {/* <TableCell align="center">Cargo</TableCell> */}
                                        <TableCell align="center" >Estado</TableCell>
                                        <TableCell align="center">Email</TableCell>
                                        <TableCell align="center">Celular</TableCell>
                                        <TableCell align="center">Editar</TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoadUser ?
                                        <>
                                            <Grid
                                                container
                                                spacing={1}
                                                direction="row"
                                                justify="center"
                                                alignItems="center"
                                                alignContent="center"
                                                wrap="nowrap"
                                            >
                                                <ReactLoading
                                                    type={"bars"}
                                                    color={"#000"}
                                                    height={50}
                                                    width={50}
                                                />
                                            </Grid>
                                        </>
                                        : data.map((row, key) => (
                                            <TableRow key={key}>
                                                <TableCell align="center">{`${row.numerocedula}`}</TableCell>
                                                <TableCell align="center">{row.nombreCompleto}</TableCell>
                                                {/* <TableCell align="center">{row.cargo}</TableCell> */}
                                                <TableCell align="center">{row.estado === "A" ? "Activo" : "Inactivo"}</TableCell>
                                                <TableCell align="center">{row.email}</TableCell>
                                                <TableCell align="center">{row.celular}</TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="contained"
                                                        style={{color:'#FFFFFF',
                                                        background:'#2BAEB3'}}
                                                        // disabled={row.estadoSolicitud.id != 10? true: false}
                                                        onClick={(e) => {
                                                            EditVendor(row)
                                                        }}
                                                    >
                                                        Editar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                        >
                            <Paginator
                                totalElement={Math.round(totalElements / size)}
                                setPage={setPage}
                                page={page}

                            />
                        </Grid>
                        <br />

                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}