import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { selectUserPhone } from '../user/domain/userPhoneSlice';
import { Home } from './home/Home';

import { Menus } from './menu/menuJson';

import { HelpMenuDashboard, DefaultDashboard } from '../dashboard';
import { Solicitudes } from './solicitudes/Solicitud';
import {PreSolicitudes} from './pre-solicitudes/PreSolicitud'
import { MisSolicitudes } from './miSolicitud/miSolicitud';
import { AsignarSolicitud } from './miSolicitud/asignarSolicitud';
import { ListaVendedores } from './vendedores/ListVendedores';
import { MediosPagos } from './mediosPagos/mediosPagos';
import { ListSolicitudesCommerce } from './solicitudesByCommerce/solicitudesCommerce';
import { Sucursal } from './sucursales/listSucursales';
import { Administrator } from './admin/viewAdmin';
import {ListaVendedoresComercio} from './vendedoresComercio/ListVendedoresComercio'
import { Calculadora } from './calculadora/calculadora';
import { Recaudos } from './recaudos/recaudos';
import { InfoPayment } from './recaudos/InfoPayment';
import { Historico } from './recaudos/historico';






export const Prestapolis = () => {

  const user = useSelector(selectUserPhone)
  

  const components = {
   
    Default: DefaultDashboard,
    Home: Home,
    Solicitudes: Solicitudes,
    MisSolicitudes: MisSolicitudes,
    AsignarSolicitud: AsignarSolicitud,
    ListaVendedores: ListaVendedores,
    MediosPagos: MediosPagos,
    ListSolicitudesCommerce: ListSolicitudesCommerce,
    Sucursal: Sucursal,
    AdminPrestapolis: Administrator,
    ListaVendedoresComercio:ListaVendedoresComercio,
    Calculadora:Calculadora,
    PreSolicitudes:PreSolicitudes,
    Recaudos: Recaudos,
    InfoPayment: InfoPayment,
    Historico: Historico,
    
    
    
  
  };

  return (
    <>
      {HelpMenuDashboard().RenderMenu(Menus(), components)}
    </>
  );
}
