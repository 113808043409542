//React
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { postLogin } from '../../domain/userPhoneSlice';
import { loginUser, getUser } from './auth/auth0';
import { USER_AUTH0, ERROR_LOGIN, CHANGE_PASS, EMAIL_CHANGE } from '../../../app/api-client/constants';

//Icons Material
import {
    PhoneIphone,
} from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';

//Component Material
import {
    Paper,
    Grid,
    TextField,
    Button,
    Container,
    CircularProgress,
    Typography,
    Link
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

//Local Component
import clsx from 'clsx';
import logo from '../img/logo.png';
import { useStyles } from './SinginStyles';
import { HelpValidate } from '../../utils/helpValidate';
import { Copyright } from '../../../dashboard/componet/UI/CopyRight'
import { ModalInput } from '../../../prestapolis/modals/modalInput';
import { sendEmailChangePass } from '../../../prestapolis/domain/api/PrestapolisApi';



export default function Sigin() {


    const classes = useStyles();
    const history = useHistory();
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const borderRadius = clsx(classes.padding, classes.borderRadius);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [errorSingin, seterrorSingin] = useState(false)
    const [message, setMessage] = useState("")

    ///pasar email por los params para el cambio de contraseña
    const [email, setEmail] = useState("")

    //MODAL
    const [isOpenModal, setisOpenModal] = useState(false)
    const [correo_recuperacion, setCorreo_recuperacion] = useState("")
    const [isAlertModal, setisAlertModal] = useState(false)
    const [messageAlert, setmessageAlert] = useState("")
    const [typeAlert, settypeAlert] = useState("")
    const [disBtnContinue, setdisBtnContinue] = useState(false)


    const setAlerts = (type, message) => {
        setmessageAlert(message);
        settypeAlert(type);
        setisAlertModal(true);
        setTimeout(() => {
            setisAlertModal(false);
        }, 3500);
    }

    const sendEmail = () => {
        setdisBtnContinue(true)

        let request = {

            email: correo_recuperacion
        }

        sendEmailChangePass(dispatch, request)
        .then((response)=>{
            if(response.codigoRespuesta === "0000"){
                setAlerts("green", "Correo enviado con exito");
                setTimeout(() => {
                    goChangePassAdmin(correo_recuperacion);
                    setdisBtnContinue(false)
                }, 2000);
                
            }else{
                setdisBtnContinue(false)
                setAlerts("red", response.mensaje);
            }
          
        })
    }

    const HandeldChangePass = () => {
        history.push(`/changePass/`)
    }

    const goChangePassAdmin = (email) =>{
        history.push(`/changePassAdmin/${email}`)
    }



    const sentRequest = (email, code) => {
        return new Promise((resolve, reject) => {
            resolve(dispatch(postLogin({
                usernameOrEmail: `COMMERCE-${email}`,
                password: code
            }))
            );
        })
    }

    const onSubmit = (datos) => {
        setLoading(true)
        loginUser(datos.email, datos.password)
            .then((response) => {
                getUser(response.accessToken)
                    .then((res) => {
                        let data = {
                            email: res.email,
                            sub: res.sub,
                            accessToken: response.accessToken,
                            tokenType: response.tokenType,
                            idToken: response.idToken,
                            expiresIn: response.expiresIn,
                        };
                      
                        localStorage.setItem(USER_AUTH0, JSON.stringify(data));
                        localStorage.setItem(EMAIL_CHANGE, datos.email)
                        sentRequest(datos.email, datos.password)
                            .then((response) => {
                                setLoading(false)
                                let respuestaError = localStorage.getItem(ERROR_LOGIN)
                                let cambioContra = localStorage.getItem(CHANGE_PASS)
                                if (respuestaError === "error") {
                                    setMessage("Usuario y o contraseña incorrectos")
                                    seterrorSingin(true)
                                    setTimeout(() => {
                                        seterrorSingin(false)
                                    }, 3000);
                                }


                                if (cambioContra === "isChange") {
                                    setMessage("Señor usuario para hacer uso del aplicativo, deberá primero cambiar su contraseña")
                                    seterrorSingin(true)
                                    setTimeout(() => {
                                        seterrorSingin(false)
                                        HandeldChangePass()
                                    }, 3500);
                                    // setTimeout(() => {

                                    // }, 4000);
                                }

                            })
                            .catch((err) => {
                             
                                setMessage("Usuario y o contraseña incorrectos")
                                seterrorSingin(true)
                                setTimeout(() => {
                                    seterrorSingin(false)
                                }, 3000);
                            })

                    })
                    .catch((error) => {
                        setLoading(false)
                       
                        setMessage("Usuario y o contraseña incorrectos")
                        seterrorSingin(true)
                        setTimeout(() => {
                            seterrorSingin(false)
                        }, 3000);

                    });
            })
            .catch((error) => {
                setLoading(false)
                setMessage("Usuario y o contraseña incorrectos")
                seterrorSingin(true)
                setTimeout(() => {
                    seterrorSingin(false)
                }, 3000);
            });

    }

    const HandledBack = () => {
        history.push(`/`)
    }




    return (
        <>
            <ModalInput
                handledtClose={setisOpenModal}
                isOpen={isOpenModal}
                text="Para restablecer tu contraseña suministra un correo electrónico, al cual enviaremos  el código de recuperación"
                handledAccept={sendEmail}
                title="Restablecimiento de contraseña"
                input_label="Correo electrónico"
                inpunt_value={correo_recuperacion}
                setInput_value={setCorreo_recuperacion}
                typeInput="email"
                isAlert={isAlertModal}
                messageAlert = {messageAlert}
                typeAlert={typeAlert}
                disBtnContinue={disBtnContinue}
                setdisBtnContinue={setdisBtnContinue}

            />
            <div className={classes.centerv} >
                <Container maxWidth="xs" alignitems="center" >
                    <Paper className={borderRadius}>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="left"
                            alignItems="left"
                            alignContent="left"
                            wrap="nowrap"

                        >
                            <IconButton onClick={HandledBack}>
                                <ArrowBackIcon />
                            </IconButton>

                        </Grid>
                        <div className={classes.margin}>
                            <Grid container justify="center" style={{ marginTop: '15px', maxwidth: '350px' }}>
                                <img src={logo} width="100%" alt="Logo" />
                            </Grid>
                            <Grid
                                container
                                justify="center"
                                alignItems="flex-start"
                                alignContent="stretch"
                                wrap="nowrap"

                            >
                                <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Iniciando sesión como Administrador
                                </Typography>
                            </Grid>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '20px' }}>
                                    <Grid item>
                                        <EmailIcon style={{color:'#2BAEB3'}}  fontSize="large" />
                                    </Grid>

                                    <Grid item md={true} sm={true} xs={true} >
                                        <TextField
                                            placeholder="Correo"
                                            label="Correo"
                                            fullWidth
                                            name="correo"
                                            type="email"
                                            {...HelpValidate().FormatEmailSigin(register('email', {
                                                required: "Por favor ingrese correo electronico.",
                                                minLength: {
                                                    value: 10,
                                                    message: 'correo electronico no valido'
                                                },
                                            }), setError, clearErrors)}
                                            error={!!errors.correo}
                                            helperText={errors.correo?.message}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '20px' }}>
                                    <Grid item>
                                        <LockIcon style={{color:'#2BAEB3'}} fontSize="large" />
                                    </Grid>

                                    <Grid item md={true} sm={true} xs={true} >
                                        <TextField
                                            placeholder="Contraseña"
                                            label="Contraseña"
                                            fullWidth
                                            name="Contraseña"
                                            type="password"
                                            {...HelpValidate().writeText(register('password', {
                                                required: "Por favor ingrese la contraseña.",
                                                minLength: {
                                                    value: 8,
                                                    message: 'Coreoo electronico no valido'
                                                },
                                            }))}
                                            error={!!errors.contraseña}
                                            helperText={errors.contraseña?.message}
                                        />
                                    </Grid>

                                </Grid>
                                <div className={classes.wrapper}>
                                    <Grid container justify="center" style={{ marginTop: '40px' }}>
                                        <Button
                                            disabled={loading}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            style={{ textTransform: "none", width: "inherit", color:'#FFFFFF', background:'#2BAEB3'}}>
                                            Continuar
                                        </Button>
                                    </Grid>
                                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                                <br></br>
                                {/* {succesCode && <Alert severity="success">El código se envio correctamente</Alert>} */}
                                {errorSingin && <Alert severity="error">{message}</Alert>}

                                <Grid container alignItems="flex-end" style={{ marginTop: '15px', marginLeft: '11%', marginBottom: '10px' }}>
                                    <Typography variant="body2" color="#2BAEB3" align='center' style={{color:'#2BAEB3'}}>
                                        <Link onClick={((e) => {setisOpenModal(true)})} target="_blank" underline="hover">
                                            Si has olvidado tu contraseña da click aquí
                                        </Link>
                                    </Typography>
                                </Grid>
                                <br />
                            </form>
                        </div>
                        <Box >
                            <Copyright />
                        </Box>
                    </Paper>
                </Container >
            </div>
        </>
    );

}