import { configureStore } from '@reduxjs/toolkit'

import userReducer from '../../user/domain/userSlice';
import userPhoneReducer from '../../user/domain/userPhoneSlice';

export default configureStore({
  reducer: {
      user:userReducer,
      userPhone:userPhoneReducer,
  },
})