//React
import React, { useState, useEffect, createRef } from 'react';
import { useForm } from 'react-hook-form';
import { store } from 'react-notifications-component';


//Reux
import { useDispatch } from 'react-redux';

//Icons Material
import {
    PhoneIphone,
} from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import './styles.css'

//Component Material
import {
    Paper,
    Grid,
    TextField,
    Button,
    Container, CircularProgress,
    Typography,
    IconButton,
    Box,
    FormControl,
    InputLabel,
    OutlinedInput,
    CardActionArea,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Slider, FormControlLabel, Checkbox,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Link from '@material-ui/core/Link'
import InputAdornment from '@material-ui/core/InputAdornment';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';


//Local component 
import { useStyles } from './calcStyle';
import clsx from 'clsx';
import { HelpValidate } from '../../user/utils/helpValidate';
import { Copyright } from '../../dashboard/componet/UI/CopyRight';
import logo from '../img/logo.png'
import {
    getParameters,
    getCommerceByName,
    generatePLans,
    saveChangeCalc,
    aprobSolicitud,
    preaprovedSolicitud,
    multiUpdate,
    cambiarEstado
} from '../domain/api/PrestapolisApi';

import { set } from 'date-fns';
import MigaPan from '../../app/orussystem/component/MigaPan';
import createTypography from '@material-ui/core/styles/createTypography';


export const Calculadora = ({ data, setopenMondal, reloadView }) => {

    const classes = useStyles();
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const borderRadius = clsx(classes.padding, classes.borderRadius);
    const dispatch = useDispatch();

    const [name_prod, setName_prod] = useState("");
    const [valor_prod, setValor_prod] = useState("");
    const [abono_prod, setAbono_prod] = useState("");
    const [abono_init, setabono_init] = useState("")
    const [valor_init, setvalor_init] = useState("")
    const [optionStep, setOptionStep] = useState(0);
    const [completed, setCompleted] = useState(new Set());
    const [planSliderSelected, setPlanSliderSelected] = useState("");

    const [plazoPrestamo, setplazoPrestamo] = useState(0);
    const [maxEndeudamiento, setMaxEndeudamiento] = useState("");
    const [endeudamiento, setEndeudamiento] = useState(0)

    const [listParams, setListParams] = useState([]);
    const [comerceData, setComerceData] = useState({});
    const [montoMin, setMontoMin] = useState("");
    const [montoMax, setMontoMax] = useState("");
    const [esCuota, setEsCuotas] = useState([]);
    const [isMonto, setisMonto] = useState(false);
    const [isCardPlan, setisCardPlan] = useState(false);
    const [plans, setPlans] = useState([]);
    const [dataCard, setDataCard] = useState({});
    const [openDialog, setopenDialog] = useState(false);
    const [confirmSave, setconfirmSave] = useState(false);
    const [maskOptions, setmaskOptions] = useState([])
    const [disCard, setDisCard] = useState(0);
    const [indep, setIndep] = useState(0);
    const [showCalc, setshowCalc] = useState(false);
    const [tipoForm, setTipoForm] = useState("")
    const [tipoDeSolicitud, settipoDeSolicitud] = useState("")
    const [checkAbono, setcheckAbono] = useState(false)
    const [isChangeType, setisChangeType] = useState(false)
    const [stateRequest, setstateRequest] = useState("")

    let costo = listParams.filter((lParam) => lParam.key === "COSTO_PLATAFORMA");

    const c1 = createRef();
    const c2 = createRef();
    const c3 = createRef();

    const getListParms = () => {
        getParameters(dispatch, 0, 1000, "")
            .then((response) => {
                if (response.code === "0000") {
                    setListParams(response.content);
                } else {
                   
                }
            })
            .catch((err) => {
               
            })
    }

    const submitFocus = () => {

        c1.current.focus();
        c2.current.focus();
        tipoForm !== "SIN_ABONO" && tipoDeSolicitud !== 5 && c3.current.focus();
    }

    const getDataComerceByCode = (code) => {
        getCommerceByName(dispatch, code)
            .then((response) => {
                let data = response.respuesta;
                if (response.codigoRespuesta === "0000") {
                    setComerceData(data)
                    setMontoMax(data.montoMaximo);
                    setMontoMin(data.montoMinimo);
                    setTipoForm(data.tipoFormulario);

                    if (data.tipoFormulario === "ABONO_MINIMO_OBLIGATORIO") {
                        setcheckAbono(true)
                    }

                } else {
                  
                }
            })
            .catch((err) => {
                
            })
    }

    const formatNumber = (num) => {
        return new Intl.NumberFormat(["ban", "id"]).format(num);
    };


    const calculePlan = (data) => {
   
        let options = [];
        setshowCalc(true)
        let valor = data.valor_prod;
        if (typeof valor === "string") {
            valor = valor.includes(".") ? valor.split(".") : valor.split(",");
            valor = valor.join("");
            valor = parseInt(valor, 10);
        }
        let abono = tipoDeSolicitud !== 1 ? 0 : data.Abono_prod;
        if (typeof abono === "string") {
            abono = abono.includes(".") ? abono.split(".") : abono.split(",");
            abono = abono.join("");
            abono = parseInt(abono, 10);
        }

        let esCuotas = comerceData.estructuraCuotas;
      
        if (typeof esCuotas === "string") {
            esCuotas = esCuotas.includes('"')
                ? esCuotas.split("")
                : esCuotas.split("");
            esCuotas = esCuotas.join("");
            esCuotas = JSON.parse(esCuotas);
        }

      

        setEsCuotas(esCuotas);

        let request = {
            valor: valor,
            abono: abono = tipoForm === "SIN_ABONO" ? 0 : abono,
            opciones: esCuotas,
            costoPlataforma: costo,
        };

     

        let newEndeudamiento = valor - abono;
        let abonoSugMin = valor - maxEndeudamiento < 0 ? 20000 : valor - maxEndeudamiento

        clearErrors();
        if (tipoForm === "ABONO_MINIMO_OBLIGATORIO" && valor <= abono) {

            setError("valor_prod", {
                type: "manual",
                message: `El valor del producto no puede ser menor al abono`,
            });
            setTimeout(() => {
                clearErrors();
            }, 2000);
            setshowCalc(false);

        } else if ((tipoForm === "ABONO_MINIMO_SUGERIDO" && tipoDeSolicitud !== 5) && abono < abonoSugMin) {

            setError("Abono_prod", {
                type: "manual",
                message: `El abono no puede ser menor a $${formatPesos(abonoSugMin)}`,
            });
            setTimeout(() => {
                clearErrors();
            }, 8000);
            setshowCalc(false);

        } else if (newEndeudamiento > maxEndeudamiento && valor > valor_init) {

            setError("Abono_prod", {
                type: "manual",
                message: `para este producto la inicial mínima es de $${formatPesos(valor - maxEndeudamiento)}, por favor ajustar o calcular con un producto de menor valor`,
            });
            setTimeout(() => {
                clearErrors();
            }, 8000);
            setshowCalc(false);

        } else if (newEndeudamiento > maxEndeudamiento && abono < abono_init) {

            setError("Abono_prod", {
                type: "manual",
                message: `para este producto la inicial mínima es de $${formatPesos(valor - maxEndeudamiento)}, por favor ajustar o calcular con un producto de menor valor`,
            });
            setTimeout(() => {
                clearErrors();
            }, 8000);
            setshowCalc(false);

        } else if (valor < comerceData.montoMinimo || valor > comerceData.montoMaximo) {
            setshowCalc(false);
            setisMonto(true);
        } else if (tipoForm === "ABONO_MINIMO_OBLIGATORIO" && parseInt(abono) < 20000) {
            setError("Abono_prod", {
                type: "manual",
                message: `El abono no pude ser menor a $20.000 `,
            });
            setTimeout(() => {
                clearErrors();
            }, 4000);
            setshowCalc(false);
        }
        else {
            setisMonto(false);
            generatePLans(dispatch, request)
                .then((response) => {
                   
                    if (response.codigoRespuesta === "0000") {
                        let endeudamiento = valor - abono;

                        setisCardPlan(true);
                        setshowCalc(false);
                        let plazo = 0;
                        response.respuesta.length > 0 &&
                            response.respuesta.forEach((option) => {
                                options.push({
                                    ...option,
                                    selected: "",
                                });
                            });
                        options.map((item, key) => {
                            let mes = parseInt(item.meses, 10);
                            if (mes === plazoPrestamo) {
                                plazo = key;
                            }
                        })

                        setPlans(options);
                        setDataCard(options[plazo]);
                        setOptionStep(plazo);
                        completed.add(plazo)
                        let meses = parseInt(options[plazo].meses)
                        setPlanSliderSelected(meses)
                        setIndep(endeudamiento);
                        setDisCard(plazo)

                    } else {
                      
                    }
                    setshowCalc(false);
                })
                .catch((err) => {
                    setshowCalc(false);
                   
                });
        }

    };

    const formatPesos = (value) => {
        let number = ""
        number = new Intl.NumberFormat(["ban", "id"]).format(value)
        return number
    }

    function isStepComplete(step) {
        return completed.has(step);
    }

    const setCard = (data, key) => {
        setDataCard(data);
        setOptionStep(key);
    }


    const onSubmit = (data) => {
        calculePlan(data)
    }

    const setDataCalc = (data) => {
        setOptionStep(0)

        let tipoSoli = data.tipoSolicitud === undefined || data.tipoSolicitud === null ? "" : parseInt(data.tipoSolicitud.id)
        let ende = ""

        if (tipoSoli === 1) {
            setcheckAbono(true)
        } else {
            setcheckAbono(false)
        }

        for (let item of completed) {
            completed.delete(item);
        }

        listParams.map((item) => {
            if (parseInt(item.id) === 105) {
                ende = parseInt(item.valor);
                ende = ende / 100;
            }
        })

        let plazo = data.plazoFinal === undefined || data.plazoFinal === null
            ? parseInt(data.plazosolicitado, 10)
            : parseInt(data.plazoFinal, 10)

        let abono = data.abonoFinal === undefined || data.abonoFinal === null
            ? data.abono
            : data.abonoFinal

        let montoRequerido = data.montoAprobado === undefined || data.montoAprobado === null
            ? data.valormontoaprobado === undefined || data.valormontoaprobado === null
                ? data.valormontorequerido
                : data.valormontoaprobado
            : data.montoAprobado

        let idStateRequest = data.estadoSolicitud !== undefined && data.estadoSolicitud !== null
            ? parseInt(data.estadoSolicitud.id) : "";

        setName_prod(data.descripcion);
        setabono_init(abono)
        setAbono_prod(formatPesos(abono));
        setValor_prod(formatPesos(montoRequerido));
        setplazoPrestamo(plazo);
        setstateRequest(idStateRequest)


        let valorInit = montoRequerido;
        if (typeof valorInit === "string") {
            valorInit = valorInit.includes(".") ? valorInit.split(".") : valorInit.split(",");
            valorInit = valorInit.join("");
            valorInit = parseInt(valorInit, 10);
        }
        let abonoInit = abono;
        if (typeof abonoInit === "string") {
            abonoInit = abonoInit.includes(".") ? abonoInit.split(".") : abonoInit.split(",");
            abonoInit = abonoInit.join("");
            abonoInit = parseInt(abonoInit, 10);
        }

        let valorInitReq = data.valormontoaprobado;
        if (typeof valorInit === "string") {
            valorInit = valorInit.includes(".") ? valorInit.split(".") : valorInit.split(",");
            valorInit = valorInit.join("");
            valorInit = parseInt(valorInit, 10);
        }
        let abonoInitCre = data.abono;
        if (typeof abonoInit === "string") {
            abonoInit = abonoInit.includes(".") ? abonoInit.split(".") : abonoInit.split(",");
            abonoInit = abonoInit.join("");
            abonoInit = parseInt(abonoInit, 10);
        }



        let endeudamiento = valorInitReq - abonoInitCre;
        let max_endeudamiento = endeudamiento * (1 + ende);


        setEndeudamiento(endeudamiento);
        setvalor_init(valorInit)
        setMaxEndeudamiento(max_endeudamiento);
        getDataComerceByCode(data.comercio === undefined || data.comercio === null ? "" : data.comercio.apikey)
        settipoDeSolicitud(tipoSoli)
    }

    const generateDialog = () => {
        return (
            <Dialog
                // onClose={() => setopenDialog(false)}
                aria-labelledby="simple-dialog-title"
                open={openDialog}
            >
                <DialogTitle id="simple-dialog-title">¿Está seguro de guardar los datos?</DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={6} sm={6}>
                            <Button variant="contained" color="Prymary" style={{ width: "100%" }} onClick={() => { acceptSave() }}>

                                Si
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={6} sm={6}>
                            <Button variant="contained" color="secondary" style={{ width: "100%" }} onClick={() => { noSave() }}>
                                No
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog >

        )
    }
    //Abrir Modal antes de guardar
    const saveData = () => {
        setconfirmSave(true)
        setopenDialog(true)
    }

    //Dar si en el modal de aprovar
    const acceptSave = () => {
        setopenDialog(false);
        saveChange();
    }

    //Dar no en el modal de aprovar
    const noSave = () => {
        setopenDialog(false);
        setconfirmSave(false);
    }


    //Metodo para guardar datos calculados 
    const saveChange = () => {

        let valorProducto = valor_prod.replaceAll(".", "").replaceAll(",", "")
        valorProducto = parseInt(valorProducto);
        let valorAbono = tipoDeSolicitud !== 5 && tipoForm !== "SIN_ABONO" && checkAbono ? abono_prod.replaceAll(".", "").replaceAll(",", ""):0;
        valorAbono = parseInt(valorAbono)

        if (tipoForm === "ABONO_MINIMO_SUGERIDO" && tipoDeSolicitud !== 5 && isChangeType) {
            handledChangeTipoForm()
        }


        let request = {
            solicitud: {
                id: data.id
            },
            interes: {
                id: data.interes.id
            },
            monto: valorProducto,
            abono: valorAbono,
            plazo: planSliderSelected - 1
        }


//        multiUpdate(dispatch, request)
//            .then((response) => {
//                if (response.codigoRespuesta === "0000") {
//                    setconfirmSave(false);
                    // changeStateRequest(data.id, 4);

        saveChangeCalc(dispatch, request)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    setconfirmSave(false);
                    if (stateRequest === 3) {
                        changeStateRequest(data.id, 3);
                    }
                    store.addNotification({
                        title: "Exito!",
                        message: "¡Los datos han sido guardados con exito!",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeOut"],
                        animationOut: ["animate__animated", "animate__fadeIn"],
                        dismiss: {
                            duration: 3000,
                            // onScreen: true
                        }
                    });
                    setopenMondal(false);

                }
            })
            .catch((err) => {
                setconfirmSave(false);
                // changeStateRequest(data.id, 4);

                store.addNotification({
                    title: "Erro interno!",
                    message: "¡contacta al administrador!",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeOut"],
                    animationOut: ["animate__animated", "animate__fadeIn"],
                    dismiss: {
                        duration: 3000,
                        // onScreen: true
                    }
                });
            })
    }

    //Metodo para cambiar estado de la solicitud get 
    const changeStateRequest = (id, state) => {
        aprobSolicitud(dispatch, id, state)
            .then((response) => {
               
                // preaprovedRequest(id);
            })
            .catch((err) => {
                
            })
    }

    //Metodo para preaprovar solicitud post
    const preaprovedRequest = (id) => {
        preaprovedSolicitud(dispatch, id)
            .then((response) => {
               
                reloadView()
            })
            .catch((err) => {
               
            })
    }

    //Metodo para cambiar de tipo de formulario
    const handledChangeTipoForm = () => {
        cambiarEstado(dispatch, data.id, 1)
            .then((response) => {
                const {
                    tipoSolicitud,
                } = response.respuesta;

                settipoDeSolicitud(tipoSolicitud.id);

            }).catch((err) => {

                
                if (err.status === 401) {

                }
            })
    }

    //handledChec 
    const handledCheck = (e) => {
        setcheckAbono(e.target.checked)
        settipoDeSolicitud(1)
        setisChangeType(true)
    }

    useEffect(() => {
        setisCardPlan(false)
        getListParms()
        setDataCalc(data);
        clearErrors();
    }, [data]);



    return (
        <>

            <div>{generateDialog()}</div>
            <div className={classes.centerv} >
                <Container maxWidth="xs" alignitems="center" >
                    <Paper className={borderRadius}>
                        <div className={classes.margin}>
                            <Grid
                                container
                                justify="center"
                                alignItems="flex-start"
                                alignContent="stretch"
                                wrap="nowrap"

                            >
                                <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Inicia tu solicitud de crédito
                                </Typography>
                            </Grid>
                            <form autocomplete="off" onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '30px' }}>
                                    <FormControl fullWidth variant="outlined" >
                                        <InputLabel htmlFor="outlined-adornment-amount">Nombre del producto *</InputLabel>
                                        <OutlinedInput
                                            id="name_prod"
                                            autocomplete="off"
                                            startAdornment={<InputAdornment position="start"><ShoppingCartIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                            labelWidth={165}
                                            fullWidth
                                            name="name_prod"
                                            value={name_prod}
                                            type="text"
                                            inputProps={{ className: "label__color", ref: c1 }}
                                            {...HelpValidate().validateText(register('name_prod', {
                                                required: "Por favor ingresa un valor del producto valido.",
                                                minLength: {
                                                    value: 2,
                                                    message: 'Ingresa un nombre valido'
                                                },
                                            }), setName_prod)}
                                            helperText={errors.name_prod?.message}
                                        />
                                    </FormControl>
                                </Grid>
                                <div className={classes.errorLabel}>{errors.name_prod?.message}</div>


                                <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '15px' }}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-amount">Valor del producto *</InputLabel>
                                        <OutlinedInput
                                            id="valor_prod"
                                            autocomplete="off"
                                            startAdornment={<InputAdornment position="start"><AttachMoneyIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                            labelWidth={150}
                                            fullWidth
                                            name="valor_prod"
                                            value={valor_prod}
                                            inputProps={{ className: "label__color", ref: c2 }}
                                            type="tel"
                                            {...HelpValidate().calcAbonoCrete(register('valor_prod', {
                                                required: "Por favor ingresa un valor del producto valido.",
                                                minLength: {
                                                    value: 2,
                                                    message: 'Por favor ingresa un valor del producto valido'
                                                },
                                            }), setValor_prod, setisCardPlan, setAbono_prod, comerceData)}
                                            helperText={errors.valor_prod?.message}
                                        />
                                    </FormControl>
                                </Grid>
                                <div className={classes.errorLabel}>{errors.valor_prod?.message}</div>

                                {tipoDeSolicitud !== 5 && tipoForm !== "SIN_ABONO" && checkAbono &&

                                    <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '15px' }}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-amount">Abono *</InputLabel>
                                            <OutlinedInput
                                                id="Abono_prod"
                                                autocomplete="off"
                                                startAdornment={<InputAdornment position="start"><AttachMoneyIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                                labelWidth={60}
                                                fullWidth
                                                name="Abono_prod"
                                                value={abono_prod}
                                                type="tel"
                                                inputProps={{ className: "label__color", ref: c3 }}
                                                {...HelpValidate().formatMoneyNumber(register('Abono_prod', {
                                                    required: "Por favor ingresa un valor abono valido.",
                                                    minLength: {
                                                        value: tipoForm === "ABONO_MINIMO_OBLIGATORIO" ? 4 : 0,
                                                        message: 'Ingresa un abono valido'
                                                    },
                                                }), setAbono_prod, setisCardPlan)}
                                                helperText={errors.Abono_prod?.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                                <div className={classes.errorLabel}>{errors.Abono_prod?.message}</div>

                                {!checkAbono && tipoDeSolicitud !== 1 && tipoForm === "ABONO_MINIMO_SUGERIDO" &&
                                    <div style={{ paddingTop: "10px" }} >
                                        <FormControlLabel
                                            label="Selecciona la casilla para hacer un abono a tu credito"
                                            control={
                                                <Checkbox
                                                    value=""
                                                    checked={checkAbono}
                                                    onChange={(e) => handledCheck(e)}
                                                    color="primary"
                                                />
                                            }
                                        />
                                    </div>
                                }
                                <div className={classes.wrapper}>
                                    <Grid container justify="center" style={{ marginTop: '20px' }}>
                                        <Button
                                            disabled={showCalc}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            id='continuar'
                                            onMouseEnter={(e) => (submitFocus())}
                                            style={{ textTransform: "none", width: "inherit" }}
                                        >
                                            Calcular
                                        </Button>
                                    </Grid>
                                    {showCalc && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>



                                {false && <Alert severity="success">El código se envio correctamente</Alert>}
                                {isMonto && <Alert severity="error" style={{ textAlign: "center" }}>{`El monto minimo es: $${formatPesos(montoMin)}, el monto maximo es: $${formatPesos(montoMax)}`}</Alert>}

                            </form>

                            {isCardPlan &&
                                <div style={{ width: "100%" }}>
                                    <Typography id="discrete-slider-restrict" style={{ marginTop: "40px" }} gutterBottom>
                                        Plazo - meses
                                       
                                    </Typography>

                                    {plans.length > 0 && <Slider
                                        getAriaValueText={value => `${value}`}
                                        aria-labelledby="discrete-slider-custom"
                                        value={planSliderSelected}
                                        step={null}
                                        autocomplete="off"
                                        marks={plans.map(({ meses }) => ({ value: meses, label: `${tipoDeSolicitud !== 5 && tipoForm !== "SIN_ABONO" && checkAbono ? meses - 1: meses}` }))}
                                        max={plans[plans.length - 1].meses}
                                        min={plans[0].meses}
                                        onChange={(_event, value) => {
                                            const key = plans.findIndex(({ meses }) => meses === value);
                                            const item = plans[key];
                                            setPlanSliderSelected(value);
                                            setCard(item, key);
                                        }}
                                    />}
                                    <Paper style={{ border: "5px solid #0a699e", borderRadius: "20px", width: "100%" }}>
                                        <Card variant="outlined" style={{ borderRadius: "20px" }} >
                                            <CardActionArea >
                                                <CardContent>
                                                    <Typography variant="h6" component="h1" style={{ color: "#ff6700" }}>

                                                    </Typography>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                                                        <div style={{ marginTop: "5pt" }}>
                                                            <b className="text-planes" style={{ fontSize: '28px', color: "#4d4d4d" }}>
                                                                $ {formatPesos(Math.floor((dataCard?.valor == undefined ? "-" : dataCard?.valor )))}
                                                                
                                                            </b>
                                                        </div>
                                                        <div style={{ display: "flex", alignItems: "center", marginTop: "5px", color: "#FF6700 " }}>
                                                            <div>
                                                                <CalendarTodayIcon style={{ fontSize: '1.7rem', marginTop: "2px" }} />
                                                            </div>
                                                            <div style={{ marginTop: "10pt", marginLeft: "2px" }} >
                                                                <b className="text-planes" style={{ fontSize: '1rem' }}>
                                                                    {`${tipoDeSolicitud !== 5 && tipoForm !== "SIN_ABONO" && checkAbono ? tipoDeSolicitud: tipoDeSolicitud} meses`}
                                                                </b>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <Typography className={classes.pos} style={{ color: "#676767" }}>
                                                        <Brightness1Icon style={{ fontSize: "12px", marginRight: "10px", color: "#2c8aaf" }} />
                                                        Interes: {dataCard?.interes === undefined ? "sin interes" : dataCard?.interes.interesmensual} %
                                                    </Typography>
                                                    <Typography className={classes.pos} style={{ color: "#676767" }} >
                                                        <Brightness1Icon style={{ fontSize: "12px", marginRight: "10px", color: "#2c8aaf" }} />
                                                        Costo administracion: $ {formatPesos(dataCard?.costo === undefined ? '0' : dataCard?.costo)}
                                                    </Typography>
                                                    <Typography variant="h6" component="h1" style={{ fontWeight: "bold" }}>
                                                        Total a a pagar: $ {tipoDeSolicitud !== 5 && tipoForm !== "SIN_ABONO"?formatPesos(dataCard?.pagoTotal): formatPesos(dataCard?.pagoTotal + dataCard?.costo)}
                                                        Total a a pagar: $ {tipoDeSolicitud !== 5 && tipoForm !== "SIN_ABONO" && checkAbono ?formatPesos(dataCard?.pagoTotal): formatPesos(dataCard?.pagoTotal)}

                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Paper>

                                </div>
                            }

                            {isCardPlan &&
                                <div className={classes.wrapper}>
                                    <Grid container justify="center" style={{ marginTop: '20px' }}>
                                        <Button
                                            disabled={confirmSave}
                                            onClick={saveData}
                                            variant="contained"
                                            color="primary"
                                            id='continuar'
                                            style={{ textTransform: "none", width: "inherit" }}
                                        >
                                            Guardar
                                        </Button>
                                    </Grid>
                                    {confirmSave && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            }


                        </div>
                    </Paper>

                </Container >
            </div>
        </>
    );
}
