import { logout } from "../../user/domain/userPhoneSlice";
import { ACCESS_TOKEN, ERROR_SERVICE } from "./constants";

export const request = (options, dispatch) => {                                                                             
    const headers = new Headers({
        "Content-Type": "application/json",
    });

    if (localStorage.getItem(ACCESS_TOKEN)) {
    	headers.append(
    		"Authorization",
    		"Bearer " + localStorage.getItem(ACCESS_TOKEN)
    	);
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status === 401) {
                localStorage.removeItem(ACCESS_TOKEN);
                localStorage.setItem(ERROR_SERVICE, "true");
                dispatch(logout());
                return {
                    ...json,
                    error: true,
                    process: 'failed',
                    content:[]
                  
                };
            }

            return { error: false, ...json };
        });
};
