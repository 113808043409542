//React
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { postLogin, selectUser, selectLoading, selectPayload } from '../../domain/userSlice';

//Icons Material
import {
    Paper,
    Typography,
    Grid,
    Button,
    Container,
    CircularProgress,
    Box,
    IconButton,
} from '@material-ui/core';

//Components Material
import { Alert } from '@material-ui/lab';
import {
    DriveFileRenameOutline,
    Autorenew,
    CallSharp,
} from '@material-ui/icons';




//Local Componet
import clsx from 'clsx';
import logo from '../img/logo.png';
import { useStyles } from './SinginStyles';
import { Copyright } from '../../../dashboard/componet/UI/CopyRight'



export default function SiginHelpCode({ dataCell, singin, backStep, setsendCode, SendSmsCellphone, sendCallCellphone, succesCode, errorCode, mensaje, messageSucces, sendCode }) {


    const classes = useStyles();
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const borderRadius = clsx(classes.padding, classes.borderRadius);
    const iconSize = clsx(classes.largeIcon);
    const dispatch = useDispatch();
    const [disable, setdisable] = useState(false)

    const isLoading = useSelector(selectLoading);
    const user = useSelector(selectUser);
    const payload = useSelector(selectPayload);


    const onSubmit = (data) => {

    }


    return (


        <div className={classes.centerv} >
            <Container maxWidth="xs" alignitems="center" textAlign='center !important' >
                <Paper className={borderRadius}>
                    <div className={classes.margin}>
                        <Grid container justify="center" style={{ marginTop: '15px', maxwidth: '350px' }}>
                            <img src={logo} width="100%" alt="Logo" />
                        </Grid>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justify="center"
                                alignItems="center"
                                alignContent="center"
                                wrap="nowrap"
                                style={{ marginLeft: '0px', marginTop: '20px' }}
                            >


                                <Grid item xs={6} style={{ border: '1px solid #b2dcf4' }} >
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            margin: '0 auto',
                                            textAlign: 'center',
                                            justify: "center",
                                            alignItems: "center",
                                            alignContent: "center",
                                            '& > :not(style)': {
                                                m: 1,
                                            },
                                        }}

                                        onClick={((e) => {
                                            SendSmsCellphone(dataCell, "reload")
                                            setTimeout(() => {
                                                backStep()
                                            }, 4000);
                                            setdisable(true)
                                            setTimeout(() => {
                                                setsendCode(true)
                                                setdisable(false)
                                            }, 10000);
                                        })}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <IconButton
                                            aria-label="Reenviar"
                                            iconStyle={iconSize}
                                            disable={disable}
                                        >
                                            <Autorenew />
                                        </IconButton>

                                        <Typography variant="h6" color="#0a699e" align='center'>
                                            Reenviar Código
                                        </Typography>

                                        <Typography variant="body2" color="#0a699e" align='center'>
                                            Obtener un nuevo código de verificación
                                        </Typography>

                                    </Box>
                                </Grid>
                                &nbsp;
                                <Grid item xs={6} style={{ border: '1px solid #b2dcf4' }}>
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            margin: '0 auto',
                                            textAlign: 'center',
                                            justify: "center",
                                            alignItems: "center",
                                            alignContent: "center",
                                            '& > :not(style)': {
                                                m: 1,
                                            },
                                            
                                        }}
                                        onClick={((e) => {
                                            sendCallCellphone(dataCell)
                                            setTimeout(() => {
                                                backStep()
                                            }, 4000);
                                            setdisable(true)
                                            setTimeout(() => {
                                                setsendCode(true)
                                                setdisable(false)
                                            }, 10000);
                                        })}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <IconButton aria-label="Reenviar" iconStyle={iconSize} disable={disable} >
                                            <CallSharp />
                                        </IconButton>

                                        <Typography variant="h6" color="#cecece" align='center' >
                                            Llamar
                                        </Typography>

                                        <Typography variant="body2" color="#cecece" align='center' >
                                            Obtener una llamada con el código.
                                        </Typography>

                                    </Box>
                                </Grid>
                            </Grid>
                            &nbsp;&nbsp;
                            {!sendCode && <div style={{color:"red", textAlign:"center"}}>Ya se te ha sido enviado un código por favor espera un momento antes de enviar otro</div>}
                            <div className={classes.wrapper}>
                                <Grid container justify="center" style={{ marginTop: '20px' }}>
                                    <Button
                                        disabled={isLoading}
                                        onClick={((e) => { backStep() })}
                                        variant="contained"
                                        color="primary"
                                        style={{ textTransform: "none", width: "inherit" }}>
                                        Volver
                                    </Button>
                                </Grid>
                                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                            {succesCode && <Alert severity="success">{messageSucces}</Alert>}
                            {errorCode && <Alert severity="error">{mensaje}</Alert>}

                            <Grid container justify="space-between" alignItems="flex-end" style={{ marginTop: '10px' }}>
                                <Grid item >

                                </Grid>
                            </Grid>
                            <br />
                        </form>
                    </div>
                    <Box >
                        <Copyright />
                    </Box>
                </Paper>
            </Container >
        </div>

    );

}