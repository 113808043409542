// Dependencies
import * as auth0 from 'auth0-js';
/**
 * @description Auth0 API Manager services
 */

// Initialize Auth0
const _auth0 = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT,
  responseType: 'token id_token',
  scope: 'openid email'
});

/**
 * Make user authentication
 * @param { string } email - Email of the user
 * @param { string } password - Password of the user
 * @return { Promise } Auth0 response
 */
export function loginUser(username, password) {
  return new Promise((resolve, reject) => {
    _auth0.client.login({
      realm: process.env.REACT_APP_AUTH0_COMMERCE,
      username,
      password
    }, (err, authResult) => {
      if (err) {
        reject(err);
      } else {
        resolve(authResult);
      }
    });
  });
}

/**
 * Get user data from Auth0 API
 * @param { string } token - AccessToken from Auth0
 * @return { Promise } Auth0 response
 */
export function getUser(token) {
  return new Promise((resolve, reject) => {
    _auth0.client.userInfo(token, (err, user) => {
      if (err) {
        reject(err);
      } else {
        resolve(user);
      }
    });
  });
}