//React
import React, { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";



//Redux
import { ID_SUCURSAL, ID_COMERCIO, SUCURSAL } from "../../app/api-client/constants";
import { useDispatch } from 'react-redux';
import { getFileToDownload } from '../domain/api/PrestapolisApi';
//Icons Material
import {
    Cancel,
    CreateNewFolder,
    CheckCircle
} from '@material-ui/icons';

//Icons Material
import {
    Clear,
} from '@material-ui/icons';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';

//Components Material
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Modal,
    Backdrop,
    Fade,
    Paper,
    TextField,
    Button,
    NativeSelect,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    FormControlLabel,
    Switch,
    Box,
    Fab,
} from '@material-ui/core';

//Local Component
import MigaPan from '../../app/orussystem/component/MigaPan';
import { HelpValidate } from '../../user/utils/helpValidate';
import { getZonesList, getCitiesList } from '../domain/api/PrestapolisApi';



//My style
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperModal: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #2c8aaf',
        width: 450,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 4, 3),
    },
    seeMore: {
        marginTop: theme.spacing(3),
    },
    div: {
        margin: '0 auto',
        minWidth: '40%',
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        overflow: 'auto',
        margin: '0 auto',
        flexDirection: 'column',
    },
    paperPhoto: {
        padding: theme.spacing(0),
        display: 'flex',
        margin: '0 auto',
        maxWidth: '500px',
        overflow: 'auto',
        flexDirection: 'column',
    },
    btnFactura: {
        margin: '0px !important',
        textAlign: 'center',
        justifycontent: 'center'
    },
    button: {
        color: '#ff0000 !important',
    },
    img: {
        maxWidth: '1000px',
    },
    inputPerson: {
        marginTop: '15px'
    },
    input: {
        display: 'none',
      },
}));

export default function ModalSucursalEdit({ isOpen, setopenMondal, onSubmit, data }) {
    const classes = useStyles();
    const [nombre, setnombre] = useState("")
    const [nombreAdmin, setnombreAdmin] = useState("")
    const [documentoAdmin, setdocumentoAdmin] = useState("")
    const [email, setemail] = useState("")
    const [cellphone, setcellphone] = useState("")
    const [tipo, settipo] = useState("")
    const [estado, setestado] = useState("")
    const [direccion, setdireccion] = useState("")
    const [departamento, setdepartamento] = useState("")
    const [municipio, setMunicipio] = useState("")
    const [numberVendor, setnumberVendor] = useState("")
    const [listDepartamento, setlistDepartamento] = useState([])
    const [listMunicipio, setlistMunicipio] = useState([])
    const [habilitado_ventas, setHabilitado_ventas] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const [logoMessage, setLogoMessage] = useState("Cargar Logo")
    const [descargarlogoMessage, setdescargarLogoMessage] = useState("Descargar archivo")
    const [showLoad, setshowLoad] = useState(false)
    const [messgeFile, setMessageFile] = useState("")
    const [fileUpload, setfileUpload] = useState(false)
    const [logoFile, setLogoFile] = useState("")
    const [fileName, setfileName] = useState("")
    const [fileUploadErr, setfileUploadErr] = useState(false)

    const dispatch = useDispatch();
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();

    const ClearForm = () => {
        setnombre("")
        setnombreAdmin("")
        setdocumentoAdmin("")
        setemail("")
        setcellphone("")
        settipo("")
        setdireccion("")
        setdepartamento("")
        setMunicipio("")
        setnumberVendor("")
        setestado("")
    }

    const SendForm = (data) => {
        data = {...data, logo:fileName}
        onSubmit(data, listDepartamento, listMunicipio)
        // ClearForm();
    }

    const ExitModal = () => {
        setopenMondal(false);
        ClearForm();
    }

    const GetDepartamentos = () => {
        getZonesList(dispatch)
            .then((response) => {
                if (response.respuesta) {
                    setlistDepartamento(response.respuesta)
                } else {
                   
                }
            }).catch((err) => {

            })
    }

    const handledChangeDepartamento = (value) => {
        setdepartamento(value)
        GetMunicipios(value)
    }

    const GetMunicipios = (id) => {
        getCitiesList(dispatch, parseInt(id, 10))
            .then((response) => {
                if (response.respuesta) {
                    setlistMunicipio(response.respuesta)
                    if (parseInt(id, 10) === 11) {
                        setMunicipio("11001")
                    }
                } else {
               
                }
            })
    }

    const SetData = () => {


        let idDepartamento = data === undefined
            ? ""
            : data.localizacione === undefined
                ? ""
                : data.localizacione.departamento === undefined
                    ? ""
                    : parseInt(data.localizacione.departamento)

        let idMunicipio = data === undefined
            ? ""
            : data.localizacione === undefined
                ? ""
                : data.localizacione.id === undefined
                    ? ""
                    : parseInt(data.localizacione.id)

        let addres = data === undefined
            ? ""
            : data.localizacione === undefined
                ? ""
                : data.localizacione.nombre === undefined
                    ? ""
                    : data.localizacione.nombre

        // let idMunicipio = data.localizacione.id

        setnombre(data.nombre)
        setnombreAdmin(data.name_admin)
        setdocumentoAdmin(data.cedula_admin)
        setemail(data.email)
        setcellphone(data.phone)
        settipo(data.types)
        setdireccion(addres)
        setdepartamento(idDepartamento)
        setMunicipio(idMunicipio)
        setnumberVendor(data.vendedoresPermitidos)
        setestado(data.estado)
    }

    useEffect(() => {
        GetDepartamentos();
        SetData()

    }, [data])

    const descargarLogo = (file) => {
      
        // file = null;
        if (file !== null) {
            getFileToDownload(file)
                .then((response) => {
                   
                    if (response.respuesta) {
                        window.open(response.mensaje);
                   
                    }
                })
                .catch((err) => {
                   
                    if (err.status === 401) {
                        this.props.onSetAuthUser(null);
                    }
                });
        } else {
           
        }

    }
    const onFileUploadFacture = async (event, type, filename) => {
        type = type === null || type === undefined ? "" : type;  
       
        setshowLoad(true);
        const file = event.target.files[0];
      

      if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" ) {

            const formData = new FormData();
            //this.setState({ [loading]: true });
            setshowLoad(true);
            // Comercios/nit/nit_nombresucursal_logo_sucursal.png
            const nit = localStorage.getItem(SUCURSAL);
            const nombreLogo = nit + "_" + nombre + "_logo_sucursal."
            const lastDot = file.name === undefined ? file.name = "" : file.name.lastIndexOf(".");
            const ext = file.name.substring(lastDot + 1);
            formData.append("file", file, nombreLogo + ext);
            let msg = "";
            if (file.size < 10485760 && nit !== "") {
                let url = `${process.env.REACT_APP_FILES}?doc=Comercios/${nit}`;
                await fetch(url, {
                    // content-type header should not be specified!
                    method: "POST",
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((response) => {
                      
                        if (response.respuesta) {
                            //this.setState({ [loading]: false, [type]: response.mensaje });{
                                setMessageFile("Documento cargado correctamente")
                                let nameFile = file.name;
                                let nameFrontFile = nameFile.length >= 40 ? nameFile.slice(-30) : nameFile;
                             
                                setLogoMessage(nameFrontFile)
                                setfileUpload(true)
                                setLogoFile(response.mensaje)
                                setTimeout(() => {
                                    setfileUpload(false)
                                }, 2000)
                                setshowLoad(false);
                                setfileName(response.mensaje)
                        }
                        else {
                         
                            setshowLoad(false);
                            setfileName("")
                        }
                    })
                    .catch((error) => {
                 
                        msg = "Error";
                        setMessageFile("Error cargando documento")
                        setfileUploadErr(true)
                        setTimeout(() => {
                            setfileUploadErr(false)
                        }, 2000)
                        setshowLoad(false);
                        setfileName("")
                    });
            } else {

                setMessageFile("El documento supera el maximo de 10 MB permitidas")
                setfileUploadErr(true)
                setTimeout(() => {
                    setfileUploadErr(false)
                }, 2000)
                setshowLoad(false);
                setfileName("")
            }
        } else {

            setshowLoad(false);
            event.target.value = null;
            setMessageFile("Unicamente archivos, .png, .jpg, .jpeg")
            setfileUploadErr(true)
            setTimeout(() => {
                setfileUploadErr(false)
            }, 2000)
            setfileName("")
        }
       
    };


    return (
        <div>
            <Modal
                open={isOpen}
                onClose={(e) => {
                    ExitModal()
                }}
                className={classes.modal}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isOpen}>
                    <div className={classes.paperModal}>
                        <Grid container spacing={1} alignItems="flex-end" textAlign="center">
                            <Grid item xs={12}>
                                <form onSubmit={handleSubmit(SendForm)} autocomplete="off">

                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                        <Grid item>
                                            <AccountCircle color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} >
                                            <TextField
                                                placeholder="Escribe el nombre de la sucursal * "
                                                label="Nombre Sucursal *"
                                                fullWidth
                                                id="nameSucursal"
                                                name="nameSucursal"
                                                autocomplete="off"
                                                value={nombre}
                                                defaultValue={nombre}
                                                InputLabelProps={{ className: "label__color" }}
                                                type="text"
                                                {...HelpValidate().validateText(register('nameSucursal', {
                                                    required: "El nombre de la sucursal es requerido.",
                                                    pattern: {
                                                        minLength: 3,
                                                        message: "el nombre debe tener minimo 3 caracteres"
                                                    }
                                                }), setnombre)}
                                                error={!!errors.nameSucursal}
                                                helperText={errors.nameSucursal?.message} />
                                        </Grid>
                                    </Grid>

                                   
                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson}>
                                        <Grid item>
                                            <SettingsPhoneIcon color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} >
                                            <TextField
                                                placeholder="Escribe el # de celular *"
                                                label="Celular *"
                                                fullWidth
                                                id="celular"
                                                name="cellPhone"
                                                autocomplete="off"
                                                type="tel"
                                                value={cellphone}
                                                defaultValue={cellphone}
                                                InputLabelProps={{ className: "label__color" }}
                                                {...HelpValidate().validateNumber(register('cellPhone', {
                                                    required: "El Celular es requerida.",
                                                    minLength: {
                                                        value: 10,
                                                        message: 'El Celular debe tener minimo 10 caracteres'
                                                    },
                                                }), 10, 10, setError, clearErrors, setcellphone)}
                                                error={!!errors.cellPhone}
                                                helperText={errors.cellPhone?.message} />
                                        </Grid>
                                    </Grid>


                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson}>
                                        <Grid item>
                                            <AccountCircle color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} >
                                            <TextField
                                                placeholder="Cantidad de vendedores permitida"
                                                label="Cantidad de vendedores permitida"
                                                fullWidth
                                                id="numVendedor"
                                                name="numVendedor"
                                                autocomplete="off"
                                                type="tel"
                                                value={numberVendor}
                                                defaultValue={numberVendor}
                                                InputLabelProps={{ className: "label__color" }}
                                                {...HelpValidate().validateNumber(register('numVendedor', {
                                                    required: "El campo es requerida.",
                                                    minLength: {
                                                        value: 1,
                                                        message: 'El campo requiere minimo 1 caracter'
                                                    },
                                                }), 1, 3, setError, clearErrors, setnumberVendor)}
                                                error={!!errors.numVendedor}
                                                helperText={errors.numVendedor?.message} />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                        <Grid item>
                                            <AccountCircle color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} >
                                            <FormControl align="left" style={{ width: "100%" }}>
                                                <InputLabel id="input-select">Estado</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    name="estadp"
                                                    align="Left"
                                                    autocomplete="off"
                                                    fullWidth
                                                    defaultValue={estado}
                                                    value={estado}
                                                    {...register("estado", {
                                                        required: "Requerido",
                                                    })}
                                                    error={!!errors.estado}
                                                    helperText={errors.estado?.message}
                                                    onChange={(e) => { setestado(e.target.value) }}

                                                >
                                                    <MenuItem value="">
                                                        <em>Estado</em>
                                                    </MenuItem>
                                                    <MenuItem value="A">Activo</MenuItem>
                                                    <MenuItem value="I">Inactivo</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>


                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                        <Grid item>
                                            <AccountCircle color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} >
                                            <FormControl align="left" style={{ width: "100%" }}>
                                                <InputLabel id="input-select">Tipo</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    name="Tipo"
                                                    align="Left"
                                                    autocomplete="off"
                                                    fullWidth
                                                    defaultValue={tipo}
                                                    value={tipo}
                                                    {...register("tipo", {
                                                        required: "Requerido",
                                                    })}
                                                    error={!!errors.tipo}
                                                    helperText={errors.tipo?.message}
                                                    onChange={(e) => { settipo(e.target.value) }}

                                                >
                                                    <MenuItem value="">
                                                        <em>Tipo</em>
                                                    </MenuItem>
                                                    <MenuItem value="Fisica">Fisica</MenuItem>
                                                    <MenuItem value="Web">Web</MenuItem>
                                                    <MenuItem value="WhatsApp">WhatsApp</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                        <Grid item>
                                            <AccountCircle color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} >
                                            <TextField
                                               value={data.logo}
                                               fullWidth></TextField>
                                        </Grid>
                                    </Grid>

                                    {/*LOGO*/}
                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson}>
                                       
                                        <Grid item md={true} sm={true} xs={true} >
                                        <div className="container">
                                            <div className="row">
                                            <label htmlFor="logoSucursal">
                                                <input
                                                    style={{ display: "none" }}
                                                    id="logoSucursal"
                                                    name="logoSucursal"
                                                    type="file"
                                                    required
                                                    accept="image/jpeg,image/png,image/jpg"
                                                    onChange={((e) => {
                                                        onFileUploadFacture(
                                                            e,
                                                            "logoSucursal",
                                                            "img_logo"
                                                        )
                                                    })}
                                                />
                                                <Fab
                                                    color="primary"
                                                    size="small"
                                                    component="span"
                                                    aria-label="add"
                                                    variant="extended"
                                                >
                                                    <CreateNewFolder />&nbsp;&nbsp;{logoMessage}&nbsp;&nbsp;
                                                </Fab>
                                            </label>
                                            <label style={{marginLeft: "5px"}}>
                                                <input
                                                    style={{ display: "none" }}
                                                   
                                                />
                                               
                                                <Fab
                                              
                                                    color="primary"
                                                    size="small"
                                                    component="span"
                                                    aria-label="add"
                                                    variant="extended"
                                                  
                                                    onClick={() => descargarLogo(data.logo)}  
                                                >
                                                    &nbsp;&nbsp;{descargarlogoMessage}&nbsp;&nbsp;
                                                </Fab>
                                            </label>  
                                            </div>
                                            {showLoad &&
                                                    (

                                                        <Box
                                                            sx={{
                                                                display: 'grid',
                                                                margin: '0 auto',
                                                                '& > :not(style)': {
                                                                    m: 1,
                                                                    width: 50,
                                                                    height: 50,
                                                                },
                                                            }}
                                                        >
                                                            <ReactLoading
                                                                type={"bars"}
                                                                color={"#000"}
                                                                height={50}
                                                                width={50}
                                                            />
                                                        </Box>

                                                    )
                                                }
                                        </div>
                                        </Grid>
                                        </Grid>

                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                        {/* <Grid item>
                                            <AssignmentTurnedInIcon color="primary" />
                                        </Grid> */}
                                        <Grid item md={true} sm={true} xs={true} style={{marginLeft: "70px"}} >
                                            <FormControlLabel
                                                value="start"
                                                control={<Switch  checked={habilitado_ventas} onChange={(e) => {setHabilitado_ventas(!habilitado_ventas)}} name="gilad" />}
                                                label="Habilitado para ventas"
                                                labelPlacement="start"
                                            />
                                        </Grid>
                                    </Grid>


                                    <div className={classes.wrapper}>
                                        <Grid container justify="center" style={{ marginTop: '35px' }}>
                                            <Button
                                                // disabled={isContinuo}
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                style={{ textTransform: "none", width: "inherit" }}>
                                                Actualizar
                                            </Button>

                                        </Grid>
                                    </div>
                                </form>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );


}