//React 
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import ModalCalculadora from '../modals/modalCalculadora';

//Redux
import { ID_SUCURSAL, ID_COMERCIO, USER_ROL, ID_SELLER, ID_USER, NAME_COMERCIO, BAAS } from "../../app/api-client/constants";
import { useDispatch } from 'react-redux';

//Icons Material
import {
    Visibility,
} from '@material-ui/icons';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';


//Components Material
import { makeStyles } from '@material-ui/core/styles';
import SearchBar from "material-ui-search-bar";
import {
    Paper,
    Grid,
    Button,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    TextField,
    TableContainer
} from '@material-ui/core';


//Local Component
import {
    geSolicitudes,
    statusRequest,
    getSolicitudesByFilter,
    getVendedoresComercio,
    getVendedoresSucursal,
    getSucursalByIdCommerce,
    asingUser,
    getUsersComerce,
    geListSucuralById,
    getSucursalByIdCommer,

} from '../domain/api/PrestapolisApi'
import Paginator from '../utils/paginator'
import ModalContac from '../modals/modalContactos';
import Title from '../../app/orussystem/component/Title';
import MigaPan from '../../app/orussystem/component/MigaPan';
import {
    formatData,
    formatMoney
} from '../utils/validation'
import { ModalTowButton } from '../modals/modalTowButton';
import ModalAsignarMiSolicitud from '../modals/modalAsigarMisolicitud';

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        paddingTop: 10,
        display: "flex",

    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center"
    },
    search: {
        // border: "2px solid #2c8aaf",
        borderRadius: "5px",
        height: "10px",
        backgroundColor: "#ffffff",
        boxShadow: "0 1px 1px 1px rgb(0 0 0 / 8%)"
    },
    Data: {
        backgroundColor: "#ffffff"
    }
}));


export const MisSolicitudes = ({ }) => {
    //const classes = SolcitudesStyle();
    const classes = useStyles();
    const [data, setData] = useState([])
    const [isLoadUser, setisLoadUser] = useState(false)
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(10)
    const [filter, setFilter] = useState("")
    const [totalElements, settotalElements] = useState(0)
    const [totalPage, settotalPage] = useState(0)
    const [id, setId] = useState("")
    const [indice, setIndice] = useState(0)
    const [openMondal, setopenMondal] = useState(false)
    const [dataContac, setDataContac] = useState([])
    const [listEstates, setlistEstates] = useState([])
    const [arrayEstates, setarrayEstates] = useState([])
    const [filterEstates, setFilterEstates] = useState("")
    const [filterComercios, setFilterComercios] = useState("")
    const [modalCalc, setmodalCalc] = useState(false);
    const [dataCalc, setdataCalc] = useState({});
    const [isOpenModalTow, setisOpenModalTow] = useState(false);
    const [disBusiness, setdisBusiness] = useState(false);
    const [isOpenModalthree, setisOpenModalthree] = useState(false);
    const [listVendedor, setlistVendedor] = useState([])
    const [listSucursal, setlistSucursal] = useState([])
    const [id_solicitud, setId_solicitud] = useState("")

    //Modal Asiganr
    const [isOpen_asign, setIsOpen_asign] = useState(false)
    const [error_asign, setError_asign] = useState(false)
    const [succes_asign, setSucces_asign] = useState(false)
    const [user_asign, setUser_asign] = useState("")
    const [errMessage_asign, setErrMessage_asign] = useState(false)
    const [vendedor_asign, setVendedor_asign] = useState("")
    const [sucursal_asing, setSucursal_asing] = useState("")


    //Modal Asiganr SELLER
    const [isOpen_asign_seller, setIsOpen_asign_seller] = useState(false)
    const [error_asign_seller, setError_asign_seller] = useState(false)
    const [succes_asign_seller, setSucces_asign_seller] = useState(false)
    const [user_asign_seller, setUser_asign_seller] = useState("")
    const [errMessage_asign_seller, setErrMessage_asign_seller] = useState(false)
    const [sucursal_asing_seller, setSucursal_asing_seller] = useState("");
    const [listSucursal_Seller, setlistSucursal_Seller] = useState([]);

    const [listSucursalByUser, setlistSucursalByUser] = useState([])


    const history = useHistory();
    let idUser = localStorage.getItem(ID_USER);
    idUser = idUser !== null && idUser !== undefined ? idUser : ""

    let idSeller = localStorage.getItem(ID_SELLER);
    idSeller = idSeller !== null && idSeller !== undefined ? idSeller : ""

    let idSucursal = localStorage.getItem(ID_SUCURSAL);
    let idComercio = localStorage.getItem(ID_COMERCIO);
    let rol = localStorage.getItem(USER_ROL);
    // idUser = idUser === null? "":idUser;
    const styleSearch = classes.search
    
    let nameComercio = localStorage.getItem(NAME_COMERCIO)
    let baas = localStorage.getItem(BAAS)
    let namecomerce = baas != "true" ? 'Home' : nameComercio

    const dispatch = useDispatch();

    const handleRowClick = (row) => {
        history.push(`/misolicitud/asignar/${row.id}`);

    }

    useEffect(async () => {
        reloadPage()
    }, [page], []);


    const reloadPage = () => {
        //    getListSolicitud(idComercio,page,size,filter,idUser)
        if (rol !== "ROLE_SELLER") {
            loadDataTable(idComercio, page, size, filter, "", filterEstates)
        } else {
            // loadDataTable(idComercio, page, size, filter, "", filterEstates)
            loadDataTable(idComercio, page, size, filter, idSeller, filterEstates)
        }

        getUserComerce(idComercio, 0, 1000, "")
        getStatusRequest();
        getSucursalList();
    }


    //Metodo para cargar datos de la tabla
    const loadDataTable = (id, page, size, filter, id_comercio, arrayEstados) => {
        setisLoadUser(true);
        getSolicitudesByFilter(dispatch, id, page, size, filter, id_comercio, arrayEstados)
            .then((response) => {
                if (response.code === "0000") {
                    let totalPages = response.totalPages;
                    let totalElements = response.totalElements;
                    let data = response.content;

                    setData(data);
                    settotalElements(totalElements);
                    settotalPage(totalPages);

                    setisLoadUser(false);
                } else {
                   
                    setisLoadUser(false)
                }

            })
            .catch((error) => {
                
                if (error.status === 401) {

                }
            });
    };

    // Sucursal list seller
    const getSucursalList = () => {
        geListSucuralById(dispatch, idSucursal)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    setlistSucursal_Seller(response.respuesta)
                }
            }).catch((err) => {
              
            })
    }

    //Metodo para listar las sucursales de un usuario
    const getListSucursales = (list) => {
        geListSucuralById(dispatch, list)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    setlistSucursal(response.respuesta)
                }
            })
            .catch((err) => {
                setlistSucursal([])
                
            })
    }

     //Metodo para listar las sucursales si el vendedor el ADMIN COMER
     const getListSucursalesComer = (id) => {
        getSucursalByIdCommer(dispatch,  id, 0, 1000, "")
            .then((response) => {
                if (response.code === "0000") {
                    setlistSucursal(response.content)
                }
            })
            .catch((err) => {
                setlistSucursal([])
            })

    }


    //Metodo para setear user y listar vendedores
    const setUsuerComerce = (value) => {
        setVendedor_asign(value)
        let sucursales = ""
        let rol = ""
        listVendedor.map((item) => {
            if (parseInt(value) === parseInt(item.id)) {
                sucursales = item.sucursales
                rol = item.rol.rol
            }
        })
        if(rol === "ROLE_COMMERCE_ADMIN"){
            getListSucursalesComer(idComercio);
        }else{
            getListSucursales(sucursales);
        }
    }


    //Metodo para listar Usuarios del comercio
    const getUserComerce = (id, page, size, filter) => {


        getUsersComerce(dispatch, id, page, size, filter)
            .then((response) => {
                if (response.code === "0000") {
                    let data = response.content;
                    setlistVendedor(data);
                } else {
                
                    
                }
            })
            .catch((error) => {
              
                if (error.status === 401) {

                }
            });

    };



    const getStatusRequest = () => {
        statusRequest(dispatch)
            .then((response) => {
                let data = response.respuesta;
                let lista = [];
                data.map((item) => {
                    lista.push({
                        id: item.id,
                        value: item.estado,
                        label: item.estado,
                    });
                });

                setarrayEstates(lista)
            })
            .catch((err) => {
            
                if (err.status === 401) {

                }
            });
    }

    //Filter Estados    
    function getDropdownButton({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: Seleccionar todos`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} Seleccionado`;
        }
    }

    const setFilterSearch = (setState, arrayFilter) => {
        return new Promise((resolve, reject) => {
            resolve(setState(arrayFilter));
        })
    }

    function onChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            if (event.name === "estados-filter") {
                let idString = []
                arrayEstates.map((item) => {
                    idString.push(
                        item.id
                    )
                })
                idString = idString.toString();
                setFilterEstates(idString)
            }
            this.setState(this.options);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            if (event.name === "estados-filter") {
                setFilterEstates("")
            }
            this.setState([]);
        } else if (event.action === "deselect-option") {
            if (event.name === "estados-filter") {
                let idString = []
                let arrayFilter = value.filter((o) => o.value !== "*")
                arrayFilter.map((item) => {
                    idString.push(
                        item.id
                    )
                })
                idString = idString.toString();
                setFilterEstates(idString)
            }
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {
            if (event.name === "estados-filter") {

                let select = [];
                let stringSelect = ""
                value.map((item) => {
                    select.push(
                        item.id
                    )
                })


                stringSelect = select.toString();
                // setFilterEstates(stringSelect)
                setFilterSearch(setFilterEstates, stringSelect)
                    .then((response) => {
                        // loadDataTable(idComercio,page,size,filter, filterComercios, filterEstates)
                    })
            }


            this.setState(value);
        }
    }

    const reloadTable = () => {
        if (rol !== "ROLE_SELLER") {
            loadDataTable(idComercio, page, size, "", "", filterEstates)
        } else {
            loadDataTable(idComercio, page, size, "", idSeller, filterEstates)
        }
        setFilterComercios("");
        setFilter("")
        setlistEstates([])
    }


    const filtrar = () => {

        if (filter === "") {
            // getListSolicitud(idComercio,page,size,filter,idUser)
            if (rol !== "ROLE_SELLER") {
                loadDataTable(idComercio, page, size, "", "", filterEstates)
            } else {
                loadDataTable(idComercio, page, size, "", idSeller, filterEstates)
            }

        } else {

            if (rol !== "ROLE_SELLER") {
                loadDataTable(idComercio, page, size, filter, "", filterEstates)
            } else {
                loadDataTable(idComercio, page, size, filter, idSeller, filterEstates)
            }
            // getListSolicitud(idComercio,page,size,e,idUser)

        }
    }

    const modalContac = (data) => {

     
        setDataContac(data.datosPersona);
        setopenMondal(!openMondal);
    }

    const setDataModalCalc = (data) => {
        // setmodalCalc(true)
        let estado = data.estadoSolicitud.estado;
        if (estado === "Aprobado pendiente pago" || estado === "Aprobado") {
            setisOpenModalthree(true)
        } else {
            setisOpenModalTow(true);
        }

        setdataCalc(data);
    }

    const handledAccept = () => {
        setisOpenModalTow(false)
        setmodalCalc(true)
    }

    const handledAcceptThree = () => {
        setisOpenModalthree(false)
        setisOpenModalTow(true)
    }

    const validateDisable = (data) => {

        let estado = data.estadoSolicitud.estado;
        let subEstado = data.subEstado.subestado;

        // if (estado === "Prevalidacion" || estado === "Procesada") {
        //     return true;
        // } else if (estado === "En Estudio" && subEstado === "En Estudio") {
        //     return true;
        // } else if (estado === "En Estudio" && subEstado === "Negociación") {
        //     return false;
        // } else if (estado === "Preaprobado") {
        //     return false;
        // } else if (estado === "Aprobado pendiente pagare" || estado === "Aprobado pendiente pago" || estado === "Aprobado") {
        //     return true;
        // } else {
        //     return true;
        // }

        if (estado === "En Estudio" && subEstado === "Negociación") {
            return false;
        } else{
            return true;
        }


    }


    const setStateData = (setState, data) => {
       
        return new Promise((resolve, reject) => {
            resolve(setState(data));
        })
    }

    const setDataModalAsing = (data) => {

        let sucursalId = data.sucursal !== undefined && data.sucursal !== null
            ? data.sucursal.id
            : ""

        let vendedorId = data.vendedor !== undefined && data.vendedor !== null
            ? data.vendedor.id
            : ""

        let document = data.datosPersona !== undefined && data.datosPersona !== null
            ? data.datosPersona.documentNumber
            : ""

        let idSoli = data.id!== undefined && data.id !== null
            ? data.id
            : ""


        setSucursal_asing(sucursalId);
        setVendedor_asign(vendedorId);
        setUser_asign(document)
        setId_solicitud(idSoli)
        
    }

    const setDataModalAsingSeller = (data) => {
        setUser_asign_seller(data.datosPersona.documentNumber)
        setSucursal_asing(data.sucursal.id)
        setId_solicitud(data.id)
    }

    //Asignar usuario
    const handledModalAsing = (data) => {
       
        setStateData(setDataModalAsing, data)
            .then((response) => {
                setIsOpen_asign(true)
                setVendedor_asign(data.vendedor.id)
                
                let sucursales = data.vendedor.sucursales
                let rol = data.vendedor.rol.rol
                if(rol === "ROLE_COMMERCE_ADMIN"){
                    getListSucursalesComer(idComercio)
                }else{
                    getListSucursales(sucursales);
                }
                setSucursal_asing(data.sucursal.id)
            })      
    }

    //Asignar ususario seller
    const handledModalAsingSeller = (data) => {

        setStateData(setDataModalAsingSeller, data)
            .then((response) => {
                setIsOpen_asign_seller(true)
                
            })
    }

    const asingUserSelect = (data) => {

        if (rol === "ROLE_SELLER") {

            const request = {
                solicitud: {
                    id: id_solicitud
                },
                vendedore: idSeller,
                datosPersona: {
                    documentNumber: user_asign_seller
                },
                usuario: idSeller,
                sucursales: sucursal_asing_seller
            }

            asingUser(dispatch, request)
                .then((response) => {

                    if (response.codigoRespuesta === "0000") {
                        setSucces_asign_seller(true)
                        setTimeout(() => {
                            setSucces_asign_seller(false)
                            setIsOpen_asign_seller(false)
                        }, 3000)

                        reloadPage();


                    } else {
                        setErrMessage_asign_seller(response.mensaje)
                        setError_asign_seller(true)
                        setTimeout(() => {
                            setError_asign_seller(false)
                        }, 4000);
                    }

                }).catch((err) => {
                    setErrMessage_asign_seller("¡Se presento un error en el servicio!")
                    setError_asign_seller(true)
                 
                    setTimeout(() => {
                        setError_asign_seller(false)
                    }, 4000);
                })

        } else {
            const request = {
                solicitud: {
                    id: id_solicitud
                },
                vendedore: vendedor_asign,
                datosPersona: {
                    documentNumber: user_asign
                },
                usuario: idSeller,
                sucursales: data.sucursalID
            }

            asingUser(dispatch, request)
                .then((response) => {

                    if (response.codigoRespuesta === "0000") {
                        setSucces_asign(true)
                        setTimeout(() => {
                            setSucces_asign(false)
                            setIsOpen_asign(false)
                        }, 3000)

                        reloadPage();


                    } else {
                        setErrMessage_asign(response.mensaje)
                        setError_asign(true)
                        setTimeout(() => {
                            setError_asign(false)
                        }, 4000);
                    }

                }).catch((err) => {
                    setErrMessage_asign("¡Se presento un error en el servicio!")
                    setError_asign(true)
                  
                    setTimeout(() => {
                        setError_asign(false)
                    }, 4000);
                })
        }
    }

    return (
        <>
            <ModalTowButton
                handledtClose={setisOpenModalTow}
                isOpen={isOpenModalTow}
                text="Recuerda que al modificar las condiciones de un crédito en estado Preaprobado el cliente recibirá un nuevo correo con las nuevas condiciones de crédito para su aprobación."
                handledAccept={handledAccept}
                title={"Atención"}
                isTowButton={false}
            />

            <ModalTowButton
                handledtClose={setisOpenModalthree}
                isOpen={isOpenModalthree}
                text="Esta solicitud ya cuenta con una firma que legaliza la operación de crédito, al modificarla debes estar seguro de contar con la disponibilidad del cliente para firmar nuevamente, de lo contrario el crédito quedaría sin validez"
                handledAccept={handledAcceptThree}
                title={"Atención"}
                isTowButton={false}
            />

            <ModalContac
                isOpen={openMondal}
                setopenMondal={setopenMondal}
                dataContac={dataContac}
            />

            <ModalCalculadora
                setopenMondal={setmodalCalc}
                isOpen={modalCalc}
                data={dataCalc}
                setdata={setdataCalc}
                reloadView={reloadPage}
            />

            <ModalAsignarMiSolicitud
                isOpen={isOpen_asign}
                setopenMondal={setIsOpen_asign}
                errorFile={error_asign}
                succesFile={succes_asign}
                handeldClick={asingUserSelect}
                setuserDni={setUser_asign}
                userDni={user_asign}
                messageErro={errMessage_asign}
                listVendedor={listVendedor}
                setUsuerComerce={setUsuerComerce}
                setlistSucursalComer={listSucursalByUser}
                vendorDNI={vendedor_asign}
                sucursalID={sucursal_asing}
                listSucursal={listSucursal}
                setSucursalID={setSucursal_asing}
            />


            <ModalAsignarMiSolicitud
                isOpen={isOpen_asign_seller}
                setopenMondal={setIsOpen_asign_seller}
                errorFile={error_asign_seller}
                succesFile={succes_asign_seller}
                handeldClick={asingUserSelect}
                setuserDni={setUser_asign_seller}
                userDni={user_asign_seller}
                listVendedor={[]}

                messageErro={errMessage_asign_seller}
                sucursalID={sucursal_asing_seller}
                listSucursal={listSucursal_Seller}
                setSucursalID={setSucursal_asing_seller}
            />


            <Grid item xs={12} >
                <MigaPan namePage="Mis Clientes" to="/" >
                {namecomerce}
                </MigaPan>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={3} sm={4} md={4} >
                    <ReactMultiSelectCheckboxes
                        name="estados-filter"
                        options={[{ label: "Seleccionar todos", value: "*" }, ...arrayEstates]}
                        placeholderButtonLabel="Estados..."
                        getDropdownButtonLabel={getDropdownButton}
                        value={listEstates}
                        onChange={onChange}
                        setState={setlistEstates}
                    />
                </Grid>
                <Grid item xs={2} sm={1} md={1} style={{ textAlign: 'center' }}>
                    <IconButton
                        size="large"
                        color="Primary"
                        onClick={(e) => { reloadTable() }}
                    >
                        <RotateLeftIcon style={{ fontSize: 30 }} />
                    </IconButton>
                </Grid>


                <Grid item xs={5} sm={6} md={6} >
                    <TextField
                        placeholder='Buscar'
                        variant="outlined"
                        inputProps={{ className: styleSearch }}
                        fullWidth
                        value={filter}
                        onChange={((e) => { setFilter(e.target.value) })}
                    />
                </Grid>

                <Grid item xs={1} style={{ textAlign: 'center' }}>
                    <IconButton
                        size="large"
                        color="Primary"
                        onClick={(e) => { filtrar() }}
                    >
                        <SearchIcon style={{ fontSize: 30 }} />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Box
                            m={2}
                            component="span"
                            className={`${classes.spreadBox} ${classes.box}`}
                        >
                            <Title  style={{color:'#2BAEB3'}}>Mis Clientes</Title>
                        </Box>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table size="small" >
                                <TableHead>
                                    <TableRow alignItems="center">
                                        <TableCell align="center">Fecha Solicitud</TableCell>
                                        <TableCell align="center">Cedula</TableCell>
                                        <TableCell align="center">Nombre</TableCell>
                                        <TableCell align="center" >Ver datos Contacto</TableCell>
                                        <TableCell align="center">Cuota Inicial</TableCell>
                                        <TableCell align="center">Valor Producto</TableCell>
                                        <TableCell align="center">Estado Solicitud</TableCell>
                                        <TableCell align="center">Código Aprobación</TableCell>
                                        <TableCell align="center">Vendedor</TableCell>
                                        <TableCell align="center">Sucursal</TableCell>
                                        <TableCell align="center">Negociación</TableCell>
                                        <TableCell align="center">Aprobación</TableCell>
                                        {rol !== "ROLE_SELLER" && <TableCell align="center">Reasignar</TableCell>}
                                        {rol === "ROLE_SELLER" && <TableCell align="center">Reasignar</TableCell>}
                                        
                                        
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoadUser ?
                                        <>
                                            <Grid
                                                container
                                                spacing={1}
                                                direction="row"
                                                justify="center"
                                                alignItems="center"
                                                alignContent="center"
                                                wrap="nowrap"
                                            >
                                                <ReactLoading
                                                    type={"bars"}
                                                    color={"#000"}
                                                    height={50}
                                                    width={50}
                                                />
                                            </Grid>
                                        </>
                                        : data != null && data != undefined && data.map((row, key) => (
                                            <TableRow key={key}>
                                                <TableCell align="center">{`${formatData(row.created)}`}</TableCell>
                                                <TableCell align="center">{row.datosPersona.documentNumber}</TableCell>
                                                <TableCell align="center">{row.datosPersona.nombrecompleto}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        style={{color:'#2BAEB3'}}
                                                        aria-label="upload picture"
                                                        component="span"
                                                        onClick={(e) => { modalContac(data[key]) }}
                                                    >
                                                        <Visibility />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell align="center">{
                                                    row.abonoFinal === undefined || row.abonoFinal === null ?
                                                        `${formatMoney(row.abono)}` : `${formatMoney(row.abonoFinal)}`

                                                }</TableCell>
                                                <TableCell align="center">{
                                                    row.montoAprobado === undefined || row.montoAprobado === null ?
                                                        row.valormontoaprobado === undefined || row.valormontoaprobado === null ?
                                                            `${formatMoney(row.valormontorequerido)}`
                                                            : `${formatMoney(row.valormontoaprobado)}`
                                                        : `${formatMoney(row.montoAprobado)}`
                                                }</TableCell>
                                                <TableCell align="center">{row.estadoSolicitud.estado}{row.subEstado && `/${row.subEstado.subestado}`}</TableCell>
                                                <TableCell align="center">{row.approvalCode === undefined ? "----" : row.approvalCode}</TableCell>
                                                <TableCell align="center">{row.vendedor === undefined ? "----" : row.vendedor.nombreCompleto}</TableCell>
                                                <TableCell align="center">{row.sucursal === undefined ? "----" : row.sucursal.nombre}</TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        disabled={validateDisable(row)}
                                                        onClick={(e) => {
                                                            setDataModalCalc(row)
                                                        }}
                                                    >
                                                        Negociación
                                                    </Button>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        disabled={row.estadoSolicitud.id != 10 ? true : false}
                                                        onClick={(e) => {
                                                            handleRowClick(row);
                                                        }}
                                                    >
                                                        Aprobación
                                                    </Button>
                                                </TableCell>
                                                {rol !== "ROLE_SELLER" && <TableCell align="center">
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        // disabled={row.estadoSolicitud.id != 10 ? true : false}
                                                        onClick={(e) => {
                                                            handledModalAsing(row);
                                                        }}
                                                    >
                                                        Reasignar
                                                    </Button>
                                                </TableCell>}
                                                {rol === "ROLE_SELLER" && <TableCell align="center">
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        // disabled={row.estadoSolicitud.id != 10 ? true : false}
                                                        onClick={(e) => {
                                                            handledModalAsingSeller(row);
                                                        }}
                                                    >
                                                        Reasignar
                                                    </Button>
                                                </TableCell>}


                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                        >
                            <Paginator
                                totalElement={Math.round(totalElements / size)}
                                setPage={setPage}
                                page={page}

                            />
                        </Grid>
                        <br />

                    </Paper>
                </Grid>
            </Grid>
        </>


    )



}
