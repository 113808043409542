//React
import React from 'react';

//Componet Material
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

//My style
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

export default function Paginator ({totalElement, setPage, page}) {

    const classes = useStyles()
    
    const nextPageFle = (e,p) =>{
        setPage(p-1);
    }

    return(

        <div className={classes.root}>
        <Pagination 
            count={totalElement} 
            size="large"
            page={page+1}
            variant="outlined"
            color="primary"
            shape="rounded"
            onChange={nextPageFle}
            />
        </div>
    )


}