import React from 'react';

import { Link } from 'react-router-dom';
import { makeStyles, Breadcrumbs, Typography } from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';
import GrainIcon from '@material-ui/icons/Grain';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        color: theme.color
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    depositContext: {
        flex: 1,
    },
}));

export default function MigaPan({namePage, to, children, to2, children2, to3, children3}) {

    const classes = useStyles();

    return (
        <Breadcrumbs aria-label="breadcrumb" color="inherit">
            <Link color="inherit" to={to} className={classes.link}>
                <HomeIcon className={classes.icon} />
                {children}
            </Link>

            {children2 != null && <Link color="inherit" to={to2} className={classes.link}>
                {children2}
            </Link>}
            
            <Typography color="textPrimary" className={classes.link}>
                <GrainIcon className={classes.icon} />
                {namePage}
            </Typography>

            {children3 != null && <Link color="inherit" to={to3} className={classes.link}>
                {children3}
            </Link>}
          


            

        </Breadcrumbs>


    );

}
