//React
import { createRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

//Redux
import { useDispatch } from 'react-redux';
import { postLoginPhone } from '../../domain/userPhoneSlice';
import { ERROR_SERVICE } from "../../../app/api-client/constants"

//Icon material
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

//Componet Material
import { Alert } from '@material-ui/lab';
import {
    Paper,
    Typography,
    Grid,
    TextField,
    Button,
    Container,
    CircularProgress,
    Link,

} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';


//Local Componet
import clsx from 'clsx';
import logo from '../img/logo.png';
import { useStyles } from './SinginStyles';
import { HelpValidate } from '../../utils/helpValidate';
import { Copyright } from '../../../dashboard/componet/UI/CopyRight'
import MigaPan from '../../../app/orussystem/component/MigaPan';


export default function SiginCode({ cellphone, singin, nextStep, isContinue, backStep }) {


    const classes = useStyles();
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const borderRadius = clsx(classes.padding, classes.borderRadius);
    const paddingInput = clsx(classes.InputText);

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [errorIsLoading, seterrorIsLoading] = useState(false)
    const error = localStorage.getItem(ERROR_SERVICE);


    const c1 = createRef();
    const c2 = createRef();
    const c3 = createRef();
    const c4 = createRef();
    const c5 = createRef();
    const c6 = createRef();
    const Btncontinue = createRef();



    const sentRequest = (code) => {
        return new Promise((resolve, reject) => {
            resolve(dispatch(postLoginPhone({
                usernameOrEmail: `SELLER-${cellphone}`,
                password: code
            })));
        })
    }

    const onSubmit = (data) => {


        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 2000);

        let code = data.c1 + data.c2 + data.c3 + data.c4 + data.c5 + data.c6;

        sentRequest(code).then((respon) => {

            if (error != "false") {
                seterrorIsLoading(true)
                setTimeout(() => {
                    seterrorIsLoading(false)
                    localStorage.setItem(ERROR_SERVICE, "false")
                }, 3000);
            }
        })

    }


    const focusInput = (ref) => {
        if (ref === 'c1') {
            c1.current.focus()
        } else if (ref === 'c2') {
            c2.current.focus()
        }
        else if (ref === 'c3') {
            c3.current.focus()
        }
        else if (ref === 'c4') {
            c4.current.focus()
        }
        else if (ref === 'c5') {
            c5.current.focus()
        }
        else if (ref === 'c6') {
            c6.current.focus()
        }
        else if (ref === 'btn') {
            Btncontinue.current.focus()
        }

    }

    const resetInput = () => {

        c1.current.value = "";
        c2.current.value = "";
        c3.current.value = "";
        c4.current.value = "";
        c5.current.value = "";
        c6.current.value = "";
    }

    const HandledBack = () => {
        backStep();
    }

    useEffect(() => {
        focusInput('c1')
    }, [])


    return (


        <div className={classes.centerv} >
            <Container maxWidth="xs" alignitems="center" textAlign='center !important' >
                <Paper className={borderRadius}>
                <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="left"
                        alignItems="left"
                        alignContent="left"
                        wrap="nowrap"

                    >
               <IconButton onClick={HandledBack}>
                            <ArrowBackIcon />
                        </IconButton>

                    </Grid>
                    <div className={classes.margin}>
                        <Grid container justify="center" style={{ marginTop: '15px', maxwidth: '350px' }}>
                            <img src={logo} width="100%" alt="Logo" />
                        </Grid>
                        <Grid
                        style={{ marginTop: '15px'}}
                            container
                            justify="center"
                            alignItems="flex-start"
                            alignContent="stretch"
                            wrap="nowrap"

                        >
                            <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                            Ingresa tu código de acceso OTP
                            </Typography>
                        </Grid>


                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justify="center"
                                alignItems="center"
                                alignContent="center"
                                wrap="nowrap"
                                style={{ marginLeft: '-4px', marginTop: '20px' }}
                            >


                                <Grid
                                    item xs={2}
                                    style={{ border: '1px solid #b2dcf4' }}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="nowrap"

                                >
                                    
                                    <TextField
                                        // placeholder="1"
                                        // label="1"
                                        fullWidth
                                        name="c1"
                                        type="tel"
                                        inputProps={{ className: paddingInput, ref: c1 }}
                                        {...HelpValidate().limintCodeNumber(register('c1', {
                                            required: "Por favor ingrese su número de celular.",
                                            minLength: {
                                                value: 1,
                                                message: 'El Celular debe tener minimo 10 caracteres'
                                            },
                                        }), 1, 1, setError, clearErrors, c1, 'c2', focusInput)}
                                        error={!!errors.email}
                                        helperText={errors.email?.message}

                                    />
                                </Grid>
                                &nbsp;
                                <Grid item xs={2} style={{ border: '1px solid #b2dcf4' }}>
                                    <TextField
                                        // placeholder="2"
                                        // label="2"
                                        fullWidth
                                        name="c2"
                                        type="tel"
                                        inputProps={{ className: paddingInput, ref: c2 }}
                                        {...HelpValidate().limintCodeNumber(register('c2', {
                                            required: "Por favor ingrese su número de celular.",
                                            minLength: {
                                                value: 1,
                                                message: 'El Celular debe tener minimo 10 caracteres'
                                            },
                                        }), 1, 1, setError, clearErrors, c2, 'c3', focusInput)}
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                    />
                                </Grid>
                                &nbsp;
                                <Grid item xs={2} style={{ border: '1px solid #b2dcf4' }} >
                                    <TextField
                                        // placeholder="3"
                                        // label="3"
                                        fullWidth
                                        name="c3"
                                        type="tel"
                                        inputProps={{ className: paddingInput, ref: c3 }}
                                        {...HelpValidate().limintCodeNumber(register('c3', {
                                            required: "Por favor ingrese su número de celular.",
                                            minLength: {
                                                value: 1,
                                                message: 'El Celular debe tener minimo 10 caracteres'
                                            },
                                        }), 1, 1, setError, clearErrors, c3, 'c4', focusInput)}
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                    />
                                </Grid>
                                &nbsp;
                                <Grid item xs={2} style={{ border: '1px solid #b2dcf4' }} >
                                    <TextField
                                        // placeholder="4"
                                        // label="4"
                                        fullWidth
                                        name="c4"
                                        type="tel"
                                        inputProps={{ className: paddingInput, ref: c4 }}
                                        {...HelpValidate().limintCodeNumber(register('c4', {
                                            required: "Por favor ingrese su número de celular.",
                                            minLength: {
                                                value: 1,
                                                message: 'El Celular debe tener minimo 10 caracteres'
                                            },
                                        }), 1, 1, setError, clearErrors, c4, 'c5', focusInput)}
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                    />
                                </Grid>
                                &nbsp;
                                <Grid item xs={2} style={{ border: '1px solid #b2dcf4' }} >
                                    <TextField
                                        // placeholder="5"
                                        // label="5"
                                        fullWidth
                                        name="c5"
                                        type="tel"
                                        inputProps={{ className: paddingInput, ref: c5 }}
                                        {...HelpValidate().limintCodeNumber(register('c5', {
                                            required: "Por favor ingrese su número de celular.",
                                            minLength: {
                                                value: 1,
                                                message: 'El Celular debe tener minimo 10 caracteres'
                                            },
                                        }), 1, 1, setError, clearErrors, c5, 'c6', focusInput)}
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                    />
                                </Grid>
                                
                                &nbsp;
                                <Grid item xs={2} style={{ border: '1px solid #b2dcf4' }} >
                                    <TextField
                                        // placeholder="6"
                                        // label="6"
                                        fullWidth
                                        name="c6"
                                        type="tel"
                                        inputProps={{ className: paddingInput, ref: c6 }}
                                        {...HelpValidate().limintCodeNumber(register('c6', {
                                            required: "Por favor ingrese su número de celular.",
                                            minLength: {
                                                value: 1,
                                                message: 'El Celular debe tener minimo 10 caracteres'
                                            },
                                        }), 1, 1, setError, clearErrors, c6, 'btn', focusInput)}
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                    />
                                </Grid>

                            </Grid>
                            
                            {!isContinue &&
                                <Grid container alignItems="flex-end" style={{ marginTop: '20px' }}>
                                    <Typography variant="body2" color="#0a699e" align='center'>
                                        Un mensaje con un código de verificación fue
                                        enviado a <b>{cellphone}</b> ingrese el código para
                                        continuar.
                                    </Typography>
                                </Grid>

                            }

                            <Grid container alignItems="flex-end" style={{ marginTop: '20px', marginLeft: '13%'}}>
                                <Typography variant="body2" color="#0a699e" align='center'>
                                    <Link onClick={((e) => { nextStep() })} target="_blank"   style={{textDecoration: 'underline'}}>
                                        Si no cuentas con un código, has click aquí.
                                    </Link>
                                </Typography>
                            </Grid>

                            <div className={classes.wrapper}>
                                <Grid container justify="center" style={{ marginTop: '20px' }}>
                                    <Button
                                        disabled={isLoading}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{ textTransform: "none", width: "inherit" }}
                                        ref={Btncontinue}
                                    >
                                        Continuar
                                    </Button>
                                </Grid>
                                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>

                            <Grid container alignItems="flex-end" style={{ marginTop: '20px', marginLeft: '36%', marginBottom:'10px' }}>
                                <Typography variant="body2" color="#0a699e" align='center'>
                                    <Link onClick={((e) => { resetInput() })} target="_blank" underline="hover">
                                        Limpiar código
                                    </Link>
                                </Typography>
                            </Grid>
                            {errorIsLoading && <Alert severity="error">El código no coincide o a expirado, Prueba enviando otro código</Alert>}

                            <Grid container justify="space-between" alignItems="flex-end" style={{ marginTop: '10px' }}>
                                <Grid item >

                                </Grid>
                            </Grid>
                            <br />
                        </form>
                    </div>
                    <Box >
                        <Copyright />
                    </Box>
                </Paper>
            </Container >
        </div>

    );

}
