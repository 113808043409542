import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    ACCESS_TOKEN,
    ID_USER,
    ID_SUCURSAL,
    ID_COMERCIO,
    ERROR_SERVICE,
    USER_ROL,
    ERROR_LOGIN,
    ISADMIN_USER,
    NAME_COMERCIO,
    NAME_SUCURSAL,
    CHANGE_PASS,
    URL_COMERCIO,
    EMAIL_CHANGE,
    USER_AUTH0,
    ID_SELLER,
    USER_LOGIN,
    SUCURSAL_LOGIN,
    RECAUDO,
} from "../../app/api-client/constants";
import { request } from "../../app/api-client/ApiClient";



export const postLoginPhone = createAsyncThunk(
    'userPhone/login',
    async (login) => {

        return await request({
            url: `${process.env.REACT_APP_API_URL}/api/auth/client/login`,
            method: "POST",
            body: JSON.stringify(login),
        });


    }
)


export const postLogin = createAsyncThunk(
    'userPhone/login',
    async (login) => {

        return await request({
            url: `${process.env.REACT_APP_API_URL}/api/auth/signin`,
            method: "POST",
            body: JSON.stringify(login),
        });


    }
)


const userPhoneSlice = createSlice({
    name: "userPhone",
    initialState: {
        user: null,
        loginToken: {},
        process: null,
        isLoading: false,
        payload: { error: false },
        id: "",
    },
    reducers: {
        loginBytoken: (state) => {
            let token = localStorage.getItem(ACCESS_TOKEN)
            state.user = { accessToken: token }
        },
        logout: (state) => {
            state.user = null;
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(ID_USER);
            localStorage.removeItem(ID_SELLER)
            localStorage.removeItem(ID_SUCURSAL);
            localStorage.removeItem(ID_COMERCIO);
            localStorage.removeItem(ERROR_SERVICE);
            localStorage.removeItem(USER_ROL);
            localStorage.removeItem(NAME_COMERCIO);
            localStorage.removeItem(NAME_SUCURSAL);
            localStorage.removeItem(URL_COMERCIO);
            localStorage.removeItem(USER_LOGIN);
            localStorage.removeItem(SUCURSAL_LOGIN);
            localStorage.removeItem(USER_AUTH0);
            localStorage.removeItem(ERROR_LOGIN);
            localStorage.removeItem(ISADMIN_USER);
            localStorage.removeItem(CHANGE_PASS);
            localStorage.removeItem(EMAIL_CHANGE);
            localStorage.removeItem(RECAUDO);           
            localStorage.clear();
            
        },
    },
    extraReducers: {
        [postLoginPhone.pending]: (state, action) => {
            state.process = 'loading'
            state.isLoading = true
            state.payload = { error: false }
        },
        [postLoginPhone.fulfilled]: (state, { payload }) => {
            state.payload = payload
            state.loginToken = !payload.error ? payload : {}
            state.process = 'success'
            state.isLoading = false

            state.loginToken.accessToken && localStorage.setItem(ACCESS_TOKEN, payload.accessToken);
            state.loginToken.userPrincipal.authorities && localStorage.setItem(USER_ROL, payload.userPrincipal.authorities[0].authority);
        },
        [postLoginPhone.rejected]: (state, action) => {
            state.process = 'failed'
            state.isLoading = false
            state.payload = { error: true }

        },




        [postLogin.pending]: (state, action) => {
            state.process = 'loading'
            state.isLoading = true
            state.payload = { error: false }
        },
        [postLogin.fulfilled]: (state, { payload }) => {

        
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(ID_USER);
            localStorage.removeItem(ID_SUCURSAL);
            localStorage.removeItem(ID_COMERCIO);
            localStorage.removeItem(ERROR_SERVICE);
            localStorage.removeItem(USER_ROL);
            localStorage.removeItem(CHANGE_PASS);
            localStorage.removeItem(ERROR_LOGIN)
            localStorage.removeItem(ISADMIN_USER)
            localStorage.removeItem(ID_SELLER)
            localStorage.removeItem(USER_LOGIN);
            localStorage.removeItem(SUCURSAL_LOGIN);
            localStorage.removeItem(RECAUDO);

            state.payload = payload
            state.loginToken = !payload.error ? payload : {}
            state.process = 'success'
            state.isLoading = false

            payload.userPrincipal.authorities[0].authority === "ROLE_ADMIN"
                ? localStorage.setItem(ISADMIN_USER, "isAdmin")
                : localStorage.setItem(ISADMIN_USER, "noAdmin")

            state.loginToken.accessToken && localStorage.setItem(ACCESS_TOKEN, payload.accessToken);
            state.loginToken.userPrincipal.authorities && localStorage.setItem(USER_ROL, payload.userPrincipal.authorities[0].authority);

            payload.sucursale != null && localStorage.setItem(ID_SUCURSAL, payload.sucursale.id);
            payload.sucursale != null && localStorage.setItem(ID_COMERCIO, payload.sucursale.comercioBean.id);

            payload.sucursale != null && localStorage.setItem(NAME_COMERCIO, payload.sucursale.comercioBean.nombre);
            payload.sucursale != null && localStorage.setItem(URL_COMERCIO, payload.sucursale.comercioBean.url);
            payload.sucursale != null && localStorage.setItem(NAME_SUCURSAL, payload.sucursale.nombre);
            payload.userPrincipal != null && localStorage.setItem(ID_USER, payload.userPrincipal.id);
       

            payload.userPrincipal.authorities[0].authority === "ROLE_SELLER"
                ? localStorage.setItem(CHANGE_PASS, "noChange")
                : payload.userPrincipal != null
                    ? payload.userPrincipal.changepassword
                        ? localStorage.setItem(CHANGE_PASS, "isChange")
                        : localStorage.setItem(CHANGE_PASS, "noChange")
                    : localStorage.setItem(CHANGE_PASS, "noChange")

            state.user = state.loginToken.userPrincipal && state.loginToken.userPrincipal
        },
        [postLogin.rejected]: (state, action) => {
            state.process = 'failed'
            state.isLoading = false
            state.payload = { error: true }
            localStorage.setItem(ERROR_LOGIN, "error");

        },
    },

});

export const reloadPage = (isTrue) => {
    if (isTrue) {
        isTrue = false
        window.location.href = "/"
        return
    }
    return
}

export const { logout, loginBytoken } = userPhoneSlice.actions;

export const selectUserPhone = (state) => state.userPhone.user;

// export const selectServiceErr = (state) => state.userPhone.isLoading;

export const selectLoginApiStatus = (state) => state.user.process

export const selectuserPhonePayload = (state) => state.userPhone.payload

export const selectLoadingPhone = (state) => state.userPhone.isLoading

export default userPhoneSlice.reducer;
