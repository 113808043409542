
//React
import React from 'react';

//Icons Material
import {
  Clear,
} from '@material-ui/icons';

//Components Material
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Modal,
  IconButton,
  Backdrop,
  Fade,
  Badge,
} from '@material-ui/core';


//My style
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #ff670080',
    width: 400,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 4, 3),
  },
}));

export  default function ModalGeneric({isOpen, setopenMondal, message}) {

  const classes = useStyles();
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={(e)=>{ 
          setopenMondal(false)
        }}
        className={classes.modal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="nowrap"
                
              >
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    alignContent="stretch"
                    wrap="nowrap"
                    
                  >
                    <Grid xs={4}></Grid>
                    <Grid 
                      item xs={6}
                      direction="row"
                      justify="center"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"

                      >
                    <h1 id="transition-modal-title">Alerta</h1>
                    </Grid>
                    <Grid item xs ={1} textAlign="Right">
                      <IconButton 
                        color="error" 
                        aria-label="upload picture"
                        component="span"
                        onClick={((e)=>{
                          setopenMondal(false);
                        })}
                        >
                        <Clear/>
                      </IconButton>
                    </Grid>
                </Grid>
              </Grid>
              <br/>
            <Grid container spacing={1} alignItems="flex-end" textAlign="center">
                <Grid item md={true} sm={true} xs={true} style={{ borderTop: '1px solid  #000000', borderBottom: '1px solid  #000000'}}>
                    <Typography 
                        id="keep-mounted-modal-title"
                        variant="h6" 
                        component="h2"
                        fullWidth
                        >
                        {message}   
                    </Typography>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={1} alignItems="flex-end" textAlign="center">
                <Grid item xs={4}></Grid>
                <Grid item xs={6}>
                  <Badge color="secondary" variant="dot">
                      <Typography 
                          id="keep-mounted-modal-title"
                          variant="body1" 
                          component="h2"
                          fullWidth
                          >
                          PrestáPolis
                      </Typography>
                    </Badge>
                </Grid>
            </Grid>
              <br/>
              {/* {errorFile && <Alert severity="error">{messageErro}</Alert>}
              {succesFile && <Alert severity="success">¡Se te asigno la solicitud correctamente!</Alert>} */}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}