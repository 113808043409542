import React, { Children, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { reloadPage } from '../../../user/domain/userPhoneSlice';

import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';

import { updateToken } from '../../../prestapolis/domain/api/PrestapolisApi'
import { ACCESS_TOKEN } from "../../../app/api-client/constants"

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { DashboardStyle } from './DashboardStyle';
import { useDispatch } from 'react-redux';

import Header from '../AppBarOrs/Header';
import { Copyright } from '../UI/CopyRight';
import { logout } from '../../../user/domain/userPhoneSlice';

import { BrowserRouter as Router, Switch } from 'react-router-dom';
import withAuthentication from '../../../user/componet/session/withAuthentication';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
import { Button } from '@material-ui/core';



function Dashboard({ children, menu }) {

  const dispatch = useDispatch();
  const classes = DashboardStyle();
  const history = useHistory();
  const click = useRef();

  let tiempoInactividad = 0;
  //Servidor 1 min = 60000
  const MINUTE_MS = process.env.REACT_APP_SESSION_TIME;
  const RELOAND_TOKEN = (MINUTE_MS / 10) * 2;

  //Servicio actualizar token
  const updateTokenbyToken = (dis, oldToken) => {
    updateToken(dis, oldToken)
      .then((response) => {
        localStorage.setItem(ACCESS_TOKEN, response.message);
      })
      .catch((err) => {
      })
  }

  const LogoutDash = () => {
    dispatch(logout())
    reloadPage(true);
    
  }


  useEffect(async () => {

    window.addEventListener("mousemove", (e) => {
      tiempoInactividad = 0;
    });

    const interIsActive = setInterval(() => {
      tiempoInactividad += 1;
  
    }, 1000);



    setTimeout(async function run() {

      if (tiempoInactividad <= MINUTE_MS / 1000) {

        let oldToken = localStorage.getItem(ACCESS_TOKEN)
        const request = {
          token: oldToken
        }
        await updateTokenbyToken(dispatch, request);
        setTimeout(run, RELOAND_TOKEN);
      } else {
        // dispatch(logout());
        LogoutDash()
      }
    }, MINUTE_MS / 10);


    return () => {
      //clearInterval(interval)
      clearInterval(interIsActive)
    }; // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])



  return (
    <Router>
      <div className={classes.root}>
        <ReactNotification />
        <CssBaseline />
        <Header title={process.env.REACT_APP_TITLE} _menus={menu} />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Switch >
                <>
                  {children}
                </>
              </Switch>
            </Grid>
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>

    </Router>
  );
}

export default withAuthentication(Dashboard);
