import React, { createRef, useEffect, useState } from 'react';
import { USER_ROL, RECAUDO } from '../../app/api-client/constants';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import ReactLoading from "react-loading";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
//local componente
import ModalRegistroPago from '../modals/modalRegistroPago';
import {
    Grid,
} from '@material-ui/core';


import Typography from '@material-ui/core/Typography';
import solicitudImg from '../img/solicitudImg.png'
import misSolicitudes from '../img/misSolicitudes.png'
import mediosPagos from '../img/mediosPagos.png'
import recaudos from '../img/recaudos.png'
import vendedores from '../img/vendedores.png'
import sucursal from '../img/sucursalesPrestapolis.png'
import admin from '../img/adminPrestapolis.svg'
import ventas from '../img/ventasPrestapolis.png'
import calculadora from '../img/calculadora.svg'
import SimpleCard from "../card/card"
import pre_solicitud from "../img/pre_solicitud.png"


const useStyles = makeStyles((theme) => ({
 
    media: {
        width: 150,
        height: 150,
        padding: 4,
        marginTop:'1%',
        marginLeft:'30%',
        textAlign: 'center'
       //transform: scale(2, 0.5),
    }

}));

export const Home = () => {
    const classes = useStyles();
    let REC = localStorage.getItem(RECAUDO)
    const [rec, setRecaudo] = useState(REC)

    useEffect(() => {

        if (localStorage.getItem(RECAUDO) === null) {
            setTimeout(() => {
                setRecaudo(REC)
                window.location.reload();
            }, 500);
        } else {
            setRecaudo(REC)
        }
    }, [REC])
   

    //let recaudo = true;

    const dispatch = useDispatch();
    const history = useHistory();
    
  
    const handledCalculator = () => {
        history.push(`/calculadora`);

    }

    const handledSolicitudes = () => {
        history.push(`/solicitudes`);

    }


    const handledMisSolicitudes = () => {
        history.push(`/miSolicitud/`);

    }

    const handledMediosPagos = () => {
        history.push(`/mediosPagos/`);

    }

    const handledRecaudos = () => {
        history.push(`/recaudos/`);

    }


    const handledVendedores = () => {
        history.push(`/vendedores/:id`);

    }

    const handledVendedoresComercios = () => {
        history.push(`/listvendedores-sucursal/`);

    }

    const handledSucursales = () => {
        history.push(`/sucursales/`);

    }

    const handledAdmin = () => {
        history.push(`/admin-prestapolis/`);

    }

    const handleVentas = () => {
        history.push(`/solicitudesCommerce/`);

    }

    const handlePreSolicitud = () => {
        history.push(`/Pre-solicitudes`);

    }

    let ROL = localStorage.getItem(USER_ROL)
   

    if (rec === 'true'){
        return (
            <>
                <Grid container spacing={1} style={{ marginBottom: '35%', marginTop: '2%', marginLeft: 0, marginRight: 0 }}>    
                    {ROL === "ROLE_BRANCH_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12} >
                            <Card className={classes.root} >
                            <CardActionArea
                                onClick={handledSolicitudes}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={pre_solicitud}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                    Solicitudes
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Listado de solicitudes originadas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                           
                             
                        </Grid>
                    }
                    {ROL === "ROLE_BRANCH_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12} >
                            <Card className={classes.root} >
                            <CardActionArea
                                onClick={handlePreSolicitud}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={solicitudImg}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                    Pre Solicitudes
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Listado de solicitudes pre-aprobadas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                    }
                    {ROL === "ROLE_BRANCH_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12}>
                            <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledMisSolicitudes}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={misSolicitudes}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Mis Clientes
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Clientes asignados por vendedor
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
    
                    }
                    {ROL === "ROLE_BRANCH_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12}>
                                 <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledVendedoresComercios}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={vendedores}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Usuarios
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Lista de Usuarios
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    }
                    {ROL === "ROLE_SELLER" &&
                        <Grid item sm={3} md={3} xs={12} >
                            <Card className={classes.root} >
                            <CardActionArea
                                onClick={handledSolicitudes}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={pre_solicitud}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                    Solicitudes
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Listado de solicitudes originadas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                    }
                    {ROL === "ROLE_SELLER" &&
                        <Grid item sm={3} md={3} xs={12} >
                            <Card className={classes.root} >
                            <CardActionArea
                                onClick={handlePreSolicitud}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={solicitudImg}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                    Pre Solicitudes
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Listado de solicitudes pre-aprobadas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                    }
                    {ROL === "ROLE_SELLER" &&
                        <Grid item sm={3} md={3} xs={12}>
                            <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledMisSolicitudes}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={misSolicitudes}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Mis Clientes
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Clientes asignados por vendedor
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
    
                    }
                    {ROL === "ROLE_COMMERCE_ADMIN" && <Grid item sm={3} md={3} xs={12}>
                        <Card className={classes.root} >
                            <CardActionArea
                                onClick={handleVentas}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={ventas}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                        Ventas
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Listas de ventas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                     
                    </Grid>
                    }
                    {ROL === "ROLE_COMMERCE_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12} >
                            <Card className={classes.root} >
                            <CardActionArea
                                onClick={handledSolicitudes}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={pre_solicitud}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                    Solicitudes
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Listado de solicitudes originadas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                    }
                    {ROL === "ROLE_COMMERCE_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12} >
                            <Card className={classes.root} >
                            <CardActionArea
                                onClick={handlePreSolicitud}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={solicitudImg}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                    Pre Solicitudes
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Listado de solicitudes pre-aprobadas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                         
                        </Grid>
                    }
    
                    {ROL === "ROLE_COMMERCE_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12}>

                            <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledMisSolicitudes}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={misSolicitudes}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Mis Clientes
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Clientes asignados por vendedor
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                           
                        </Grid>
    
                    }
                    {ROL === "ROLE_COMMERCE_ADMIN" && <Grid item sm={3} md={3} xs={12}>

                    <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledSucursales}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={sucursal}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Sucursales
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Lista de sucursales
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                    </Grid>
                    }
                    {ROL === "ROLE_COMMERCE_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12}>

                            <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledVendedoresComercios}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={vendedores}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Usuarios
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Lista de Usuarios
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    }
                    {ROL === "ROLE_ADMIN" && <Grid item sm={3} md={3} xs={12}>
                        <SimpleCard
                            title="Administrador"
                            messageInfo="Lista de Administradores"
                            img={admin}
                            event={handledAdmin}
                        />
                    </Grid>}
                    {ROL != "ROLE_ADMIN" && <Grid item sm={3} md={3} xs={12}>

                    <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledMediosPagos}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={mediosPagos}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Medios de pago
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Medios de pagos disponibles
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                       
                    </Grid>}
                    
                    {ROL != "ROLE_ADMIN" && <Grid item sm={3} md={3} xs={12}>
                    <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledRecaudos}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={recaudos}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Recaudos
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Registro de pagos cliente
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        
                    </Grid>}
    
    
    
                    {/* {ROL != "ROLE_ADMIN" && <Grid item sm={3} md={3} xs={12}>
                        <SimpleCard
                            title="Calculadora"
                            messageInfo="Simula tu crédito"
                            img={calculadora}
                            event={handledCalculator}
                        />
                    </Grid>} */}
    
                </Grid>
    
            </>
        );


    }else{


        return (
      
            <>
    
                <Grid container spacing={1} style={{ marginBottom: '35%', marginTop: '2%', marginLeft: 0, marginRight: 0 }}>
    
    
                    {ROL === "ROLE_BRANCH_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12} >
                            <Card className={classes.root} >
                            <CardActionArea
                                onClick={handledSolicitudes}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={pre_solicitud}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                    Solicitudes
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Listado de solicitudes originadas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                    }
                    {ROL === "ROLE_BRANCH_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12} >
                           <Card className={classes.root} >
                            <CardActionArea
                                onClick={handlePreSolicitud}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={solicitudImg}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                    Pre Solicitudes
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Listado de solicitudes pre-aprobadas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                    }
                    {ROL === "ROLE_BRANCH_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12}>
                          <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledMisSolicitudes}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={misSolicitudes}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Mis Clientes
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Clientes asignados por vendedor
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
    
                    }
                    {ROL === "ROLE_BRANCH_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12}>
                           <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledVendedoresComercios}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={vendedores}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Usuarios
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Lista de Usuarios
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    }
                    {ROL === "ROLE_SELLER" &&
                        <Grid item sm={3} md={3} xs={12} >
                            <Card className={classes.root} >
                            <CardActionArea
                                onClick={handledSolicitudes}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={pre_solicitud}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                    Solicitudes
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Listado de solicitudes originadas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                    }
                    {ROL === "ROLE_SELLER" &&
                        <Grid item sm={3} md={3} xs={12} >
                           <Card className={classes.root} >
                            <CardActionArea
                                onClick={handlePreSolicitud}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={solicitudImg}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                    Pre Solicitudes
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Listado de solicitudes pre-aprobadas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                    }
                    {ROL === "ROLE_SELLER" &&
                        <Grid item sm={3} md={3} xs={12}>
                           <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledMisSolicitudes}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={misSolicitudes}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Mis Clientes
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Clientes asignados por vendedor
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
    
                    }
                    {ROL === "ROLE_COMMERCE_ADMIN" && <Grid item sm={3} md={3} xs={12}>
                    <Card className={classes.root} >
                            <CardActionArea
                                onClick={handleVentas}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={ventas}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                        Ventas
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Listas de ventas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    }
                    {ROL === "ROLE_COMMERCE_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12} >
                            <Card className={classes.root} >
                            <CardActionArea
                                onClick={handledSolicitudes}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={pre_solicitud}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                    Solicitudes
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Listado de solicitudes originadas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                    }
                    {ROL === "ROLE_COMMERCE_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12} >
                            <Card className={classes.root} >
                            <CardActionArea
                                onClick={handlePreSolicitud}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={solicitudImg}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography style={{ color:"#2BAEB3", fontSize:"25px"}} align='center'>
                                    Pre Solicitudes
                                    </Typography>
                                    <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Listado de solicitudes pre-aprobadas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                    }
    
                    {ROL === "ROLE_COMMERCE_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12}>
                           <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledMisSolicitudes}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={misSolicitudes}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Mis Clientes
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Clientes asignados por vendedor
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
    
                    }
                    {ROL === "ROLE_COMMERCE_ADMIN" && <Grid item sm={3} md={3} xs={12}>
                    <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledSucursales}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={sucursal}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Sucursales
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Lista de sucursales
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                    </Grid>
                    }
                    {ROL === "ROLE_COMMERCE_ADMIN" &&
                        <Grid item sm={3} md={3} xs={12}>
                           <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledVendedoresComercios}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={vendedores}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Usuarios
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Lista de Usuarios
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    }
                    {ROL === "ROLE_ADMIN" && <Grid item sm={3} md={3} xs={12}>
                        <SimpleCard
                            title="Administrador"
                            messageInfo="Lista de Administradores"
                            img={admin}
                            event={handledAdmin}
                        />
                    </Grid>}
                    {ROL != "ROLE_ADMIN" && <Grid item sm={3} md={3} xs={12}>
                    <Card className={classes.root} >
                                <CardActionArea
                                    onClick={handledMediosPagos}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={mediosPagos}
                                    >
                                    </CardMedia>
                                    <CardContent>
                                        <Typography style={{ color:"#2BAEB3", fontSize: "25px" }} align='center'>
                                        Medios de pago
                                        </Typography>
                                        <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                        Medios de pagos disponibles
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                    </Grid>}
                    
                    {/* {ROL != "ROLE_ADMIN" && <Grid item sm={3} md={3} xs={12}>
                        <SimpleCard
                            title="Calculadora"
                            messageInfo="Simula tu crédito"
                            img={calculadora}
                            event={handledCalculator}
                        />
                    </Grid>} */}
    
                </Grid>
    
            </>
        );
    }
   
}
