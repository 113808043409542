import React, { useState, useMemo, forwardRef } from 'react';
import { SubMenuItem } from './SubMenuItem';

import { Link as RouterLink } from 'react-router-dom';

import {
    ListItem,
    ListItemIcon,
    Icon,
    ListItemText,
    Collapse,
    List
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';



const MenuItem = ({ label, icon, submenus, isOpen=true, to }) => {

    const [open, setOpen] = useState(false);


    const handleClick = () => {
        setOpen(!open);
    };

    const isSubmenu = (open) => {
        return (
            open ? <ExpandLess /> : <ExpandMore />
        )
    }

    const renderLink = useMemo(
        () => forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );



    return (
        <>
            <ListItem button onClick={handleClick} component={renderLink}>
                {icon ?   <ListItemIcon> <Icon>{icon}</Icon></ListItemIcon> : null}
                <ListItemText primary={label} />
                { isOpen && submenus.length != 0 ? isSubmenu(open) : ""}
            </ListItem>
           
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {submenus.map((sub) => {
                        return (
                            <SubMenuItem {...sub} key={sub.id} />
                        )
                    })
                    }
                </List>
            </Collapse>
        </>
    );
}

export default MenuItem;