import React, { useState } from "react"
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";



//Redux
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_CHANGE } from '../../../app/api-client/constants';

//Icons Material
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';



//Component Material
import {
    Paper,
    Grid,
    Button,
    InputLabel,
    FormControl,
    Container,
    CircularProgress
} from '@material-ui/core';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Alert } from '@material-ui/lab';

//Local Component
import clsx from 'clsx';
import logo from '../img/logo.png';
import { useStyles } from './SinginStyles';
import { HelpValidate } from '../../utils/helpValidate';
import { Copyright } from '../../../dashboard/componet/UI/CopyRight'
import { changeNewPass } from "../../../prestapolis/domain/api/PrestapolisApi";


export const ChangePass = () => {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const borderRadius = clsx(classes.padding, classes.borderRadius);
    const [loading, setloading] = useState(false)

    const [password__current, setPassword__current] = useState("");
    const [password__new, setPassword__new] = useState("");
    const [password__confirm, setPassword__confirm] = useState("");
    const [showPassword__current, setShowPassword__current] = useState(false);
    const [showPassword__new, setShowPassword__new] = useState(false);
    const [showPassword__confirm, setShowPassword__confirm] = useState(false);
    const [error, seterror] = useState(false);
    const [message, setmessage] = useState("");
    const [typeAlert, settypeAlert] = useState("");
    // severity="error" severity="warning" severity="success"


    let emailUser = localStorage.getItem(EMAIL_CHANGE)

    const clearForm = () => {
        setPassword__current("");
        setPassword__new("");
        setPassword__confirm("");
    }

    const handledMessage = (message, type) => {
        setmessage(message);
        settypeAlert(type);
        seterror(true);
        setTimeout(() => {
            seterror(false);
        }, 3500);
    }

    const changePasss = (request) => {
        setloading(true);
        changeNewPass(dispatch, request)
            .then((response) => {
                if (response.success) {
                    setloading(false);
                    handledMessage("¡Tu contraseña a sido cambiada satisfactoriamente!", "success");
                    setTimeout(() => {
                        clearForm();
                        BackStep();
                    }, 3600);

                } else {
                    setloading(false);
                    handledMessage("¡Ocurrió un problema cambiando la contraseña revisa los datos!", "error");
                }
            })
            .catch((err) => {
                setloading(false);
                handledMessage("¡Error interno contacte a soporte!", "error");
            })
    }

    const SendForm = (data) => {
        if (data.password__current !== data.password__new && data.password__new === data.password__confirm) {

            const request = {
                email: `COMMERCE-${emailUser}`,
                newPassword: data.password__new
            };

            changePasss(request);

        } else if (data.password__new !== data.password__confirm) {
            handledMessage("¡La contraseña no coincide en la confirmación!", "warning");
        } else {
            handledMessage("¡La contraseña nueva no puede ser igual a la actual!", "warning");
        }
    }


    const BackStep = () => {
        history.push("/login");
    }

    return (
        <div className={classes.centerv} >
            <Container maxWidth="xs" alignitems="center" >
                <Paper className={borderRadius}>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="left"
                        alignItems="left"
                        alignContent="left"
                        wrap="nowrap"

                    >
                        <IconButton onClick={BackStep}>
                            <ArrowBackIcon />
                        </IconButton>

                    </Grid>
                    <div className={classes.margin}>
                        <Grid container justify="center" style={{ marginTop: '15px', maxwidth: '350px' }}>
                            <img src={logo} width="100%" alt="Logo" />
                        </Grid>
                        <Grid container spacing={1} alignItems="flex-end" textAlign="center">

                            <Grid item xs={12}>
                                <form onSubmit={handleSubmit(SendForm)} autocomplete="off">
                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                        <Grid item>
                                            <LockIcon color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} x >
                                            <FormControl fullWidth >
                                                <InputLabel htmlFor="standard-adornment-password">Contraseña actual *</InputLabel>
                                                <Input
                                                    placeholder="Ingresa la contraseña actual"
                                                    label="Contraseña actual*"
                                                    fullWidth
                                                    id="password__current"
                                                    name="password__current"
                                                    type={showPassword__current ? 'text' : 'password'}
                                                    autocomplete="off"
                                                    value={password__current}
                                                    defaultValue={password__current}
                                                    {...HelpValidate().validatePass(register('password__current', {
                                                        required: "La contraseña actual es requerida.",

                                                    }), 8, 15, setError, clearErrors, setPassword__current)}

                                                    error={!!errors.password__current}
                                                    helperText={errors.password__current?.message}

                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => { setShowPassword__current(!showPassword__current) }}
                                                                onMouseDown={(e) => { e.preventDefault() }}
                                                                edge="end"
                                                            >
                                                                {showPassword__current ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <div className={classes.errorLabel}>{errors.password__current?.message}</div>

                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                        <Grid item>
                                            <LockOpenIcon color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} x >
                                            <FormControl fullWidth >
                                                <InputLabel htmlFor="outlined-adornment-password">Contraseña nueva *</InputLabel>
                                                <Input
                                                    placeholder="Ingresa la contraseña nueva*"
                                                    label="Contraseña nueva *"
                                                    fullWidth
                                                    id="password__new"
                                                    name="password__new"
                                                    type={showPassword__new ? 'text' : 'password'}
                                                    autocomplete="off"
                                                    InputLabelProps={{ className: "label__color" }}
                                                    value={password__new}
                                                    defaultValue={password__new}
                                                    {...HelpValidate().validatePass(register('password__new', {
                                                        required: "La nueva contraseña es requerida",

                                                    }), 8, 15, setError, clearErrors, setPassword__new)}

                                                    error={!!errors.password__new}
                                                    helperText={errors.password__new?.message}

                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => { setShowPassword__new(!showPassword__new) }}
                                                                onMouseDown={(e) => { e.preventDefault() }}
                                                                edge="end"
                                                            >
                                                                {showPassword__new ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <div className={classes.errorLabel}>{errors.password__new?.message}</div>

                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                        <Grid item>
                                            <LockOpenIcon color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} x >
                                            <FormControl fullWidth >
                                                <InputLabel htmlFor="outlined-adornment-password">Confirmar *</InputLabel>
                                                <Input
                                                    placeholder="Confirma la contraseña *"
                                                    label="Confirmar *"
                                                    fullWidth
                                                    id="password__confirm"
                                                    name="password__confirm"
                                                    type={showPassword__confirm ? 'text' : 'password'}
                                                    autocomplete="off"
                                                    InputLabelProps={{ className: "label__color" }}
                                                    value={password__confirm}
                                                    defaultValue={password__confirm}
                                                    {...HelpValidate().comparePass(register('password__confirm', {
                                                        required: "Por favor confirma tu contraseña.",

                                                    }), 8, 15, setError, clearErrors, setPassword__confirm, password__new)}

                                                    error={!!errors.password__confirm}
                                                    helperText={errors.password__confirm?.message}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => { setShowPassword__confirm(!showPassword__confirm) }}
                                                                onMouseDown={(e) => { e.preventDefault() }}
                                                                edge="end"
                                                            >
                                                                {showPassword__confirm ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <div className={classes.errorLabel}>{errors.password__confirm?.message}</div>

                                    <div className={classes.wrapper}>
                                        <Grid container justify="center" style={{ marginTop: '35px' }}>
                                            <Button
                                                disabled={loading}
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                style={{ textTransform: "none", width: "inherit" }}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}

                                    </div>
                                    <div style={{ paddingTop: 8 }}>
                                        {error && <Alert severity={typeAlert} >{message}</Alert>}
                                    </div>
                                </form>
                            </Grid>
                        </Grid>
                    </div>
                    <Box >
                        <Copyright />
                    </Box>
                </Paper>
            </Container >
        </div>

    )
}