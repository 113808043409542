import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
    root: {
        
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
      },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 290,
    },

    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(1)
    },
    centerv: {
        alignItems: "center",
        margin: '0 auto',
    },
    borderRadius: {
        borderRadius: '3%'
    },

    alginTextGrid: {

        textAlign: 'center !important',
    },

    largeIcon: {
        width: 200,
        height: 200,
      },

    InputText: {
        paddingLeft: '35%'
    },
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    errorLabel: {
        color: "red",
        padding: 0,
        paddingLeft: 2,
        paddingTop: 15
    },
    inputPerson: {
        marginTop: '15px',
        fontSize: "18px"
    },

}));