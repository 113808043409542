import React, { useRef, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { store } from 'react-notifications-component';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { useDispatch } from 'react-redux';
import { HelpValidate } from '../../user/utils/helpValidate';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import {
    Grid,
    Modal,
    Backdrop,
    TextField,
    Button,
    Fade,
    InputAdornment,
    InputLabel,
    FormControl,
    OutlinedInput,

} from '@material-ui/core';
import {
    reenviarMensaje,
} from '../domain/api/PrestapolisApi'
//My style
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperModal: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #2c8aaf',
        width: 400,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 4, 3),
    },
}));

export default function ModalReenviarMensaje({ isOpen, setopenMondal, data }) {
      
    const classes = useStyles();
    const [isLoadUser, setisLoadUser] = useState(false)
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const [correo, setCorreo] = useState("");
    const [cellphone, setCellphone] = useState("");
    const dispatch = useDispatch();

    
    useEffect(() => {
        let phoneDefault = "";
        if(data != null && data.id != undefined) {
            phoneDefault = data.datosPersona.contactos && 
            data.datosPersona.contactos[0] && 
            data.datosPersona.contactos[0].cellphone ?
                data.datosPersona.contactos[0].cellphone:"";
        }
        setCellphone(phoneDefault)
       
    }, [isOpen]);

   
    let intervalRef = useRef();


    const onCerrar = () => {
        ClearForm()
        clearInterval(intervalRef.current)
        setopenMondal(false)
    }
    const ClearForm = () => {
        setCellphone("")
    }

    //Metodo para reenviar mensaje
    const postReenviarMensaje = () => {
        setisLoadUser(true);
        let request = {
            celular: cellphone,
            id: data.id
        }
        reenviarMensaje(dispatch, request)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    setisLoadUser(false);
                    store.addNotification({
                        title: "El mensaje será enviado al numero celular que indicaste!",
                        message: "----------------",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 6000,
                        }
                    });
                    onCerrar();
                    ClearForm();
                } else {
                    store.addNotification({
                        title: "Error",
                        message: response.mensaje.replace("codigo", "código"),
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 7500,
                        }
                    });
                    setisLoadUser(false)
                }
            })
            .catch((err) => {
                
                store.addNotification({
                    title: "Error",
                    message: "Error de envío del mensaje, por favor comunicarce con soporte@prestapolils.com",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 9500,
                    }
                });
                setisLoadUser(false)
            })
    }

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={(e) => {
                    onCerrar();
                }}
                className={classes.modal}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isOpen}>
                    <div className={classes.paperModal}>
                        <Grid container spacing={1} alignItems="center" textAlign="center">
                            <Grid item xs={12}>
                               

                                    <Grid container spacing={1} alignItems="center" className={classes.inputPerson} >
                                        <Grid container justify="center" style={{ marginTop: '10px' }}>
                                            <div>
                                                <p>
                                                    REEENVIAR MENSAJE DE TEXTO
                                                </p>
                                            </div>
                                        </Grid>

                                        <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '30px' }}>
                                            <FormControl fullWidth variant="outlined" >
                                                <InputLabel htmlFor="outlined-adornment-amount">Celular *</InputLabel>
                                               
                                                <OutlinedInput 
                                                    startAdornment={<InputAdornment position="start"><SettingsPhoneIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                                    labelWidth={70}
                                                    fullWidth
                                                    autocomplete="off"
                                                    type='tel'
                                                    id="cellphone"
                                                    name="cellphone"
                                                    value={cellphone}
                                                   defaultValue={cellphone}
                                                   //value={row.datosPersona.contactos[0].cellphone}
                                                    {...HelpValidate().validateNumber(register('cellphone', {
                                                        required: "El número de celular es requerida.",
                                                        minLength: {
                                                            value: 20,
                                                            message: 'La número celular debe tener minimo 8 caracteres'
                                                        },
                                                    }), 7, 16, setError, clearErrors, setCellphone)}
                                                    error={!!errors.cellphone}
                                                    helperText={errors.cellphone?.message}
                                                />
                                            </FormControl>
                                        </Grid>

                                    </Grid>

                                    <Grid container xs spacing={2} textAlign="center" justifyContent="space-between" style={{ marginTop: '10px' }}>
                                        <Grid item xs={6} spacing={1}  >
                                            <Button
                                                fullWidth={true}
                                                variant="contained"
                                                size="large"
                                                className={classes.margin}
                                                color="secondary"
                                                onClick={(e) => {
                                                    onCerrar()
                                                }}
                                                style={{ textTransform: "none", fontSize: 14, color: "white" }}
                                            >
                                                Cerrar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Button
                                                type="submit"
                                                fullWidth={true}
                                                variant="contained"
                                                size="large"
                                                className={classes.margin}
                                                color="primary"
                                                style={{ textTransform: "none", fontSize: 14 }}
                                                onClick={(e) => {
                                                    postReenviarMensaje();
                                                }}
                                            >
                                                Enviar
                                            </Button>
                                        </Grid>
                                    </Grid>
                              
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
