import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
} from '@material-ui/core';

import vendedor from '../img/vendedor.png'
import admin from '../img/adminPrestapolis.png'
import logo from '../img/logo.png';
import SettingsCellIcon from '@material-ui/icons/SettingsCell';
import EmailIcon from '@material-ui/icons/Email';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Avatar from '@material-ui/core/Avatar';
import { red, orange } from '@material-ui/core/colors';
import { Copyright } from "../../../dashboard/componet/UI/CopyRight";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 350,
        margin: "0 auto",
      
    },
    media: {
        width: '55%',
       height:'90%',
        marginLeft:'auto',
        marginRight:'auto',
        paddingTop: '56.25%', // 16:9
     
        
       
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: orange[500],
    },
    CardHeader: {
        fontSize: "30px"
    },
    
}));


export const HomeSigin = ({ }) => {

    const classes = useStyles();
    const history = useHistory();

    const [reload, setReload] = useState(true)

    const handleCelphone = () => {
        history.push(`/celphone/`);

    }

    const handleLogin = () => {
        history.push(`/login/`);

    }

    // useEffect(() => {

    //     setTimeout(() => {
    //         window.location.reload(reload)
    //     }, 100);

    //     setTimeout(() => {
    //         setReload(false)
    //     }, 100);
    // }, [])



    return (
        <>
            <div>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    style={{ marginTop: '20px' }}
                >
                    <img src={logo} maxWidth="20%" alt="Logo" height="100px" />
                </Grid>
            </div>



            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                style={{ marginTop: '50px' }}

            >
                <Grid item sm={3} md={3} xs={12}>
                    <Card className={classes.root}>
                        <CardActionArea onClick={handleLogin}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <EmailIcon />
                                    </Avatar>
                                }
                                title={<p style={{ fontSize: "16px" }}>Inicia sesión como {<b>administrador</b>}</p>}
                            // subheader="prestapolis comercios"
                            />
                            <CardMedia
                            
                               className={classes.media}
                                 image={vendedor}
                                title="Paella dish">
                               
                            

                            </CardMedia>
                               
                            <CardContent>
                                <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Iniciar sesión con el correo y contraseña registradas en nuestro sistema
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item sm={3} md={3} xs={12} >
                    <Card className={classes.root}>
                        <CardActionArea onClick={handleCelphone}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <SettingsCellIcon />
                                    </Avatar>
                                }

                                title={<p style={{ fontSize: "16px" }}>Inicia sesión como {<b>vendedor</b>}</p>}
                                // subheader="prestapolis comercios"
                                classes={classes.CardHeader}
                            />
                            <CardMedia
                                className={classes.media}
                                
                                image={admin}
                                title="Paella dish"
                            />
                            <CardContent>
                                <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Inicia sesión utilizando el numero de celular registrado en el sistema.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            <div style={{ paddingTop: "100px" }}>
                <Copyright />
            </div>
        </>
    )
}