import { Typography, Link } from "@material-ui/core";

export const Copyright = () => {
    return (
      <>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href={`${process.env.REACT_APP_COPY_RIGHT_LINK}`}  target="_blank">
            {`${process.env.REACT_APP_COPY_RIGHT_TEXT}`}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        <b>{`${process.env.REACT_APP_VERSION}`}</b>
      </Typography>
      </>
    );
  }
  