
//React
import React, { useState } from "react";

//Redux
import { ID_USER } from "../../../app/api-client/constants";


//Local Compoenet
import SiginCellphon from "./SingiCelphone";
import SiginCode from "./SingiCode";
import SiginHelpCode from './SinginHelpCode'
import Sigin from "./Singin";
import {
    sentCallCellphone,
    sentCellPhone,
} from '../../../prestapolis/domain/api/PrestapolisApi'



export default function IndexLoginPage({ setUserIsTrue, setToken }) {

    const [activeSection, setactiveSection] = useState(1)
    const [cellphone, setCellphone] = useState("")
    const [dataCell, setdataCell] = useState({})
    const [errorCode, seterrorCode] = useState(false)
    const [succesCode, setsuccesCode] = useState(false)
    const [mensaje, setMensaje] = useState("")
    const [isTrueNext, setstate] = useState(false)
    const [isContinue, setisContinue] = useState(false);
    const [messageSucces, setMessageSucces] = useState("")

    const [isLaodSend, setIsLaodSend] = useState(false)
    const [sendCode, setsendCode] = useState(true);



    const SendSmsCellphone = (data, type) => {
        if (sendCode) {
            setsendCode(false)
            setMessageSucces("")
            setIsLaodSend(true)
            sentCellPhone(data)
                .then((response) => {
                    setIsLaodSend(false);
                    if (response.codigoRespuesta === "0000") {
                        if (type === "continue") {
                            // localStorage.setItem(ID_USER, response.respuesta);
                            setstate(true)
                            setMessageSucces("El código se envio correctamente")
                            setsuccesCode(true)
                            setTimeout(() => {
                                nextStep()
                                setsuccesCode(false);
                                setIsLaodSend(false)
                            }, 1200);

                        } else if (type === "reload") {
                            // localStorage.setItem(ID_USER, response.respuesta);
                            setstate(true)
                            setMessageSucces("El código se envio correctamente")
                            setsuccesCode(true)
                            setTimeout(() => {
                                setsuccesCode(false);
                            }, 2000);
                        }

                    } else {

                        setstate(false);
                        seterrorCode(true)
                        setMensaje("¡El celular ingresado no se encuentra en nuestra base de datos!")
                        setTimeout(() => {
                            seterrorCode(false)
                        }, 3500);

                    }
                })
                .catch((err) => {
                    setIsLaodSend(false)
                   
                });
        }

    }

    const sendCallCellphone = (data) => {
        if (sendCode) {
            setsendCode(false)
            setMessageSucces("")
            setIsLaodSend(true)
            sentCallCellphone(data)
                .then((response) => {
                    if (response.codigoRespuesta === "0000") {
                        setMessageSucces("El código se envio correctamente, en breve recibiras una llamada")
                        setstate(true)
                        setsuccesCode(true)
                        setTimeout(() => {
                            setsuccesCode(false);
                        }, 4000);
                    } else {
                        setstate(false);
                        seterrorCode(true)
                        setMensaje("¡El celular ingresado no se encuentra en nuestra base de datos!")
                        setTimeout(() => {
                            seterrorCode(false)
                        }, 3500);
                    }


                })
                .catch((err) => {
                    setstate(false);
                    seterrorCode(true)
                    setMensaje(`${err}`)
                    setTimeout(() => {
                        seterrorCode(false)
                    }, 3500);
                })
        }
    }

    const nextStep = () => {
        setactiveSection(activeSection + 1);
    }

    const backStep = () => {
        setactiveSection(activeSection - 1);
    }

    // const SignInPage = ({ history, onSetAuthUser }) => (
    //     <Router>
    //         <div className={process.env.REACT_APP_BOX_PRESTAPOLIS}>
    //             <div className="container">
    //                 <Switch>
    //                     <Route
    //                         path="*"
    //                         component={() => (
    //                             <LoginFrom history={history} onSetAuthUser={onSetAuthUser} />
    //                         )}
    //                     />
    //                 </Switch>
    //             </div>
    //         </div>
    //     </Router>
    // );

    // const LoginFrom = ({ history, onSetAuthUser }) => (
    //     <div className="singin">
    //         <SignInForm history={history} onSetAuthUser={onSetAuthUser} />
    //     </div>
    // );

    const handleActiveSection = () => {


        switch (activeSection) {
            case 1:
                return (
                    <div>
                        <SiginCellphon
                            setCellphone={setCellphone}
                            SendSmsCellphone={SendSmsCellphone}
                            nextStep={nextStep}
                            setdataCell={setdataCell}
                            succesCode={succesCode}
                            errorCode={errorCode}
                            mensaje={mensaje}
                            isTrueNext={isTrueNext}
                            isLaodSend={isLaodSend}
                            setisContinue={setisContinue}

                        />
                    </div>
                );

            case 2:
                return (
                    <div>
                        <SiginCode
                            cellphone={cellphone}
                            backStep={backStep}
                            nextStep={nextStep}
                            errorCode={errorCode}
                            isContinue={isContinue}

                        />

                    </div>

                );
            case 3:
                return (
                    <div>
                        <SiginHelpCode
                            backStep={backStep}
                            dataCell={dataCell}
                            SendSmsCellphone={SendSmsCellphone}
                            sendCallCellphone={sendCallCellphone}
                            succesCode={succesCode}
                            errorCode={errorCode}
                            mensaje={mensaje}
                            messageSucces={messageSucces}
                            setsendCode={setsendCode}
                            sendCode={sendCode}



                        />
                    </div>
                );

            default:
                return (<div></div>);

        }
    }


    return (
        <>
            <div>{handleActiveSection()}</div>
        </>
    )





}