import React, { useEffect, useState } from 'react';
import 'react-notifications-component/dist/theme.css'
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import ReactLoading from "react-loading";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { formatData, formatMoney, currentDateFormat } from '../utils/validation';
import ModaleExportarReporte from '../modals/modalExportarReporte';
import { BAAS, ID_COMERCIO, ID_SUCURSAL, NAME_COMERCIO } from "../../app/api-client/constants";
import { useDispatch } from 'react-redux';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import Paginator from '../utils/paginator';
import { makeStyles } from '@material-ui/core/styles';
import MigaPan from '../../app/orussystem/component/MigaPan';
import Link from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import {
    Paper,
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    TableContainer,
    TextField,
} from '@material-ui/core';
import {
    getSucursalByIdCommerce,
    getReporteRecaudos,
} from '../domain/api/PrestapolisApi'
//stylos
const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        paddingTop: 10,
        display: "flex",
    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center",
    },
    style: {
        marginLeft: "40%",
    },
    table: {
        minWidth: 650,
    },
}));


export const Historico = ({ }) => {

    const classes = useStyles();
    const [isLoadUser, setisLoadUser] = useState(false);
    const [data, setData] = useState([]);
    const [isOpenModalExportarReporte, setisOpenModalExportarReporte] = useState(false);
    const [listSucursal, setlistSucursal] = useState([]);
    const [filterSucursal, setfilterSucursal] = useState([]);
    const [fechaIni, setfechaIni] = useState("");
    const [fechaFin, setfehcaFin] = useState("");
    const [idSucursalSelect, setidSucursalSelect] = useState("");
    const [idSucursalAll, setIdSucursalAll] = useState("");
    const [fechaIniSucursalAll, setFechaIniSucursalAll] = useState("");
    const [fechaFinSucursalAll, setFechaFinSucursalAll] = useState("");
    const [nombreSucursalAll, setNombreSucursalAll] = useState("");
    const styleData = classes.Data
    const styleSearch = classes.search
    const [value, setValue] = React.useState(0);
    const [totalElements, settotalElements] = useState(0);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [filter, setFilter] = useState("");
    const dispatch = useDispatch();
    let nameComercio = localStorage.getItem(NAME_COMERCIO)
    let baas = localStorage.getItem(BAAS)
    let namecomerce = baas != "true" ? 'Home' : nameComercio

    const SetDataAsync = (setState, item) => {
        return new Promise((resolve, reject) => {
            resolve(setState(item));
        })
    }

    let idComercio = localStorage.getItem(ID_COMERCIO);
    let nameSucursal = localStorage.getItem(NAME_COMERCIO);


    //Filter Estados    
    function getDropdownButton({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: Seleccionar todos`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} Seleccionado`;
        }
    }

    //Onchage filter
    function onChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            if (event.name === "sucursal-filter") {
                let idSucursalesSelect = []
                let idString = []
                listSucursal.map((item) => {
                    idSucursalesSelect.push(item.id)
                })
                idString = idString.toString();
                SetDataAsync(setidSucursalSelect, idString).then((response) => {
                    GetSucursales(idString, 0, 20, "")
                })
            }
            this.setState(this.options);

        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            if (event.name === "sucursal-filter") {
                setidSucursalSelect("")
            }
            this.setState([]);
        } else if (event.action === "deselect-option") {
            if (event.name === "sucursal-filter") {
                let idString = []
                let arrayFilter = value.filter((o) => o.value !== "*")
                arrayFilter.map((item) => {
                    idString.push(item.id)
                })
                idString = idString.toString();
                SetDataAsync(setidSucursalSelect, idString).then((response) => {
                    GetSucursales(idString, 0, 20, "")
                })
            }
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            if (event.name === "sucursal-filter") {
                let idString = []
                listSucursal.map((item) => {
                    idString.push(item.id)
                })
                idString = idString.toString();
                SetDataAsync(setidSucursalSelect, idString).then((response) => {
                    GetSucursales(idString, 0, 20, "")
                })
            }
            this.setState(this.options);
        } else {
            if (event.name === "sucursal-filter") {

                let select = [];
                let stringSelect = ""
                value.map((item) => {
                    select.push(item.id)
                })
                stringSelect = select.toString();
                //setFilterEstates(stringSelect)
                SetDataAsync(setidSucursalSelect, stringSelect)
            }
            this.setState(value);
        }
    }

    const ClearForm = () => {
        setfechaIni("")
        setfehcaFin("")
        setFilter("")
    }

    const GetSucursales = (id, page, size, filter) => {
        getSucursalByIdCommerce(dispatch, id, page, size, filter)
            .then((response) => {
                if (response.code === "0000") {

                    let data = response.content;
                    let lista = [];
                    let idSucursales = ""
                    let nombreSucursalAll = ""
                    data.map((item) => {
                        lista.push({
                            id: item.id,
                            value: item.id,
                            label: item.nombre,
                        });

                        idSucursales += item.id + "-";
                        nombreSucursalAll += item.nombre + "-";
                    });
                    setlistSucursal(lista)
                    let tamano = idSucursales.length - 1
                    setIdSucursalAll(idSucursales.substring(0, tamano));
                    setNombreSucursalAll(nombreSucursalAll.replace("-", ""));
                }
            })
            .catch((err) => {
               
            })
    }

    const getReporteHistorico = (value, page, size, id_sucursal, fecha_ini, fecha_fin, filter) => {
        setisLoadUser(true)
        let idComerce = localStorage.getItem(ID_COMERCIO);
        
        let localstado = 1;
        if (value == 1) {
            localstado = 0;
            id_sucursal = !id_sucursal ? idSucursalAll : id_sucursal;
            fecha_ini = !fecha_ini ? fechaIniSucursalAll : fecha_ini;
            fecha_fin = !fecha_fin ? fechaFinSucursalAll : fecha_fin;
        }
        getReporteRecaudos(dispatch, localstado, page, size, idComerce, id_sucursal, fecha_ini, fecha_fin, filter)
            .then((response) => {
                if (response.code === "0000") {
                    setData(response.content)
                    settotalElements(response.totalElements)
                    setisLoadUser(false)

                }
            })
            .catch((err) => {
               
            })
    }

    useEffect(() => {
        setPage(0);
        GetSucursales(idComercio, 0, 1000, "")
    }, [])

    useEffect(() => {
        getReporteHistorico(value, page, size, idSucursalAll, fechaIni, fechaFin, filter)
    }, [page], { totalElements })


    return (
        <>
            <ModaleExportarReporte
                isOpen={isOpenModalExportarReporte}
                setopenMondal={setisOpenModalExportarReporte}
                fecha_Ini={fechaIni}
                fecha_Fin={fechaFin}
                id_sucursal={idSucursalAll}
                filter={filter}
                page={page}
                size={size}
                id_comercio={idComercio}
            >
            </ModaleExportarReporte>

            <Grid item xs={12} >
                <MigaPan
                    namePage={`Historico`}
                    to="/"
                    children2="Recaudos"
                    to2="/recaudos"           
                >
                    {namecomerce}
                </MigaPan>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={3} >
                    <ReactMultiSelectCheckboxes
                        name="sucursal-filter"
                        options={listSucursal}
                        placeholderButtonLabel="Sucursales..."
                        getDropdownButtonLabel={getDropdownButton}
                        value={filterSucursal}
                        onChange={onChange}
                        setState={setfilterSucursal}
                    />
                </Grid>
                <Grid item xs={2} >
                    <div style={{ backgroundColor: "#ffffff", padding: "10px" }}>
                        <TextField
                            id="date"
                            label="Cedula"
                            type="text"
                            fullWidth
                            placeholder='CEDULA'
                            inputProps={{ className: styleSearch }}
                            value={filter}
                            onChange={((e) => { setFilter(e.target.value) })}
                        />
                    </div>
                </Grid>
                <Grid item xs={2} >
                    <div style={{ backgroundColor: "#ffffff", padding: "10px" }}>
                        <TextField
                            id="date"
                            label="Fecha Inicial"
                            type="date"
                            fullWidth
                            defaultValue={fechaIni}
                            value={fechaIni}
                            onChange={(e) => { setfechaIni(e.target.value) }}
                            inputProps={{ className: styleData }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={2} >
                    <div style={{ backgroundColor: "#ffffff", padding: "10px" }}>
                        <TextField
                            id="date"
                            label="Fecha Final"
                            type="date"
                            fullWidth
                            defaultValue={fechaFin}
                            value={fechaFin}
                            onChange={(e) => { setfehcaFin(e.target.value) }}
                            inputProps={{ className: styleData }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                    <IconButton
                        size="large"
                        color="Primary"
                        style={{ backgroundColor: "#fff" }}
                        onClick={(e) => {
                            getReporteHistorico(value, page, size, idSucursalSelect, fechaIni, fechaFin, filter)
                            ClearForm();
                        }}
                    >
                        <RotateLeftIcon style={{ fontSize: 30 }} />
                    </IconButton>
                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                    <IconButton
                        size="large"
                        color="Primary"
                        style={{ backgroundColor: "#fff" }}
                        onClick={(e) => { getReporteHistorico(value, page, size, idSucursalSelect, fechaIni, fechaFin, filter) }}
                    >
                        <SearchIcon style={{ fontSize: 30 }} />
                    </IconButton>

                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                    <Button
                     style={{color:'#FFFFFF',
                     background:'#2BAEB3'}}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={(e) => {
                            setisOpenModalExportarReporte(true)
                        }}
                    >
                        Exportar
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={1} style={{ marginTop: '80px' }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Paper square >
                            <Tabs sx={{ maxHeight: 440 }}
                                value={value}
                                textColor="primary"
                                indicatorColor="primary"
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                    setPage(0);
                                    getReporteHistorico(newValue, page, size, idSucursalSelect, fechaIni, fechaFin, filter)
                                }}
                            >
                                <Tab label="Procesados" />
                                <Tab label="Pendientes" />
                            </Tabs>
                            <h3>

                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow alignItems="center">
                                                <TableCell align="center">Fecha de pago</TableCell>
                                                <TableCell align="center">Cédula</TableCell>
                                                <TableCell align="center" >Nombre</TableCell>
                                                <TableCell align="center">Valor pagado</TableCell>
                                                <TableCell align="center">Estado</TableCell>
                                                <TableCell align="center">Sucursal</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {isLoadUser ?
                                                <>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                        alignContent="center"
                                                        wrap="nowrap"
                                                    >
                                                        <ReactLoading
                                                            type={"bars"}
                                                            color={"#000"}
                                                            height={50}
                                                            width={50}
                                                        />
                                                    </Grid>
                                                </>
                                                : data.map((row, key) => (
                                                    <TableRow key={key} >
                                                        <TableCell align="center">{`${currentDateFormat(row.fecha_pago)}`}</TableCell>
                                                        <TableCell align="center">{row.documento === null || row.documento === undefined ? "-" : row.documento}</TableCell>
                                                        <TableCell align="center">{row.nombre_completo.length == 0 ? "-" : row.nombre_completo}</TableCell>
                                                        <TableCell align="center">{`${formatMoney(row.valor_pago === null || row.valor_pago === undefined ? "-" : row.valor_pago)}`}</TableCell>
                                                        <TableCell align="center">{value == 1 ? "Pendiente" : "Procesado"}</TableCell>
                                                        <TableCell align="center">{nameSucursal === null || nameSucursal === undefined ? "-" : nameSucursal}</TableCell>
                                                    </TableRow>

                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer></h3>
                        </Paper>
                        <br />
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                        >
                            <Paginator
                                totalElement={Math.round(totalElements / size)}
                                setPage={setPage}
                                page={page}
                            />
                        </Grid>
                        <br />
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
