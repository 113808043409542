//React
import React, { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';



//Redux
import { EMAIL_CHANGE } from '../../app/api-client/constants';
import { useDispatch } from 'react-redux';


//Icons Material
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//Components Material
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    FormControl,
    InputLabel,
    CircularProgress,
} from '@material-ui/core';

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';



//Local Component
import { HelpValidate } from '../../user/utils/helpValidate';
import { changeNewPass } from '../domain/api/PrestapolisApi';


function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 10 + rand();
    const left = 10 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

//My style
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperModal: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #2c8aaf',
        width: 450,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 4, 5),
    },
    seeMore: {
        marginTop: theme.spacing(3),
    },
    div: {
        margin: '0 auto',
        minWidth: '40%',
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        overflow: 'auto',
        margin: '0 auto',
        flexDirection: 'column',
    },
    paperPhoto: {
        padding: theme.spacing(0),
        display: 'flex',
        margin: '0 auto',
        maxWidth: '500px',
        overflow: 'auto',
        flexDirection: 'column',
    },
    btnFactura: {
        margin: '0px !important',
        textAlign: 'center',
        justifycontent: 'center'
    },
    button: {
        color: '#ff0000 !important',
    },
    img: {
        maxWidth: '1000px',
    },
    inputPerson: {
        marginTop: '15px',
        fontSize: "18px"
    },
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    errorLabel: {
        color: "red",
        padding: 0,
        paddingLeft: 34,
        paddingTop: 5
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(2),
        position: 'relative',
        // paddingBottom: 20

      },
}));

const DialogTitle = ((props) => {
    const classes = useStyles();
    const { children, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{ textAlign: "center" }}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function ModalCamabiarContraseña({ isOpen, setopenMondal, onSubmit }) {

    const classes = useStyles();
    const email = localStorage.getItem(EMAIL_CHANGE)
    const [password__current, setPassword__current] = useState("");
    const [password__new, setPassword__new] = useState("");
    const [password__confirm, setPassword__confirm] = useState("");
    const [showPassword__current, setShowPassword__current] = useState(false);
    const [showPassword__new, setShowPassword__new] = useState(false);
    const [showPassword__confirm, setShowPassword__confirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const [modalStyle] = useState(getModalStyle);

    const dispatch = useDispatch();
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();

    const clearForm = () => {
        setPassword__current("")
        setPassword__new("")
        setPassword__confirm("")
    }

    const changePasss = (request) => {
        setLoading(true)
        changeNewPass(dispatch, request)
            .then((response) => {

                if (response.success) {
                    setLoading(false)
                    store.addNotification({
                        title: "Exito!",
                        message: "Tu contraseña a sido cambiada exitosamente",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeOut"],
                        animationOut: ["animate__animated", "animate__fadeIn"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });

                    setTimeout(() => {
                        setopenMondal(false);
                        clearForm();
                    }, 3500);
                }else{
                    setLoading(false)
                    store.addNotification({
                        title: "Error",
                        message: "No ha sido posible cambiar la contraseña revisa tus datos",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3500,
                            onScreen: true
                        }
                    });
                }


            })
            .catch((err) => {
                setLoading(false)
                store.addNotification({
                    title: "Error",
                    message: "Ocurrió un error interno",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3500,
                        onScreen: true
                    }
                });
            })
    }

    const SendForm = (data) => {
        if (data.password__current !== data.password__new && data.password__new === data.password__confirm) {


            const request = {
                email: `COMMERCE-${email}`,
                newPassword: data.password__new
            }

            changePasss(request);

        } else if (data.password__new !== data.password__confirm) {
            store.addNotification({
                title: "Error",
                message: "La contraseña nueva no coincide en la confirmación",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3500,
                    onScreen: true
                }
            });
        } else {
            store.addNotification({
                title: "Error",
                message: "La contraseña nueva no puede ser igual a la anterior contraseña",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3500,
                    onScreen: true
                }
            });
        }
    }
    useEffect(() => {

    }, [])



    return (
        <div>
            <Dialog
                onClose={(e) => { setopenMondal(false) }}
                aria-labelledby="customized-dialog-title"
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                style={{ maxHeight: "55%" }}
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle id="customized-dialog-title" onClose={(e) => { setopenMondal(false) }} >
                    Cambiar contraseña
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} alignItems="flex-end" textAlign="center">

                        <Grid item xs={12}>
                            <form onSubmit={handleSubmit(SendForm)} autocomplete="off">
                                <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                    <Grid item>
                                        <LockIcon color="primary" />
                                    </Grid>
                                    <Grid item md={true} sm={true} xs={true} x >
                                        <FormControl fullWidth >
                                            <InputLabel htmlFor="standard-adornment-password">Contraseña actual *</InputLabel>
                                            <Input
                                                placeholder="Ingresa la contraseña actual"
                                                label="Contraseña actual*"
                                                fullWidth
                                                id="password__current"
                                                name="password__current"
                                                type={showPassword__current ? 'text' : 'password'}
                                                autocomplete="off"
                                                value={password__current}
                                                defaultValue={password__current}
                                                {...HelpValidate().validatePass(register('password__current', {
                                                    required: "La contraseña actual es requerida.",

                                                }), 8, 15, setError, clearErrors, setPassword__current)}

                                                error={!!errors.password__current}
                                                helperText={errors.password__current?.message}

                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => { setShowPassword__current(!showPassword__current) }}
                                                            onMouseDown={(e) => { e.preventDefault() }}
                                                            edge="end"
                                                        >
                                                            {showPassword__current ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <div className={classes.errorLabel}>{errors.password__current?.message}</div>

                                <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                    <Grid item>
                                        <LockOpenIcon color="primary" />
                                    </Grid>
                                    <Grid item md={true} sm={true} xs={true} x >
                                        <FormControl fullWidth >
                                            <InputLabel htmlFor="outlined-adornment-password">Contraseña nueva *</InputLabel>
                                            <Input
                                                placeholder="Ingresa la contraseña nueva*"
                                                label="Contraseña nueva *"
                                                fullWidth
                                                id="password__new"
                                                name="password__new"
                                                type={showPassword__new ? 'text' : 'password'}
                                                autocomplete="off"
                                                InputLabelProps={{ className: "label__color" }}
                                                value={password__new}
                                                defaultValue={password__new}
                                                {...HelpValidate().validatePass(register('password__new', {
                                                    required: "La nueva contraseña es requerida",

                                                }), 8, 15, setError, clearErrors, setPassword__new)}

                                                error={!!errors.password__new}
                                                helperText={errors.password__new?.message}

                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => { setShowPassword__new(!showPassword__new) }}
                                                            onMouseDown={(e) => { e.preventDefault() }}
                                                            edge="end"
                                                        >
                                                            {showPassword__new ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <div className={classes.errorLabel}>{errors.password__new?.message}</div>

                                <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                    <Grid item>
                                        <LockOpenIcon color="primary" />
                                    </Grid>
                                    <Grid item md={true} sm={true} xs={true} x >
                                        <FormControl fullWidth >
                                            <InputLabel htmlFor="outlined-adornment-password">Confirmar *</InputLabel>
                                            <Input
                                                placeholder="Confirma la contraseña *"
                                                label="Confirmar *"
                                                fullWidth
                                                id="password__confirm"
                                                name="password__confirm"
                                                type={showPassword__confirm ? 'text' : 'password'}
                                                autocomplete="off"
                                                InputLabelProps={{ className: "label__color" }}
                                                value={password__confirm}
                                                defaultValue={password__confirm}
                                                {...HelpValidate().comparePass(register('password__confirm', {
                                                    required: "Por favor confirma tu contraseña.",

                                                }), 8, 15, setError, clearErrors, setPassword__confirm, password__new)}

                                                error={!!errors.password__confirm}
                                                helperText={errors.password__confirm?.message}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => { setShowPassword__confirm(!showPassword__confirm) }}
                                                            onMouseDown={(e) => { e.preventDefault() }}
                                                            edge="end"
                                                        >
                                                            {showPassword__confirm ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <div className={classes.errorLabel}>{errors.password__confirm?.message}</div>

                                <div className={classes.wrapper}>
                                    <Grid container justify="center" style={{ marginTop: '35px' }}>
                                        <Button
                                            disabled={loading}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            style={{ textTransform: "none", width: "inherit" }}>
                                            Guardar
                                        </Button>
                                    </Grid>
                                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </form>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );


}