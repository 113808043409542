import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { loginBytoken, logout, selectUserPhone } from '../../domain/userPhoneSlice';
import { ACCESS_TOKEN } from '../../../app/api-client/constants';
import Header from '../../../dashboard/componet/AppBarOrs/Header';

const withAuthentication = Component => (props) => {

  const dispatch = useDispatch();

  const user = useSelector(selectUserPhone);
  const token = localStorage.getItem(ACCESS_TOKEN);
  
  useEffect(() => {
    setDispatch();
  }, [dispatch]);

  

  const setDispatch = async () => {

    if (!token) {
      await dispatch(logout());
      return;
    }

    if (!user) {
      await dispatch(loginBytoken())
    }

  }
  return <Component {...props} />;

};

export default withAuthentication;