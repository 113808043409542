
export function formatData(date) {

    //let fecha = new Date(date)
    //let dia = fecha.getDay();
    //let mes = fecha.getMonth();
    //let año = fecha.getFullYear();
    //let hora = fecha.getHours();
    //let min = fecha.getMinutes();
    //let seg = fecha.getSeconds();

    //return `${dia}/${mes}/${año}: ${hora}:${min}`;
    let value = date.split("-");
    let time = value[2].split("T");
    return `${time[0]}-${value[1]}-${value[0]} ${time[1]}`;
}
export function currentDateFormat(date) {
    //Fri, 30 Sep 2022 00:00:00 GMT
    //30/09/2022 00:00:00
    let fecha = new  Date(date).toUTCString()
    let dia = fecha.substring(5, 7)
    let mes = converMesNumberEN(fecha.substring(8, 11))
    let año = fecha.substring(12, 16)
    let dateHora = fecha.substring(17, 25)
    return `${dia}/${mes}/${año}: ${dateHora}`;
}

export function converMesNumberEN (mes) {
    switch (mes) {
        case "Jan":
            return "01";
        case "Feb":
            return "02";
        case "Mar":
            return "03";
        case "Apr":
            return "04";
        case "May":
            return "05";
        case "Jun":
            return "06";
        case "Jul":
            return "07";
        case "Aug":
            return "08";
        case "Sep":
            return "09";
        case "Oct":
            return "10";
        case "Nov":
            return "11";
        case "Dec":
            return "12";

        default:
            break;
        
    }
    return mes;
}

export function dateFormatwithoutTime(date) {

    let value = date.split("-");
    let time = value[2].split("T");
    return `${time[0]}-${value[1]}-${value[0]}`;
}

export function formatMoney(number) {

    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }).format(number);
}


export function writeNumber(text) {
    if (!isNaN(text)) {
        return text;
    }
    return text.substring(0, text.length - 1);

}


export function writeText(text) {
    if (isNaN(text)) {
        return text;
    }
    return text.substring(0, text.length - 1);

}

export function formatNumberMoney(value) {

    value = value.toString().replaceAll(".", "").replaceAll(",", "");
    value = writeNumber(value);
    value = new Intl.NumberFormat(("ban", "id")).format(value);
    return value;
}
