import { Button, CircularProgress, Container, FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Select, Typography } from "@material-ui/core";
import React, { createRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { HelpValidate } from "../../../user/utils/helpValidate";
import { useStyles } from "../calcStyle";
import { useDispatch } from "react-redux";
import { ID_COMERCIO } from "../../../app/api-client/constants";
//Icons Material
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EventIcon from '@material-ui/icons/Event';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { getSucursalByIdCommerce } from "../../domain/api/PrestapolisApi";
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { CODE_COMERCIO } from "../../../app/api-client/constants";


export const DatosUsuario = ({ handledSendForm, handledBack, dataPersona, listSucursal }) => {
  


    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();

    const classes = useStyles();
    const borderRadius = clsx(classes.padding, classes.borderRadius);
    const [cedula, setCedula] = useState("");
    const [apellido, setApellido] = useState("");
    const [fecha_expedicion, setFecha_expedicion] = useState("");
    const [celular, setCelular] = useState("");
    const [correo, setCorreo] = useState("");
    const [sucursal, setSucursal] = useState("")
    const [showSend, setShowSend] = useState(false)
    const [disable, setDisable] = useState(false);
    const dispatch = useDispatch();

    const c1 = createRef();
    const c2 = createRef();
    const c3 = createRef();
    const c4 = createRef();
    const c5 = createRef();
    const c6 = createRef();
    const [selectSucursal, setSelectSucursal] = useState({});
    const [inputValue, setInputValue] = useState("");


    const submitFocus = () => {

        c1.current.focus();
        c2.current.focus();
        c3.current.focus();
        c4.current.focus();
        c5.current.focus();
        c6.current.focus();
    }



    const onSubmit = (data, e) => {
        setShowSend(true)
        setTimeout(() => {
            setShowSend(false)
        }, 3000);

        data = {...data, sucursal:selectSucursal.id}
        handledSendForm(data)
    }


    const setDate = () => {


        let cedula = dataPersona.cedula !== undefined
            ? dataPersona.cedula
            : ""


        let apellido = dataPersona.apellido !== undefined
            ? dataPersona.apellido
            : ""

        let fecha_exp = dataPersona.fecha_expedicion !== undefined
            ? dataPersona.fecha_expedicion
            : ""


        let celular = dataPersona.celular !== undefined
            ? dataPersona.celular
            : ""


        let correo = dataPersona.correo !== undefined
            ? dataPersona.correo
            : ""


        let sucursal = dataPersona.sucursal !== undefined
            ? dataPersona.sucursal
            : ""


        setCedula(cedula)
        setApellido(apellido)
        setFecha_expedicion(fecha_exp)
        setCelular(celular)
        setCorreo(correo)
        setSucursal(sucursal)
    }



    useEffect(async () => {


        setDate();
       


    }, [dataPersona])




    return (
        <>
            <div className={classes.centerv} >
                <Container maxWidth="xs" alignitems="center" >
                    <Paper className={borderRadius}>
                        <div className={classes.margin}>
                            <Grid
                                container
                                justify="center"
                                alignItems="flex-start"
                                alignContent="stretch"
                                wrap="nowrap"

                            >
                                <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Datos de usuario
                                </Typography>
                            </Grid>
                            <form autocomplete="off" onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '30px' }}>
                                    <FormControl fullWidth variant="outlined" >
                                        <Autocomplete
                                            id="controllable-states-demo"
                                            options={listSucursal}
                                            getOptionLabel={(option) =>  option.nombre}
                                            value={selectSucursal}
                                            onChange={(event, newValue) => {
                                                setSelectSucursal(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Sucursal" variant="outlined" />}
                                        >
                                        </Autocomplete>
                                    </FormControl>
                                </Grid>
                                <div className={classes.errorLabel}>{errors.sucursal?.message}</div>


                                <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '30px' }}>
                                    <FormControl fullWidth variant="outlined" >
                                        <InputLabel htmlFor="outlined-adornment-amount">Cedula *</InputLabel>
                                        <OutlinedInput
                                            id="cedula"
                                            startAdornment={<InputAdornment position="start"><AccountBoxIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                            labelWidth={165}
                                            fullWidth
                                            name="cedula"
                                            autocomplete="off"
                                            pattern="[0-9]{0,13}"

                                            value={cedula.replace(".", "")}
                                            type="tel"
                                            onKeyDown="if(this.value.length==13) return false;"
                                            inputProps={{ className: "label__color", ref: c2 }}
                                            {...HelpValidate().validateNumber(register('cedula', {
                                                required: "Cedula requerida.",

                                                minLength: {
                                                    value: 2,
                                                    message: 'Ingresa una cedula valido'
                                                },
                                            }), 7, 16, setError, clearErrors, setCedula)}
                                            helperText={errors.cedula?.message}
                                        />
                                    </FormControl>
                                </Grid>
                                <div className={classes.errorLabel}>{errors.cedula?.message}</div>


                                <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '15px' }}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-amount">Primer Apellido *</InputLabel>
                                        <OutlinedInput
                                            id="apellido"
                                            autocomplete="off"
                                            startAdornment={<InputAdornment position="start"><AssignmentIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                            labelWidth={150}
                                            fullWidth
                                            name="apellido"
                                            value={apellido}
                                            inputProps={{ className: "label__color", ref: c3 }}
                                            type="text"
                                            {...HelpValidate().validateText(register('apellido', {
                                                required: "Apellido requerido.",
                                                minLength: {
                                                    value: 2,
                                                    message: 'Por favor ingresa un apellido valido'
                                                },
                                            }), setApellido)}
                                            helperText={errors.apellido?.message}
                                        />
                                    </FormControl>
                                </Grid>
                                <div className={classes.errorLabel}>{errors.apellido?.message}</div>

                                <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '15px' }}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-amount">Fecha de expedición *</InputLabel>
                                        <OutlinedInput
                                            id="fecha_expedicion"
                                            startAdornment={<InputAdornment position="start"><EventIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                            labelWidth={150}
                                            fullWidth
                                            autocomplete="off"
                                            name="fecha_expedicion"
                                            value={fecha_expedicion}
                                            inputProps={{ className: "label__color", ref: c4 }}
                                            type="date"
                                            {...HelpValidate().isData(register('fecha_expedicion', {
                                                required: "fecha de expedicion requerida.",
                                                minLength: {
                                                    value: 2,
                                                    message: 'Por favor ingresa un fecha de expedicion'
                                                },
                                            }), setError, clearErrors, "La fecha de expedicion no es valida", setFecha_expedicion)}
                                            helperText={errors.fecha_expedicion?.message}
                                        />
                                    </FormControl>
                                </Grid>
                                <div className={classes.errorLabel}>{errors.fecha_expedicion?.message}</div>

                                <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '15px' }}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-amount">Celular *</InputLabel>
                                        <OutlinedInput
                                            id="celular"
                                            startAdornment={<InputAdornment position="start"><SettingsPhoneIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                            labelWidth={150}
                                            fullWidth
                                            name="celular"
                                            autocomplete="off"
                                            value={celular}
                                            inputProps={{ className: "label__color", ref: c5 }}
                                            type="tel"
                                            {...HelpValidate().validateNumber(register('celular', {
                                                required: "Celular requerido.",
                                                minLength: {
                                                    value: 2,
                                                    message: 'Por favor ingresa un celular valido'
                                                },
                                            }), 10, 10, setError, clearErrors, setCelular)}
                                            helperText={errors.celular?.message}
                                        />
                                    </FormControl>
                                </Grid>
                                <div className={classes.errorLabel}>{errors.celular?.message}</div>

                                <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '15px' }}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-amount">Correo *</InputLabel>
                                        <OutlinedInput
                                            id="correo"
                                            startAdornment={<InputAdornment position="start"><AlternateEmailIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                            labelWidth={150}
                                            fullWidth
                                            autocomplete="off"
                                            name="correo"
                                            value={correo}
                                            inputProps={{ className: "label__color", ref: c6 }}
                                            type="email"
                                            {...HelpValidate().formatEmailGeneric(register('correo', {
                                                required: "Email requierido.",
                                                minLength: {
                                                    value: 2,
                                                    message: 'Por favor ingresa un email valido'
                                                },
                                                pattern: { value: /^\S+@\S+$/i, message: "Formato no valido" }
                                            }), setError, clearErrors, setCorreo)}
                                            helperText={errors.correo?.message}
                                        />
                                    </FormControl>
                                </Grid>
                                <div className={classes.errorLabel}>{errors.correo?.message}</div>

                                <div className={classes.wrapper}>
                                    <Grid container justify="center" style={{ marginTop: '20px' }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            id='continuar'
                                            //onMouseEnter={(e) => (submitFocus())}
                                            disabled={showSend && disable} onClick={() => setDisable(true)}
                                            style={{ textTransform: "none", width: "inherit" }}
                                        >
                                            Enviar datos
                                        </Button>
                                    </Grid>
                                    {showSend && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>

                                <div className={classes.wrapper}>
                                    <Grid container justify="center" style={{ marginTop: '20px' }}>
                                        <Button
                                            disabled={showSend}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            id='volver'
                                            onClick={handledBack}
                                            //onMouseEnter={(e) => (submitFocus())}

                                            style={{ textTransform: "none", width: "inherit" }}
                                        >
                                            Volver
                                        </Button>
                                    </Grid>
                                    {showSend && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>

                            </form>

                        </div>
                    </Paper>

                </Container >
            </div>
        </>
    )
}
