import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ACCESS_TOKEN } from "../../app/api-client/constants";
import { request } from "../../app/api-client/ApiClient";


export const postLogin = createAsyncThunk(
    'user/login',
    async (login) => {

        return await request({
            url: `${process.env.REACT_APP_API_URL}/api/auth/signin`,
            method: "POST",
            body: JSON.stringify(login),
        });


    }
)

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
        loginToken: {},
        process: null,
        isLoading: false,
        payload: { error: false }
    },
    reducers: {
        loginBytoken: (state) => {
            let token =localStorage.getItem(ACCESS_TOKEN)
            state.user = {accessToken:token}
        },
        logout: (state) => {
            state.user = null;
            localStorage.removeItem(ACCESS_TOKEN);
        },
    },
    extraReducers: {
        [postLogin.pending]: (state, action) => {
            state.process = 'loading'
            state.isLoading = true
            state.payload = { error: false }
        },
        [postLogin.fulfilled]: (state, { payload }) => {
            state.payload = payload
            state.loginToken = !payload.error ? payload : {}
            state.process = 'success'
            state.isLoading = false

            state.loginToken.accessToken && localStorage.setItem(ACCESS_TOKEN, payload.accessToken);
            state.user = state.loginToken.userPrincipal &&  state.loginToken.userPrincipal
        },
        [postLogin.rejected]: (state, action) => {
            state.process = 'failed'
            state.isLoading = false
            state.payload = { error: true }

        },
    },

});

export const {  logout, loginBytoken  } = userSlice.actions;

export const selectUser = (state) => state.user.user;

export const selectLoginApiStatus = (state) => state.user.process

export const selectPayload = (state) => state.user.payload

export const selectLoading = (state) => state.user.isLoading

export default userSlice.reducer;