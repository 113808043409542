import React  from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './app/config/themaConfig'

import { Dashboard } from './dashboard';
import { useSelector } from 'react-redux';
import { selectUserPhone } from './user/domain/userPhoneSlice';
import { UserAdmin, IndexLoginPage } from './user';
import PrestapolisPublic from './prestapolis/route';
import { Menus as menuPrestapolis } from './prestapolis/menu/menuJson'
import withAuthentication from './user/componet/session/withAuthentication';
import { Prestapolis } from './prestapolis';
import {CHANGE_PASS, FAVICON} from '../src/app/api-client/constants'
import { Helmet } from 'react-helmet';

function App() {

  const isUserChange = localStorage.getItem(CHANGE_PASS)
  const favicon = localStorage.getItem(FAVICON)
  const user = useSelector(selectUserPhone);
  const menu = menuPrestapolis();

  const isUser = isUserChange === "isChange"? false:user;
  const faviconUrl = `https://${process.env.REACT_APP_AWS_BUCKET}/${favicon}`;
  
  return (
    
    <ThemeProvider theme={theme}>
      {isUser ?
        <Dashboard
          menu={menu}
        >
          <Prestapolis />
          <UserAdmin />
        </Dashboard> :
        <PrestapolisPublic />
      }
       <div>
      <Helmet>
        <link rel="icon" type="image/png" href={faviconUrl} sizes="16x16" />
      </Helmet>
      {/* Resto del contenido */}
    </div>
    </ThemeProvider>


  );
}

export default withAuthentication(App);
