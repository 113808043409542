import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';


const MenuDesktop = ({ menuId, handleProfileMenuOpen, numNotifications }) => {
    return (
        <>
            {/* <IconButton color="inherit">
                <Badge badgeContent={numNotifications} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton> */}
            <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
        </>
    )
};


export default MenuDesktop;



