import { request } from "../../../app/api-client/ApiClient";

/**
 * Metodo para obtener las maquinas asocuadas a un admin
 * @param {Integer} idAdmin 
 */
export function getMachineByAdmin(idAdmin) {

	return request({
		url: `${process.env.REACT_APP_API_URL}/Bascula/central/v1/basculas?hash=${idAdmin}&page=0&size=10&filter=`,
		method: "GET",
	});
}

export function geSolicitudes(dispatch,id, page, size, filter1, filter2 ) {

	return request({
		url: `${process.env.REACT_APP_API_PRESTAPLIS}/v2/quotas/list/comerciobyvendedor/${id}.json?page=${page}&size=${size}&filter=${filter1}&filter2=${filter2}`,
		method: "GET",
	},dispatch);
}


export function getSolicitudesByFilter(dispatch, id, page, size, filter, id_comercio, arrayEstados) {
	let urlServices = `/v2/quotas/list/comerciobyvendedor/${id}?page=${page}&size=${size}&filter=${filter}&filter2=${id_comercio}&filter3=${arrayEstados}`;
																				
	return request({
		url: process.env.REACT_APP_API_PRESTAPLIS + urlServices,
		method: "GET",
	}, dispatch);
}

export function getPresolicitudByFilter(dispatch, id, page, size, filter, subestado) {
	let urlServices = `/v2/quotas/list/seller/substatus/${id}/${subestado}?page=${page}&size=${size}&filter=${filter}`;
																				
	return request({
		url: process.env.REACT_APP_API_PRESTAPLIS + urlServices,
		method: "GET",
	}, dispatch);
}

export function getPresolicitudByRol(dispatch, id, page, size, filter, subestado) {
	let urlServices = `/v2/quotas/list/comercio/status/${id}/${subestado}?page=${page}&size=${size}&filter=${filter}`;																		
	return request({
		url: process.env.REACT_APP_API_PRESTAPLIS + urlServices,
		method: "GET",
	}, dispatch);
}

export function getPresolicitudByRolCommerce(dispatch, id, page, size, filter, subestado) {
	
	let urlServices = `/v2/quotas/list/comercio/status/${id}/${subestado}?page=${page}&size=${size}&filter=${filter}`;																		
	return request({
		url: process.env.REACT_APP_API_PRESTAPLIS + urlServices,
		method: "GET",
	}, dispatch);
}


export function getSolicitudesByDenied(dispatch, id, page, size, filter, filter2,fecha_ini, fecha_fin) {
	let urlServices = `/v2/quotas/list/rechazadas/multifiltros/${id}?page=${page}&size=${size}&filter=${filter}&filter2=${filter2}&filter3=${fecha_ini}&filter4=${fecha_fin}`;
																				
	return request({
		url: process.env.REACT_APP_API_PRESTAPLIS + urlServices,
		method: "GET",
	}, dispatch);
}

export function getReporteRecaudos(dispatch, estado, page,size, id_comercio,id_sucursal,fecha_ini, fecha_fin, filter) {
	let urlServices = `/v1/report/recaudos/${estado}?page=${page}&size=${size}&comercio=${id_comercio}&sucursal=${id_sucursal}&dateStart=${fecha_ini}&dateEnd=${fecha_fin}&filter=${filter}`;
																				
	return request({
		url: process.env.REACT_APP_API_PRESTAPLIS + urlServices,
		method: "GET",
	}, dispatch);
}
export function getSolicitudesByProcess(dispatch, id, page, size, filter, filter2, fecha_ini, fecha_fin) {
	let urlServices = `/v2/credits/list/multifiltros/${id}?page=${page}&size=${size}&filter=${filter}&filter2=${filter2}&filter3=${fecha_ini}&filter4=${fecha_fin}`;
																				
	return request({
		url: process.env.REACT_APP_API_PRESTAPLIS + urlServices,
		method: "GET",
	}, dispatch);
}


export function getSolicitudesByMulti(dispatch, id, page, size, id_sucursal, id_vendedor, estado, fecha_ini, fecha_fin) {
	let urlServices = `/v2/quotas/list/multifiltros/${id}?page=${page}&size=${size}&filter=${id_sucursal}&filter2=${id_vendedor}&filter3=${estado}&filter4=${fecha_ini}&filter5=${fecha_fin}`;
																				
	return request({
		url: process.env.REACT_APP_API_PRESTAPLIS + urlServices,
		method: "GET",
	}, dispatch);
}


export function getSucursalByIdCommerce(dispatch, id, page, size, filter) {
	
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/branch/${id}?page=${page}&size=${size}&fil=${filter}`;
	return request({
		url: url,
		method: "GET",
	},dispatch);
}

export function getUsersByIdBranch(dispatch, id, page, size, filter) {

	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/UserCommerce/searchByBranch/${id}?page=${page}&size=${size}&filter=${filter}`;
	return request({
		url: url,
		method: "GET",
	}, dispatch);
}



export function getPaymentByCredic(dispatch, cedula, id_comercio) {

	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v2/credits/creditsByDocumentAndCommerce/${cedula}?size=10&commerce=${id_comercio}`;
	return request({
		url: url,
		method: "GET",
	}, dispatch);
}

export function getProductsByCommerceId(dispatch, id, filter) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/catalogo/${id}?filter=${filter}`;
	return request({
	  url: url,
	  method: "GET",
	}, dispatch);
  }

export function getOptCommerceCredict(dispatch, celular, credito, monto, comercio, cliente, isforward) {

	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/PaymentByCredit/getOptCommerce/${celular}/${credito}/${monto}/${comercio}/${cliente}/${isforward}`;
	return request({
		url: url,
		method: "GET",
	}, dispatch);
}

export function getOptCommerceCredictSMS(dispatch, celular, credito, monto, comercio, cliente, isforward) {

	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/PaymentByCredit/getOptCommerce/${celular}/${credito}/${monto}/${comercio}/${cliente}/${isforward}`;
	return request({
		url: url,
		method: "GET",
	}, dispatch);
}

export function calloptcommerce(dispatch,Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/commerces/calloptcommerce`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	},dispatch);
}

export function reporteRecudo(dispatch,Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/report/recaudo/excel`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	},dispatch);
}

export function reenviarMensaje(dispatch,Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v2/quotas/reenviotoken`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	},dispatch);
}


export function getpaymentcommerce(dispatch,Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v2/credits/paymentcommerce`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	},dispatch);
}

export function getFileToDownload(file) {
	let url = process.env.REACT_APP_FILES + `/download/link?file=${file}`;
	return request({
		url: url,
		method: "GET",
	});
}

//Departamentos
export function getZonesList(dispatch) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/locations/states`;
	return request({
		url: url,
		method: "GET",
	},dispatch);
}

//Municipio
export function getCitiesList(dispatch,idZone) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/locations/cities/${idZone}`;
	return request({
		url: url,
		method: "GET",
	},dispatch);
}

export function statusRequest(dispatch) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/statusRequest/listar`;
	return request({
		url: url,
		method: "GET",
	},dispatch);
}

export function statusComercio(dispatch) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/commerces/info`;
	return request({
		url: url,
		method: "GET",
	},dispatch);
}

export function getAllCommerce(dispatch, page, size, filter) {

	let urlServices = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/commerces?page=${page}&size=${size}&fil=${filter}`;

	return request({
		url: urlServices,
		method: "GET",
	},dispatch);
}

export function createSucursal(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/branch/create`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	}, dispatch);
}


export function editSucursal(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/branch/actualizar`;
	return request({
		url: url,
		method: "PUT",
		body: JSON.stringify(Request),
	},dispatch);
}


export function sendEmailChangePass(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/UserCommerce/temporalPass`;
	return request({
		url: url,
		method: "PUT",
		body: JSON.stringify(Request),
	},dispatch);
}
// /v1/UserCommerce/temporalPass

export function sendCondeChangePass(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/UserCommerce/changePass`;
	return request({
		url: url,
		method: "PUT",
		body: JSON.stringify(Request),
	},dispatch);
}
// /v1/UserCommerce/changePass

export function saveChangeCalc(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v2/quotas/multiUpdate`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	},dispatch);
}

// http://localhost:8092/v2/quotas/updateMontosFinales


export function asingUser(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v2/quotas/updateVendedor`;
	return request({
		url: url,
		method: "PUT",
		body: JSON.stringify(Request),
	},dispatch);
}

// PUT  http://localhost:8092/v2/quotas/updateVendedor

// /v2/quotas/list/comerciobyvendedor/2.json?page=0&size=10&filter=&filter2=&filter3=

export function getVendedoresSucursal(dispatch, id, page, size, filter) {

	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/vendor/sucursal/${id}.json?page=${page}&size=${size}&filter=${filter}`;
	return request({
		url: url,
		method: "GET",
	},dispatch);
}



export function getVendedoresComercio(dispatch, id, page, size, filter) {

	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/vendor/comercio/${id}.json?page=${page}&size=${size}&filter=${filter}`;
	return request({
		url: url,
		method: "GET",
	},dispatch);
}

export function getUsersComerce(dispatch, id, page, size, filter) {

	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/UserCommerce/searchByComerce/${id}.json?page=${page}&size=${size}&filter=${filter}`;
	return request({
		url: url,
		method: "GET",
	},dispatch);
}




export function updateVendedores(dispatch,Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/UserCommerce/update`;
	return request({
		url: url,
		method: "PUT",
		body: JSON.stringify(Request),
	},dispatch);
}

export function geSolicitudesById(dispatch, id) {

	return request({
		url: `${process.env.REACT_APP_API_PRESTAPLIS}/v1/quotas/solicitudes/${id}?page=0&size=20&filter=&filter2=&filter3=`,
		method: "GET",
	}, dispatch);
}

export function geListSucuralById(dispatch, listId) {

	return request({
		url: `${process.env.REACT_APP_API_PRESTAPLIS}/v1/branch/search/list/${listId}`,
		method: "GET",
	}, dispatch);
}

export function getSucursalByIdCommer(dispatch, id, page, size, filter) {
	return request({
		url: `${process.env.REACT_APP_API_PRESTAPLIS}/v1/branch/${id}?page=${page}&size=${size}&fil=${filter}`,
		method: "GET",
	}, dispatch);
}

export function updateVendedor(dispatch, Request) {
	return request(
		{
			url: `${process.env.REACT_APP_API_PRESTAPLIS}/v2/quotas/updateVendedor`,
			method: "PUT",
			body: JSON.stringify(Request),
		},dispatch,"0",true);
}

export function createVendedor(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/vendor/create`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	}, dispatch);
}


export function createNewPreSolicitud(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v2/quotas/preSolicitud`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	}, dispatch);
}


export function createUserVendedor(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/UserCommerce/create`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	}, dispatch);
}


export function updateToken(dispatch, Request) {
	return request(
		{
			url: `${process.env.REACT_APP_API_URL}/api/auth/token`,
			method: "PUT",
			body: JSON.stringify(Request),
		},dispatch,);
}

export function updateSucursalUser(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/UserCommerce/update/branchs`;
	return request({
		url: url,
		method: "PUT",
		body: JSON.stringify(Request),
	}, dispatch);
}

export function updateRolUser(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/UserCommerce/update/rol`;
	return request({
		url: url,
		method: "PUT",
		body: JSON.stringify(Request),
	},dispatch);
}


export function getParameters(dispatch, page, size, filter) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/param?page=${page}&size=${size}&filter=${filter}`;
	return request({
		url: url,
		method: "GET",
	},dispatch);
}


export function getCommerceByName(dispatch, commerce) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/commerces/${commerce}`;
	return request({
		url: url,
		method: "GET",
	},dispatch);
}

export function getSucursalData(dispatch,id) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/vendor/vendedor/user/${id}`;
	return request({
		url: url,
		method: "GET",
	},dispatch);
}

export function getSucursalDataByIdUser(dispatch,id) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v1/UserCommerce/list/idUserAuth/${id}`;
	return request({
		url: url,
		method: "GET",
	},dispatch);
}

// /v1/UserCommerce/list/idUserAuth/{id}

export function sentCellPhone(Request){
	return request(
		{
			url: `${process.env.REACT_APP_API_PRESTAPLIS}/v1/api/client/newLoginvendedor`,
			method: "POST",
			body: JSON.stringify(Request),
		}
	);
}

export function sentCallCellphone(Request){
	return request(
		{
			url: `${process.env.REACT_APP_API_PRESTAPLIS}/v1/api/client/callLoginVendedor`,
			method: "POST",
			body: JSON.stringify(Request),
		}
	);
}


export function sentChangePassword(Request){
	return request(
		{
			url: `${process.env.REACT_APP_API_URL}/api/auth/changePassword`,
			method: "POST",
			body: JSON.stringify(Request),
		}
	);
}

// https://stg-authentication.prestapolis.com/api/auth/changePassword

export function changeNewPass(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_URL}/api/auth/newChangePassword`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	}, dispatch);
}

// POST http://localhost:5090/api/auth/newChangePassword




export function sentRechazo(dispatch, Request, idSolicitud){
	return request({
			url: `${process.env.REACT_APP_API_PRESTAPLIS}/v1/vendor/ReturnState/${idSolicitud}/4`,
			method: "POST",
			body: JSON.stringify(Request),

	},dispatch);
}

export function generatePLans(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v3/quotas/calculatePlans`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	}, dispatch);
}

export function generatePLansNewComerce(dispatch, Request, idInteres) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v3/quotas/calculatePlansV4/${idInteres}`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	}, dispatch);
}

export function preaprovedSolicitud(dispatch, idSolicitud) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v2/quotas/preaproved/${idSolicitud}`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	}, dispatch);
}

export function multiUpdate(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v2/quotas/multiUpdate`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	}, dispatch);
}

// /v2/quotas/multiUpdate

export function cambiarEstado(dispatch, id, tipo) {
	return request(
		{
			url: `${process.env.REACT_APP_API_PRESTAPLIS}/v2/quotas/tiposolicitud/${id}/${tipo}`,
			method: "PUT",
			body: JSON.stringify(Request),
		},
		"0",
		true
	);
} 

//Servicio nuevo sabado
// https://stg-authentication.prestapolis.com/Prestapolis/v2/quotas/preaproved/8814


export function autSingin(Request){
	return request(
		{
			url: `${process.env.REACT_APP_API_URL}/api/auth/client/login`,
			method: "POST",
			body: JSON.stringify(Request),

		}
	);
}

export function aprobSolicitud(dispatch, idSolicitud, idState) {
	let url = `${process.env.REACT_APP_API_PRESTAPLIS}/v2/quotas/changeState/${idSolicitud}/${idState}`;
	return request({
		url: url,
		method: "GET",
	}, dispatch);
}
//Servicio  existente sabado
// https://stg-authentication.prestapolis.com/Prestapolis/v1/quotas/changeState/8814/4

export function aprovateSolicitud(dispatch, Request) {
	return request(
		{
			url: `${process.env.REACT_APP_API_PRESTAPLIS}/v2/quotas/setFactura`,
			method: "PUT",
			body: JSON.stringify(Request),
		},dispatch,"0",true);
}







