import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import {
Modal,
Backdrop,
Fade,
} from '@material-ui/core';

//My style
const useStyles = makeStyles((theme) => ({
    modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    },
    paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #2c8aaf',
    width: 400,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 4, 3),
    },    
    seeMore: {
    marginTop: theme.spacing(3),
    },
    div:{
        margin: '0 auto',
        minWidth: '40%',
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        overflow: 'auto',
        margin: '0 auto',
        flexDirection: 'column',
    },
    paperPhoto: {
        padding: theme.spacing(0),
        display: 'flex',
        margin: '0 auto',
        maxWidth: '500px',
        overflow: 'auto',
        flexDirection: 'column',
    },
    btnFactura:{
        margin: '0px !important',
        textAlign: 'center',
        justifycontent: 'center'
    },
    button:{
        color: '#ff0000 !important',
    },
    img:{
        maxWidth: '1000px',
    },
    inputPerson:{
        marginTop: '15px'
    },
}));

export  default function ModalAlertInfoPaymentCcampos({isOpen, setopenMondal, onSubmit}) {
  const classes = useStyles();
  const ExitModal = () =>{
    setopenMondal(false);
}


    return (
 
  <div>
        <Modal
          open={isOpen}
          onClose={(e)=>{ 
            ExitModal()
          }}
          className={classes.modal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isOpen}>

    <div className={classes.root}>
      <Alert severity="warning" onClose={(e) => {setopenMondal(false)}}>
      <AlertTitle>Alerta</AlertTitle>Digita tu número de celular — <strong>campo requerido!</strong>
      </Alert>
    </div>
  
          </Fade>
        </Modal>
      </div>
    );
   
  
      
}
