import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    IconButton,
    Slide,
    makeStyles, TextField, CircularProgress,
} from '@material-ui/core';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

//My style
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperModal: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #2c8aaf',
        width: 450,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 4, 5),
    },
    seeMore: {
        marginTop: theme.spacing(3),
    },
    div: {
        margin: '0 auto',
        minWidth: '40%',
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        overflow: 'auto',
        margin: '0 auto',
        flexDirection: 'column',
    },
    paperPhoto: {
        padding: theme.spacing(0),
        display: 'flex',
        margin: '0 auto',
        maxWidth: '500px',
        overflow: 'auto',
        flexDirection: 'column',
    },
    btnFactura: {
        margin: '0px !important',
        textAlign: 'center',
        justifycontent: 'center'
    },
    button: {
        color: '#ff0000 !important',
    },
    img: {
        maxWidth: '1000px',
    },
    inputPerson: {
        marginTop: '15px',
        fontSize: "18px"
    },
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    errorLabel: {
        color: "red",
        padding: 0,
        paddingLeft: 34,
        paddingTop: 5
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(2),
        position: 'relative',
        // paddingBottom: 20

    },
}));

const DialogTitle = ((props) => {
    const classes = useStyles();
    const { children, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{ textAlign: "center" }}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export const ModalInput = ({
    handledtClose,
    isOpen,
    text,
    handledAccept,
    title,
    input_label,
    inpunt_value,
    setInput_value,
    typeInput,
    isAlert,
    messageAlert,
    typeAlert,
    disBtnContinue,
    setdisBtnContinue
}) => {


    const [error_email, setError_email] = useState(false)
    // const [disBtnContinue, setdisBtnContinue] = useState(true)
    const classes = useStyles();

    const FormatEmail = (email) => {

        const expr = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;
        return expr.test(email);

    };

    return (

        <Dialog
            onClose={() => handledtClose(false)}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            style={{ maxHeight: "45%" }}
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle id="simple-dialog-title" onClose={(e) => { handledtClose(false) }}>{title}</DialogTitle>
            <DialogContent>
                <Typography variant="body1" >
                    {text}
                </Typography>
                {typeInput === "email" &&
                    < TextField
                        style={{ marginTop: "10px" }}
                        id="input"
                        label={input_label}
                        value={inpunt_value}
                        onChange={(e) => {
                            if (!FormatEmail(e.target.value)) {
                                setError_email(true)
                                setInput_value(e.target.value)
                                setdisBtnContinue(true);
                            } else {
                                setInput_value(e.target.value)
                                setdisBtnContinue(false);
                                setError_email(false)
                            }
                        }
                        }
                        fullWidth
                    />

                }
                {error_email && <div style={{ color: "red", fontSize: "15px", textAlign: "left", paddingTop: "2px" }}>Por favor ingrese un correo electrónico valido</div>}
            </DialogContent>
            {isAlert && <div style={{ color: `${typeAlert}`, marginLeft: "25px", fontSize: "16px", textAlign: "left" }}>{messageAlert}</div>}
            <DialogActions style={{ padding: 25 }}>
                <div className={classes.wrapper}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", width: "inherit" }}
                        onClick={handledAccept}
                        disabled={disBtnContinue}
                    >
                        Continuar
                    </Button>
                    {disBtnContinue && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
        </Dialog>
    );
}