import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import IndexLoginPage from "../user/componet/Singin/IndexSigin"
import Sigin from '../user/componet/Singin/Singin';
import { ChangePass } from '../user/componet/Singin/changePass';
import { HomeSigin } from '../user/componet/Singin/CardSigin';
import { ChangePassAdmin } from '../user/componet/Singin/ChangePassAdmin';



function PrestapolisPublic({ }) {



    return (
        <Router>
            <Switch>

                <Route
                    exact
                    path={`/login`}
                    component={Sigin}

                />

                <Route
                    exact
                    path={`/changePass`}
                    component={ChangePass}

                />

                <Route
                    exact
                    path={`/changePassAdmin/:mail`}
                    component={ChangePassAdmin}
                />

                <Route
                    exact
                    path={`/celphone`}
                    component={IndexLoginPage}
                />

                <Route
                    exact
                    path={`/`}
                    component={HomeSigin}
                />


            </Switch>


        </Router>
    )

}


export default PrestapolisPublic;