
//React 
import React from 'react';

//Icons Material
import {
  AccountBox,
  Work,
  Clear,
} from '@material-ui/icons';

//Components Material
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Modal,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  Backdrop,
  Fade,
} from '@material-ui/core';


//My style
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #ff670080',
    width: 400,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 4, 3),
  },
}));

export  default function ModalContac({isOpen, setopenMondal,  dataContac}) {

  const classes = useStyles();

   let contactos = []
   contactos = dataContac.contactos === undefined? []: dataContac.contactos;

   const {
    nombrecompleto,
    documentNumber,
   } = dataContac

   const cellphone = contactos.length === 0? "": contactos[0].cellphone;
   const email = contactos.length === 0? "": contactos[0].email;
   
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={(e)=>{ setopenMondal(false)}}
        className={classes.modal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>

          <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="nowrap"
                
              >
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    alignContent="stretch"
                    wrap="nowrap"
                    
                  >
                    <Grid xs={4}></Grid>
                    <Grid 
                      item xs={7}
                      direction="row"
                      justify="center"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"

                      >
                    <h2 id="transition-modal-title">Contactos</h2>
                    </Grid>
                    <Grid item xs ={1} textAlign="Right">
                        <IconButton 
                        color="error" 
                        aria-label="upload picture"
                        component="span"
                        onClick={((e)=>{setopenMondal(false)})}
                        >
                        <Clear/>
                      </IconButton>
                    </Grid>
                </Grid>
              </Grid>
              <br/>






            <Grid container spacing={1} alignItems="flex-end" textAlign="center">
                <Grid item md={true} sm={true} xs={true} >

                  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <AccountBox/>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Nombre" secondary={`${nombrecompleto}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <AccountBox />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Cedula" secondary={`${documentNumber}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                         <Work/> 
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Telefono/Celular" secondary={`${cellphone}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <Work />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Email" secondary={`${email}`} />
                    </ListItem>
                  </List>
                </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}