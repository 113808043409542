//React 
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
import ModalSelect from '../modals/modalSelect';

//Redux
import { ID_USER, ID_SUCURSAL, ID_COMERCIO, NAME_COMERCIO, BAAS } from "../../app/api-client/constants";
import { useDispatch } from 'react-redux';

//Icons Material
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

//Components Material
import { makeStyles } from '@material-ui/core/styles';
import SearchBar from "material-ui-search-bar";
import {
    Paper,
    Grid,
    Button,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    TableContainer,
} from '@material-ui/core';

//Local Component
import Paginator from '../utils/paginator';
import Title from '../../app/orussystem/component/Title';
import MigaPan from '../../app/orussystem/component/MigaPan';
import {
    getVendedoresSucursal,
    updateVendedores,
    createVendedor,
    getSucursalByIdCommerce,
    getVendedoresComercio,
    createUserVendedor,
    updateSucursalUser,
    getUsersComerce,
    updateRolUser
} from '../domain/api/PrestapolisApi'
import ModalVendedorComercio from '../modals/modalVendedorComercio'
import ModalVendedorEditComercio from '../modals/modalVendedorEditComercio'
import { ModalTowButton } from '../modals/modalTowButton'





const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        paddingTop: 10,
        display: "flex",

    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center",
    }
}));

const listRol = [
    {
        id: 3,
        nombre: "Vendedor",
        name: "ROLE_SELLER",
    },
    {
        id: 1,
        nombre: "Admin Comercio",
        name: "ROLE_COMMERCE_ADMIN",
    },
    {
        id: 2,
        nombre: "Admin Sucursal",
        name: "ROLE_BRANCH_ADMIN",
    },
]


export const ListaVendedoresComercio = ({ }) => {

    const classes = useStyles();
    const [isLoadUser, setisLoadUser] = useState(false)
    const [data, setData] = useState([])
    const [totalElements, settotalElements] = useState(0)
    const [totalPage, settotalPage] = useState(0)
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(10)
    const [filter, setFilter] = useState("")
    const [isOpenModalEdit, setisOpenModalEdit] = useState(false)
    const [isOpenModalCreate, setisOpenModalCreate] = useState(false)
    const [dataEdit, setdataEdit] = useState({})
    const [dataSucursal, setdataSucursal] = useState([]);

    //Modal Rol
    const [openModalRol, setopenModalRol] = useState(false);
    const [rol_value, setrol_value] = useState("");


    //Modal create user comerce
    const [selectListSucursal, setSelectListSucursal] = useState("");
    const [listSucursalSelect, setlistSucursalSelect] = useState("")

    //Modal select sucursal
    const [openModalSucursal, setopenModalSucursal] = useState(false);
    const [arrayValueSucural, setArrayValueSucural] = useState("")
    const [listAsingSucursal, setlistAsingSucursal] = useState("")
    const [idUserAsing, setIdUserAsing] = useState("")

    //Modal Info 
    const [isOpenModalInfo, setisOpenModalInfo] = useState(false)
    const [titleInfo, settitleInfo] = useState("")
    const [bodyInfo, setbodyInfo] = useState("")


    let { id } = useParams();
    let idSucursal = id != ":id" && id != null && id != undefined ? id : localStorage.getItem(ID_SUCURSAL);
    let idComercio = localStorage.getItem(ID_COMERCIO);

    let nameComercio = localStorage.getItem(NAME_COMERCIO)
    let baas = localStorage.getItem(BAAS)
    let namecomerce = baas != "true" ? 'Home' : nameComercio

    const history = useHistory();
    const dispatch = useDispatch();

    const getVendedor = (id, page, size, filter) => {
        setisLoadUser(true);
        getUsersComerce(dispatch, id, page, size, filter)
            .then((response) => {
                if (response.code === "0000") {
                    let totalPages = response.totalPages;
                    let totalElements = response.totalElements;
                    let data = response.content;
                    setData(data);
                    settotalElements(totalElements);
                    settotalPage(totalPages);

                    setisLoadUser(false);
                } else {
                   
                    setisLoadUser(false)
                }
            })
            .catch((error) => {
                
                if (error.status === 401) {

                }
            });
    };

    const getSucursal = (id, page, size, filter) => {
    
        getSucursalByIdCommerce(dispatch, id, page, size, filter)
            .then((response) => {
                if (response.code === "0000") {
                    let data = response.content;
                    let lista = [];
                    data.map((item) => {
                        lista.push({
                            id: item.id,
                            value: item.id,
                            label: item.nombre,
                        });
                    });
                    setdataSucursal(lista)
                    
                }
            }).catch((err) => {
                
                if (err.status === 401) {

                }
            })
    }

    const SetDataAsync = async (setState, item) => {
        return new Promise((resolve, reject) => {
            resolve(setState(item));
        })
    }

    const EditVendor = async (data) => {
        setdataEdit({});
        await SetDataAsync(setdataEdit, data).then((response) => {
            setisOpenModalEdit(true)
        })
    }

    //Metodos para editar vendedor

    const onSubmiEdit = (data) => {

        let request = {

            vendedore: {
                id: dataEdit.id,
                nombrescompletos: data.names,
                celular: data.cellPhone,
                email: data.email,
                numerodocumento: data.idNumber,
                comercio: idComercio,
                primernombres: data.names,
                segundonombre: "",
                apellido: data.apellidos,
                tipoDocumento: "CC",
                estado: data.estado,
                cargo: data.cargo,
            },
            usuariosComercio: {
                id: idComercio,
            },
            sucursal: {
                id: dataEdit.sucursale.id
            },

        }

        updateVendedor(request)
    }

    //Metodo para crear vendedores
    const onSubmiCreate = (data) => {


        let nameRol = ""
        let nameCargo =''

        listRol.map((item) => {
            if (item.id === parseInt(data.rol)) {
                nameRol = item.name;
                nameCargo = item.nombre;
            }
        })

        let request = {
            usuariosComercio: {
                nombreCompleto: data.names + " " + data.apellidos,
                celular: data.cellPhone,
                email: data.email,
                numerocedula: data.idNumber,
                comercio: {
                    id: idComercio
                },
                sucursales: listSucursalSelect.replaceAll(",", "-").replaceAll(",", "-"),
                primerNombre: data.names,
                segundoNombre: "",
                apellidos: data.apellidos,
                tipoDocumento: "CC",
                estado: "A",
                rol: {
                    id: parseInt(data.rol),
                    rol: nameRol
                },
                cargo: nameCargo
            },
            user: {
                username: data.names,
                name: data.names,
                email: data.email,
                password: data.idNumber,
                rol: nameRol,
                celular: data.cellPhone,
                numerodocumento: data.idNumber,
                comercio: idComercio,
                primernombres: data.names,
                segundonombre: "",
                apellido: data.apellidos,
                tipoDocumento: "CC"
            },
        }
      
        CreateVendor(request)
    }

    const CreateVendor = (data) => {
        createUserVendedor(dispatch, data)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    store.addNotification({
                        title: "Exito!",
                        message: "Vendedor creado con exito",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeOut"],
                        animationOut: ["animate__animated", "animate__fadeIn"],
                        dismiss: {
                            duration: 3000,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        setisOpenModalCreate(false)
                        getVendedor(idComercio, 0, size, "")
                    }, 2500);

                } else {
                    store.addNotification({
                        title: "Error",
                        message: response.mensaje,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3500,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        // setisOpenModalCreate(false)
                        // setisOkay(true);
                        getVendedor(idComercio, 0, size, "")
                    }, 3500);
                }
            })
            .catch((err) => {
              
            });
    }


    const updateVendedor = (recuest) => {
        updateVendedores(dispatch, recuest)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    store.addNotification({
                        title: "Exito!",
                        message: "Se han editado los datos satisfctoriamente",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeOut"],
                        animationOut: ["animate__animated", "animate__fadeIn"],
                        dismiss: {
                            duration: 3500,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        setisOpenModalCreate(false)
                        getVendedor(idComercio, 0, size, "")
                    }, 1500);
                } else {
                    store.addNotification({
                        title: "Error",
                        message: "Error al editar el vendedor revise los datos",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3500,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        setisOpenModalCreate(false)
                        getVendedor(idComercio, 0, size, "")
                    }, 1500);
                }
            })
            .catch((err) => {
                store.addNotification({
                    title: "Error",
                    message: err,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3500,
                        // onScreen: true
                    }
                });
                setisOpenModalCreate(false)
            });
    }

    const filtrar = (e) => {
        if (e === "") {
            getVendedor(idComercio, page, size, filter)
        } else {
            getVendedor(idComercio, page, size, e)
        }
    }

    //Onchange crear usuario comercio 
    function onChangeUserComerce(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {

            let idString = []
            dataSucursal.map((item) => {
                idString.push(
                    item.id
                )
            })
            idString = idString.toString();
            setlistSucursalSelect(idString)
            this.setState(this.options);

        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            setlistSucursalSelect("")
            this.setState([]);
        } else if (event.action === "deselect-option") {

            let idString = []
            let arrayFilter = value.filter((o) => o.value !== "*")
            arrayFilter.map((item) => {
                idString.push(
                    item.id
                )
            })
            idString = idString.toString();
            setlistSucursalSelect(idString)
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {

            let select = [];
            let stringSelect = ""
            value.map((item) => {
                select.push(
                    item.id
                )
            })

            stringSelect = select.toString();
            setlistSucursalSelect(stringSelect)
            this.setState(value);
        }
    }

    //Onchange asignar sucuaral 
    function onChangeAsingSucursal(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
          
            let idString = []
            dataSucursal.map((item) => {
                idString.push(
                    item.id
                )
            })
            idString = idString.toString();
            setlistAsingSucursal(idString)
            this.setState(this.options);

        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
         
            setlistAsingSucursal("")
            this.setState([]);
        } else if (event.action === "deselect-option") {
           
            let idString = []
            let arrayFilter = value.filter((o) => o.value !== "*")
            arrayFilter.map((item) => {
                idString.push(
                    item.id
                )
            })
            idString = idString.toString();
            setlistAsingSucursal(idString)
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            let idString = []
            dataSucursal.map((item) => {
                idString.push(
                    item.id
                )
            })
            idString = idString.toString();
            setlistAsingSucursal(idString)
            this.setState(this.options);
        } else {
            let select = [];
            let stringSelect = ""
            value.map((item) => {
                select.push(
                    item.id
                )
            })

            stringSelect = select.toString();
            setlistAsingSucursal(stringSelect)
            this.setState(value);
        }
    }

    //actualizar sucural de usuario
    const updateSucursal = () => {

        let data = {
            id: idUserAsing,
            sucursales: listAsingSucursal.replaceAll(",", "-").replaceAll(",", "-"),
        }
        updateSucursalUser(dispatch, data)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    store.addNotification({
                        title: "Exito!",
                        message: "Se han asignado las sucursales con exito",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeOut"],
                        animationOut: ["animate__animated", "animate__fadeIn"],
                        dismiss: {
                            duration: 3500,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        setopenModalSucursal(false)
                        getVendedor(idComercio, 0, size, "")
                        setArrayValueSucural([])
                        setlistAsingSucursal("")

                    }, 1500);
                } else {
                    store.addNotification({
                        title: "Error",
                        message: response.mensaje != null ? response.mensaje : "No se han podido asignar las sucursales",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3500,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        setopenModalSucursal(false)
                        getVendedor(idComercio, 0, size, "")
                        setArrayValueSucural([])
                        setlistAsingSucursal("")
                    }, 1500);
                }
            })
            .catch((err) => {
                store.addNotification({
                    title: "Error",
                    message: err,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3500,
                        // onScreen: true
                    }
                });
                setopenModalSucursal(false)
                setArrayValueSucural([])
                setlistAsingSucursal("")
            })
    }


    //Actualizar rol de usuario
    const updateRol = () => {

        let rolAsing = ""
        listRol.map((item) => {
            if (item.id === parseInt(rol_value)) {
                rolAsing = item.name;
            }
        })

        const data = {
            id: idUserAsing,
            rol: {
                rol: rolAsing
            }
        }
        updateRolUser(dispatch, data)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    store.addNotification({
                        title: "¡Exito!",
                        message: "¡Se asigno el rol correctamente!",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3500,
                            // onScreen: true
                        }
                    });
                    setopenModalRol(false)
                    setrol_value("")
                    setisOpenModalInfo(false)
                    getVendedor(idComercio, 0, size, "")

                } else {
                    store.addNotification({
                        title: "Error",
                        message: "¡No se pudo asignar el rol correctamente!",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3500,
                            // onScreen: true
                        }
                    });
                    setopenModalRol(false)
                    setrol_value("")
                    setisOpenModalInfo(false)
                    getVendedor(idComercio, 0, size, "")
                }
            })
            .catch((err) => {
                store.addNotification({
                    title: "Error",
                    message: err,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3500,
                        // onScreen: true
                    }
                });
                setopenModalRol(false)
                setrol_value("")
                setisOpenModalInfo(false)
                getVendedor(idComercio, 0, size, "")
            })
    }

    //Advertencia al cambiar rol
    const warningUpdateRol = () => {
        setopenModalRol(false)
        let msj = ""
        switch (rol_value) {
            case 3:
                msj = "Recuerde que este usuario solo podrá ingresar al las solicitudes del comercio."
                break;

            case 1:
                msj = "Recuerde que este usuario tendrá acceso a todas las sucursales."
                break;

            case 2:
                msj = "Recuerde que este usuario solo podrá ingresar al las sucursales asignadas."
                break;

            default:
                break;
        }
        setbodyInfo(msj)
        settitleInfo("¿Esta seguro de cambir el rol?")
        setisOpenModalInfo(true)
    }

    //close modal info
    const handledModalInfo = (state) => {
        setisOpenModalInfo(state);
        setrol_value("");
    }



    const setChangeListSucursal = (data) => {


        let arraySucursal = data.sucursales.replaceAll("-", ",").replaceAll("-", ",");
        setlistAsingSucursal(arraySucursal)
        arraySucursal = JSON.parse(`[${arraySucursal}]`)
      

        let newSucursal = []
        dataSucursal.map((item) => {
            arraySucursal.map((itemS) => {
                if (itemS === item.id) {
                    newSucursal.push(
                        item
                    )
                }
            })

        })
        setArrayValueSucural(newSucursal);
    }

    //Open modal asignar sucursal +
    const openModalSucursalAsing = (data) => {
        setIdUserAsing(data.id)
        setopenModalSucursal(true)
        setChangeListSucursal(data);
    }

    //Open modal asignar rol +
    const openModalAsingRol = (data) => {
        setIdUserAsing(data.id)
        setopenModalRol(true)
    }

    //limpiar onchageMulti

    const clearAsingSucursal = () => {
        setArrayValueSucural([])
        setlistAsingSucursal("")
    }

    const handledRol = (rol) => {
        switch (rol) {
            case "ROLE_SELLER":
                return "Vendedor"
            case "ROLE_COMMERCE_ADMIN":
                return "Admin Comercio"
            case "ROLE_BRANCH_ADMIN":
                return "Admin Sucursal"
            default:
                break;
        }
    }



    useEffect(async () => {
        getVendedor(idComercio, page, size, filter)
        getSucursal(idComercio, 0, 1000, "")
    
    }, [page], []);

    return (
        <>
            <ModalTowButton
                handledtClose={handledModalInfo}
                isOpen={isOpenModalInfo}
                text={bodyInfo}
                handledAccept={updateRol}
                title={titleInfo}
            />
            <ModalSelect
                isOpen={openModalRol}
                setopenMondal={setopenModalRol}
                name="Rol"
                list={listRol}
                value={rol_value}
                setValue={setrol_value}
                clear={() => { }}
                handledClick={warningUpdateRol}
            />
            <ModalSelect
                isOpen={openModalSucursal}
                setopenMondal={setopenModalSucursal}
                name="Sucursales"
                isMulti={true}
                list={dataSucursal}
                valueMulti={arrayValueSucural}
                onchangeMulti={onChangeAsingSucursal}
                setValueMulti={setArrayValueSucural}
                handledClick={updateSucursal}
                clear={clearAsingSucursal}

            />

            {isOpenModalEdit && <ModalVendedorEditComercio
                isOpen={isOpenModalEdit}
                setopenMondal={setisOpenModalEdit}
                onSubmit={onSubmiEdit}
                data={dataEdit}
                setData={setdataEdit}
            />}


            <ModalVendedorComercio
                isOpen={isOpenModalCreate}
                setopenMondal={setisOpenModalCreate}
                onSubmit={onSubmiCreate}
                dataSucursal={dataSucursal}
                listSelectSucursal={selectListSucursal}
                setlistSelectSucursal={setSelectListSucursal}
                onChange={onChangeUserComerce}

            >
            </ModalVendedorComercio>
            <Grid item xs={12} >
                <MigaPan namePage="Usuarios" to="/" >
                {namecomerce}
                </MigaPan>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={3} sm={3} md={3} style={{ textAlign: "right" }}>

                </Grid>
                <Grid item xs={12} sm={3} md={3} style={{ textAlign: 'right' }}>
                    <IconButton
                        size="large"
                        color="Primary"
                        onClick={(e) => { getVendedor(idComercio, 0, size, "") }}
                    >
                        <RotateLeftIcon />
                    </IconButton>

                    <Button
                        variant="contained"
                        style={{color:'#FFFFFF',
                        background:'#2BAEB3'}}
                        size="large"
                        // disabled={row.estadoSolicitud.id != 10? true: false}
                        onClick={(e) => {
                            setisOpenModalCreate(true)
                        }}
                    >
                        Crear Usuario
                    </Button>

                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <SearchBar
                        value={filter}
                        onChange={(e) => filtrar(e)}
                        onCancelSearch={() => filtrar("")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Box
                            m={2}
                            component="span"
                            className={`${classes.spreadBox} ${classes.box}`}
                        >
                            <Title >Usuarios</Title>
                        </Box>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table size="small" >
                                <TableHead>
                                    <TableRow alignItems="center">
                                        <TableCell align="center">Número de documento</TableCell>
                                        <TableCell align="center">Nombre Completo</TableCell>
                                        {/* <TableCell align="center">Cargo</TableCell> */}
                                        <TableCell align="center">Rol</TableCell>
                                        <TableCell align="center" >Estado</TableCell>
                                        <TableCell align="center">Email</TableCell>
                                        <TableCell align="center">Celular</TableCell>
                                        <TableCell align="center">Asignar Rol</TableCell>
                                        <TableCell align="center">Asignar Sucursal</TableCell>
                                        {/* <TableCell align="center">Sucursal</TableCell> */}
                                        <TableCell align="center">Editar</TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoadUser ?
                                        <>
                                            <Grid
                                                container
                                                spacing={1}
                                                direction="row"
                                                justify="center"
                                                alignItems="center"
                                                alignContent="center"
                                                wrap="nowrap"
                                            >
                                                <ReactLoading
                                                    type={"bars"}
                                                    color={"#000"}
                                                    height={50}
                                                    width={50}
                                                />
                                            </Grid>
                                        </>
                                        : data.map((row, key) => (
                                            <TableRow key={key}>
                                                <TableCell align="center">{`${row.numerocedula === undefined || row.numerocedula === null ? "----" : row.numerocedula}`}</TableCell>
                                                <TableCell align="center">{row.nombreCompleto === undefined || row.nombreCompleto === null ? "----" : row.nombreCompleto}</TableCell>
                                                {/* <TableCell align="center">{row.cargo === undefined || row.cargo === null ? "----" : row.cargo}</TableCell> */}
                                                <TableCell align="center">{row.rol.rol === undefined || row.rol.rol === null ? "----" : handledRol(row.rol.rol)}</TableCell>
                                                <TableCell align="center">{row.estado === "A" ? "Activo" : "Inactivo"}</TableCell>
                                                <TableCell align="center">{row.email === undefined || row.email === null ? "----" : row.email}</TableCell>
                                                <TableCell align="center">{row.celular === undefined || row.celular === null ? "----" : row.celular}</TableCell>
                                                {/* <TableCell align="center">{
                                                row.sucursale === undefined || row.sucursale === null
                                                    ? "----"
                                                    : row.sucursale.nombre === undefined || row.sucursale.nombre === null
                                                        ? "----"
                                                        : row.sucursale.nombre
                                            }</TableCell> */}
                                                <TableCell align="center">
                                                    <Button
                                                        variant="contained"
                                                        style={{color:'#FFFFFF',
                                                        background:'#2BAEB3'}}
                                                        // disabled={row.estadoSolicitud.id != 10? true: false}
                                                        onClick={(e) => {
                                                            openModalAsingRol(row)
                                                        }}
                                                    >
                                                        Rol
                                                    </Button>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="contained"
                                                        style={{color:'#FFFFFF',
                                                        background:'#2BAEB3'}}
                                                        disabled={row.rol.rol === "ROLE_COMMERCE_ADMIN" ? true : false}
                                                        onClick={(e) => {
                                                            openModalSucursalAsing(row)
                                                        }}
                                                    >
                                                        Sucursal
                                                    </Button>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="contained"
                                                        style={{color:'#FFFFFF',
                                                        background:'#2BAEB3'}}
                                                        // disabled={row.estadoSolicitud.id != 10? true: false}
                                                        onClick={(e) => {   
                                                            EditVendor(row)
                                                        }}
                                                    >
                                                        Editar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                        >
                            <Paginator
                                totalElement={Math.round(totalElements / size)}
                                setPage={setPage}
                                page={page}

                            />
                        </Grid>
                        <br />

                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
