//React
import React,{useState} from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";

//Icons Material
import {
    PhoneIphone,
} from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

//Component Material
import {
    Paper,
    Grid,
    TextField,
    Button,
    Container, CircularProgress,
    Typography,
    Link
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

//Local Component
import clsx from 'clsx';
import logo from '../img/logo.png';
import { useStyles } from './SinginStyles';
import { HelpValidate } from '../../utils/helpValidate';
import { Copyright } from '../../../dashboard/componet/UI/CopyRight'




export default function SiginCellphon({ setCellphone, isLaodSend, SendSmsCellphone, nextStep, setdataCell, succesCode, errorCode, mensaje, isTrueNext, setisContinue }) {


    const classes = useStyles();
    const history = useHistory();
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const borderRadius = clsx(classes.padding, classes.borderRadius);
    const [continuteStep, setcontinuteStep] = useState(false);



    const onSubmit = (data, type) => {

        const dataCell = {
            phone: `${data.celular}`
        }
       

        if(type.nativeEvent.submitter.id === "continuar"){
            setcontinuteStep(true)
            setisContinue(true)
            setCellphone(data.celular);
            setdataCell(dataCell)
            setTimeout(() => {
                nextStep()
            }, 1500);
        }else{
            setdataCell(dataCell)
            SendSmsCellphone(dataCell, "continue");
            setCellphone(data.celular);
            setisContinue(false)

        }

    }


    const HandledLodingEmail = () => {

        history.push(`/login`)
    }

    const HandledBack = () => {
        history.push(`/`)
    }


    return (


        <div className={classes.centerv} >
            <Container maxWidth="xs" alignitems="center" >
                <Paper className={borderRadius}>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="left"
                        alignItems="left"
                        alignContent="left"
                        wrap="nowrap"

                    >
                        <IconButton onClick={HandledBack}>
                            <ArrowBackIcon />
                        </IconButton>

                    </Grid>
                    <div className={classes.margin}>
                        <Grid container justify="center" style={{ marginTop: '15px', maxwidth: '350px' }}>
                            <img src={logo} width="100%" alt="Logo" />
                        </Grid>
                        <Grid
                            container
                            justify="center"
                            alignItems="flex-start"
                            alignContent="stretch"
                            wrap="nowrap"

                        >
                            <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                Iniciando sesión como vendedor
                            </Typography>
                        </Grid>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '15px' }}>
                                <Grid item>
                                    <PhoneIphone style={{color:'#2BAEB3'}} fontSize="large" />
                                </Grid>

                                <Grid item md={10} sm={10} xs={9} style={{ padding: '32px 0px 0px 0px' }}>
                                    <TextField
                                        placeholder="Celular "
                                        label="Celular"
                                        fullWidth
                                        name="Celular"
                                        type="tel"
                                        {...HelpValidate().limitLengthNumber(register('celular', {
                                            required: "Por favor ingrese su número de celular.",
                                            minLength: {
                                                value: 10,
                                                message: 'El Celular debe tener minimo 10 caracteres'
                                            },
                                        }), 10, 10, setError, clearErrors)}
                                        error={!!errors.celular}
                                        helperText={errors.celular?.message}
                                    />
                                </Grid>

                            </Grid>
                            <div className={classes.wrapper}>
                                <Grid container justify="center" style={{ marginTop: '20px' }}>
                                    <Button
                                        disabled={continuteStep}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        id='continuar'
                                        style={{ textTransform: "none", width: "inherit", background:'#2BAEB3',color:'#FFFFFF' }}
                                        >
                                        Continuar
                                    </Button>
                                </Grid>
                                {continuteStep && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>

                            <div className={classes.wrapper}>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="nowrap"
                                    style={{ marginTop: 20 }}

                                >
                                    <Typography variant="body2" color="#0a699e" align='center'>
                                        <Button
                                            disabled={isLaodSend}
                                            type="submit"
                                            id="obtener_code"
                                            variant='text'
                                            color="primary"
                                            style={{ textTransform: "none", width: "inherit" }}
                                        >
                                            ¡Obtener un nuevo código de verificación!
                                        </Button>
                                    </Typography>
                                </Grid>
                                {isLaodSend && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>

                            {succesCode && <Alert severity="success">El código se envio correctamente</Alert>}
                            {errorCode && <Alert severity="error" style={{ textAlign: "center" }}>{mensaje}</Alert>}


                            <br />
                        </form>
                    </div>
                    <Box >
                        <Copyright />
                    </Box>
                </Paper>
            </Container >
        </div>

    );

}
