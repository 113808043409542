import React, {  useMemo, forwardRef } from 'react';
import { ListItem, ListItemIcon, Icon, ListItemText } from '@material-ui/core';


import { Link as RouterLink } from 'react-router-dom';

import useStyles from './UseStyle';

export const SubMenuItem = ({ label, icon, to, show }) => {

    const classes = useStyles();

    const renderLink = useMemo(
        () => forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    return (
        <>
            {show ?
                < ListItem button component={renderLink} className={classes.nested}>
                    {icon ? <ListItemIcon> <Icon>{icon}</Icon></ListItemIcon> : null}
                    <ListItemText primary={label} />
                </ListItem> : <></>
            }

        </>
    );
}

