import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ID_COMERCIO, ID_SELLER, ID_SUCURSAL, USER_ROL, NAME_COMERCIO } from "../../app/api-client/constants";
import { geListSucuralById, createNewPreSolicitud, getSucursalByIdCommerce,getCommerceByName } from "../domain/api/PrestapolisApi";
import { CalculadoraCreate } from "./steps/calculadoraCreate";
import { DatosUsuario } from "./steps/datosUsuario";
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
import { CODE_COMERCIO } from "../../app/api-client/constants";

export const StartOfCalc = ({ setopenMondal, isCatalogo, catalogos }) => {

    const [step, setStep] = useState(1)
    const [dataBusiness, setdataBusiness] = useState({})
    const [dataPersona, setDataPersona] = useState({})
   
    const [listSucursal, setlistSucursal] = useState([])
    const [comerceData, setComerceData] = useState({});
    let codeComer = localStorage.getItem(CODE_COMERCIO)
    let idSucursal = localStorage.getItem(ID_SUCURSAL);
    let idComerce = localStorage.getItem(ID_COMERCIO);
    let keyComerce = localStorage.getItem(NAME_COMERCIO);
    let idSeller = localStorage.getItem(ID_SELLER);
    let rol = localStorage.getItem(USER_ROL);

    const dispatch = useDispatch();

    const handledNext = (key) => {
        setStep(step + 1)
    }


    const handledBack = (data) => {
        setDataPersona(data)
        setStep(step - 1)
    }

    const getDataComerceByCode = async () => {

        await getCommerceByName(dispatch,localStorage.getItem(CODE_COMERCIO))
             .then((response) => {
                 let data = response.respuesta;
                 if (response.codigoRespuesta === "0000") {
                    
                     setComerceData(data)
                   
                 } else {
                  
                 }
             })
             .catch((err) => {
                
             })
     }
   

    // Sucursal list seller por id del user
    const getSucursalList = () => {

        switch (rol) {
            case "ROLE_COMMERCE_ADMIN":
                
                getSucursalByIdCommerce(dispatch, idComerce, 0, 1000, "")
                    .then((response) => {
                        if (response.code === "0000") {
                            let data = response.content;
                            setlistSucursal(data);
                        } else {
                        }
                    })
                    .catch((error) => {
                     
                        if (error.status === 401) {

                        }
                    });
                break;

            case "ROLE_BRANCH_ADMIN":
                geListSucuralById(dispatch, idSucursal)
                    .then((response) => {
                        if (response.codigoRespuesta === "0000") {
                            setlistSucursal(response.respuesta)
                        }
                    }).catch((err) => {
                        
                    })
                break;

            case "ROLE_SELLER":
                geListSucuralById(dispatch, idSucursal)
                    .then((response) => {
                        if (response.codigoRespuesta === "0000") {
                            setlistSucursal(response.respuesta)
                        }
                    }).catch((err) => {
                       
                    })
                break;

            default:
                break;
        }




    }



    const handledSendForm = (data) => {
        let valor = dataBusiness.valor_prod

        if (typeof valor === "string") {
            valor = valor.includes(".") ? valor.split(".") : valor.split(",");
            valor = valor.join("");
            valor = parseInt(valor, 10);
        }

        
        let abono = dataBusiness.abono_prod;
  
        if (typeof abono === "string") {
            abono = abono.includes(".") ? abono.split(".") : abono.split(",");
            abono = abono.join("");
            abono = parseInt(abono, 10);
        }
        
        let newComercio =  keyComerce == process.env.REACT_APP_KEY_COMERCE ? process.env.REACT_APP_ID_INTERES : dataBusiness.dataCard.interes.id
      
        const request = {
            producto: dataBusiness.name_prod,
            interes: { 
                id: newComercio },
            valormontorequerido: valor,
            costoPlataforma: dataBusiness.costoPlataforma,
            plazosolicitado:dataBusiness.plazo,
            datosPersona: {
                documentNumber: data.cedula,
                documentType: "CC",
                issuerDate: data.fecha_expedicion,
                firstLastName: data.apellido,
                secondLastName: "",
                contactos: [
                    {
                        cellphone: data.celular,
                        email: data.correo,
                        byDefault: false
                    }
                ]
            },
            tipoSolicitud: { id: 1 },
            sucursal: { id: data.sucursal },
            comercio: { id: dataBusiness.idComerce },
            abono: abono,
            porcentajeVsf:comerceData.porcentaje_vsf, 
            vsf:dataBusiness.dataCard.vsf, 
            seguro:0, 
            vendedor: { id: idSeller }
        }

        createRequest(request);

    }

    const createRequest = (data) => {
        createNewPreSolicitud(dispatch, data)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    store.addNotification({
                        title: "Exito!",
                        message: "Se creó la solicitud con éxito",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3500,
                            // onScreen: true
                        }

                    });
                    setStep(1)
                    setdataBusiness({})
                    setDataPersona({})
                    setopenMondal(false)
                } else {
                    store.addNotification({
                        title: "Error",
                        message: "Ocurrio un error creando la solicitud",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            // onScreen: true
                        }
                    });
                    // setopenMondal(false)
                }

            })
            .catch((err) => {
                store.addNotification({
                    title: "Error",
                    message: "¡Algo salio mal",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        // onScreen: true
                    }
                });
            })
    }

    const changeStep = () => {

        switch (step) {
            case 1:
                return <CalculadoraCreate
                    handledNext={handledNext}
                    setdataBusiness={setdataBusiness}
                    dataBusiness={dataBusiness}
                    isCatalogo={isCatalogo}
                    catalogos={catalogos}
                />

            case 2:
                return <DatosUsuario
                    handledNext={handledBack}
                    handledSendForm={handledSendForm}
                    handledBack={handledBack}
                    setDataPersona={setDataPersona}
                    dataPersona={dataPersona}
                    listSucursal={listSucursal}

                />

            default:
                break;
        }

    }

    useEffect(() => {
        if(idComerce != null){
            getSucursalList()
        }
        
        getDataComerceByCode();
    }, [idComerce])


    return (
        <>

            <div className="container-top">
                <div className="progressbar">
                    <div className={step > 0 ? "active" : "inactive"}>1</div>
                    <div className={step > 1 ? "active" : "inactive"}>2</div>
                    {/*<div className={activeSection > 2 ? "active" : "inactive"}>3</div>*/}
                </div>
            </div>
            <div>{changeStep()}</div>
        </>
    )
}
