//React
import React, { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";


//Redux
import { ID_SUCURSAL, ID_COMERCIO } from "../../app/api-client/constants";
import { useDispatch } from 'react-redux';


//Icons Material
import {
    Clear,
} from '@material-ui/icons';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';

//Components Material
import { makeStyles } from '@material-ui/core/styles';

import {
    Grid,
    Modal,
    Backdrop,
    Fade,
    Paper,
    TextField,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Button,
    FormGroup,
    FormControlLabel,
    Switch
} from '@material-ui/core';

//Local Component
import MigaPan from '../../app/orussystem/component/MigaPan';
import { HelpValidate } from '../../user/utils/helpValidate';
import { getVendedoresSucursal } from '../domain/api/PrestapolisApi';



//My style
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperModal: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #2c8aaf',
        width: 400,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 4, 3),
    },
    seeMore: {
        marginTop: theme.spacing(3),
    },
    div: {
        margin: '0 auto',
        minWidth: '40%',
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        overflow: 'auto',
        margin: '0 auto',
        flexDirection: 'column',
    },
    paperPhoto: {
        padding: theme.spacing(0),
        display: 'flex',
        margin: '0 auto',
        maxWidth: '500px',
        overflow: 'auto',
        flexDirection: 'column',
    },
    btnFactura: {
        margin: '0px !important',
        textAlign: 'center',
        justifycontent: 'center'
    },
    button: {
        color: '#ff0000 !important',
    },
    img: {
        maxWidth: '1000px',
    },
    inputPerson: {
        marginTop: '15px'
    },
}));

export default function ModalVendedorEdit({ isOpen, setopenMondal, onSubmit, data, setData, id_sucursal, onSubmitEdit}) {
    const classes = useStyles();
    const [numeroDocumento, setnumeroDocumento] = useState("")
    const [nombres, setnombres] = useState("")
    const [apellidos, setapellidos] = useState("")
    const [correo, setcorreo] = useState("")
    const [celular, setcelular] = useState("")
    const [cargo, setcargo] = useState("")
    const [estado, setEstado] = useState("")
    const [recaudo, setRecaudo] = useState("");
    

   
    //const [recaudo, setRecaudo] = useState("")
    const [reload, setreload] = useState()
    const { id } = useParams();
    const [status, setStatus] = useState(false);

    
   
    const dispatch = useDispatch();
    let idSucursal = id_sucursal;

    const { register, setError, clearErrors, watch, setValue, formState: { errors }, handleSubmit } = useForm({
        defaultValues: { recaudo: true }
      });

    const c1 = createRef();
    const c2 = createRef();
    const c3 = createRef();
    const c4 = createRef();
    const c5 = createRef();
    const c6 = createRef();
    const c7 = createRef();
    const recaudo1 = watch("recaudo");

    const submitFocus = () => {
        c1.current.focus();
        c2.current.focus();
        c3.current.focus();
        c4.current.focus();
        c5.current.focus();
        c6.current.focus();
        c7.current.focus();
    }

    React.useEffect(() => {
      
        if (!recaudo) {
          setValue("field2", false);
          
        }
      }, [recaudo, setValue]);


    const SetData = () => {
        setnumeroDocumento(data.numerocedula)
        setnombres(data.primerNombre)
        setapellidos(data.apellidos)
        setcorreo(data.email)
        setcargo(data.cargo)
        setcelular(data.celular)
        setEstado(data.estado)
        setRecaudo(data.recaudo)
    }

    const ClearForm = () => {
        setnumeroDocumento("")
        setnombres("")
        setapellidos("")
        setcorreo("")
        setcargo("")
        setcelular("")
        setEstado("")
        setRecaudo("")
    }

    const SendForm = (data) => {
        onSubmit(data)
    }

    const ExitModal = () => {
        setopenMondal(false);
        ClearForm();
        setData([])
    }


    useEffect(() => {
        SetData();
    }, [data])

    const upDateChecked = () => {
        setRecaudo((prev) => !prev);
      };

      //PRUEBA
    


    return (
        <div>
            <Modal
                open={isOpen}
                onClose={(e) => {
                    ExitModal()
                }}
                className={classes.modal}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isOpen}>
                    <div className={classes.paperModal}>
                        <Grid container spacing={1} alignItems="flex-end" textAlign="center">
                            <Grid item xs={12}>
                                <form onSubmit={handleSubmit(SendForm)} autocomplete="off">

                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                        <Grid item>
                                            <AssignmentIndIcon color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} x >
                                            <TextField
                                                placeholder="Ingresa el # de cedula del vendedor *"
                                                label="No de Cedula *"
                                                fullWidth
                                                id="cedula"
                                                name="idNumber"
                                                type="tel"
                                                autocomplete="off"
                                                value={numeroDocumento}
                                                defaultValue={numeroDocumento}
                                                {...HelpValidate().validateNumber(register('idNumber', {
                                                    required: "La cedula es requerida es requerido.",

                                                }), 8, 10, setError, clearErrors, setnumeroDocumento)}

                                                error={!!errors.idNumber}
                                                helperText={errors.idNumber?.message} />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson}>
                                        <Grid item>
                                            <AccountCircle color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} >
                                            <TextField
                                                placeholder="Escribe los nombres del vendedor * "
                                                label="Nombres *"
                                                fullWidth
                                                name="names"
                                                id="nombres"
                                                type="text"
                                                autocomplete="off"
                                                value={nombres}
                                                defaultValue={nombres}
                                                {...HelpValidate().validateText(register('names', {
                                                    required: "El Nombre es requerido.",
                                                    minLength: {
                                                        value: 3,
                                                        message: "el nombre debe tener minimo 3"
                                                    }
                                                }), setnombres)}
                                                error={!!errors.names}
                                                helperText={errors.names?.message} />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                        <Grid item>
                                            <AccountCircle color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} >
                                            <TextField
                                                placeholder="Escribe los apellidos del vendedor * "
                                                label="Apellidos *"
                                                fullWidth
                                                id="apellidos"
                                                name="apellidos"
                                                autocomplete="off"
                                                value={apellidos}
                                                defaultValue={apellidos}
                                                type="text"
                                                {...HelpValidate().validateText(register('apellidos', {
                                                    required: "El apellido es requerido.",
                                                    pattern: {
                                                        minLength: 3,
                                                        message: "el nombre apellido debe tener minimo 3 caracteres"
                                                    }
                                                }), setapellidos)}
                                                error={!!errors.apellidos}
                                                helperText={errors.apellidos?.message} />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson}>
                                        <Grid item>
                                            <ContactMailIcon color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} >
                                            <TextField
                                                placeholder="Escribe el correo electrónico  *"
                                                label="Correo electrónico * "
                                                fullWidth
                                                id="correo"
                                                name="email"
                                                type="email"
                                                value={correo}
                                                defaultValue={correo}
                                                autocomplete="off"
                                                {...HelpValidate().validateText(register('email', {
                                                    required: "E-mail es requerido.",
                                                    pattern: {
                                                        value: /\S+@\S+.\S+/,
                                                        message: "El email es requerido"
                                                    }
                                                }), setcorreo)}
                                                error={!!errors.email}
                                                helperText={errors.email?.message} />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson}>
                                        <Grid item>
                                            <SettingsPhoneIcon color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} >
                                            <TextField
                                                placeholder="Escribe el # de celular *"
                                                label="Celular *"
                                                fullWidth
                                                id="celular"
                                                name="cellPhone"
                                                autocomplete="off"
                                                type="tel"
                                                value={celular}
                                                defaultValue={celular}
                                                {...HelpValidate().validateNumber(register('cellPhone', {
                                                    required: "El Celular es requerida.",
                                                    minLength: {
                                                        value: 10,
                                                        message: 'El Celular debe tener minimo 10 caracteres'
                                                    },
                                                }), 10, 10, setError, clearErrors, setcelular)}
                                                error={!!errors.cellPhone}
                                                helperText={errors.cellPhone?.message} />
                                        </Grid>
                                    </Grid>


                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                        <Grid item>
                                            <AccountCircle color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} >
                                            <FormControl align="left" style={{ width: "100%" }}>
                                                <InputLabel id="input-select">Estado</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    name="Estado"
                                                    align="Left"
                                                    autocomplete="off"
                                                    fullWidth
                                                    defaultValue={estado}
                                                    value={estado}
                                                    {...register("estado", {
                                                        required: "Requerido",
                                                    })}
                                                    error={!!errors.estado}
                                                    helperText={errors.estado?.message}
                                                    onChange={(e) => { setEstado(e.target.value) }}

                                                >
                                                    <MenuItem value="">
                                                        <em>estado</em>
                                                    </MenuItem>
                                                    <MenuItem value="I">Inactivo</MenuItem>
                                                    <MenuItem value="A">Activo</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>


                                    <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                        <Grid item>
                                            <AccountCircle color="primary" />
                                        </Grid>
                                        <Grid item md={true} sm={true} xs={true} >
                                            <TextField
                                                placeholder="Escribe el cargo del vendedor * "
                                                label="Cargo *"
                                                fullWidth
                                                id="cargo"
                                                name="cargo"
                                                autocomplete="off"
                                                value={cargo}
                                                defaultValue={cargo}
                                                type="text"
                                                {...HelpValidate().validateText(register('cargo', {
                                                    required: "El cargo es requerido.",
                                                    pattern: {
                                                        minLength: 3,
                                                        message: "el cargo debe tener minimo 3 caracteres"
                                                    }
                                                }), setcargo)}
                                                error={!!errors.cargo}
                                                helperText={errors.cargo?.message} />
                                        </Grid>
                                    </Grid>

                                    <div className={classes.wrapper}>
                                        <Grid container justify="center" style={{ marginTop: '30px' }}>
                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                <Grid item container justify="center" alignItems="center" style={{ fontSize: 16 }}>
                                                        Habilitar para pagos{
                                                             <Switch
                                                             id="recaudo"
                                                             name="recaudo"
                                                             defaultValue={recaudo}
                                                             value={recaudo} 
                                                             onChange={(e) => {
                                                                 upDateChecked();  
                                                                 setRecaudo(e.target.value);
                                                                 
                                                             }}                                                                
                                                                 />                                
                                                            }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div className={classes.wrapper}>
                                        <Grid container justify="center" style={{ marginTop: '35px' }}>
                                            <Button
                                                // disabled={isContinuo}
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                style={{ textTransform: "none", width: "inherit" }}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                        {/* {isContinuo && <CircularProgress size={24} className={classes.buttonProgress} />} */}
                                    </div>

                                    {/* {isErrorContinue && <Alert severity="error">Usuario o Contraseña no son Validos</Alert>} */}

                                </form>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
