//React
import React, { createRef, useEffect, useState } from 'react';
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";



//Redux


//Icons Material
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//Components Material
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    FormControl,
    InputLabel,
    CircularProgress,
    Select,
    MenuItem,
    Backdrop,
    Modal,
    Fade
} from '@material-ui/core';

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { useForm } from 'react-hook-form';

//Local Component




function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 10 + rand();
    const left = 10 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

//My style
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperModal: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #2c8aaf',
        width: 450,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 4, 5),
    },
    seeMore: {
        marginTop: theme.spacing(3),
    },
    div: {
        margin: '0 auto',
        minWidth: '40%',
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        overflow: 'auto',
        margin: '0 auto',
        flexDirection: 'column',
    },
    paperPhoto: {
        padding: theme.spacing(0),
        display: 'flex',
        margin: '0 auto',
        maxWidth: '500px',
        overflow: 'auto',
        flexDirection: 'column',
    },
    btnFactura: {
        margin: '0px !important',
        textAlign: 'center',
        justifycontent: 'center'
    },
    button: {
        color: '#ff0000 !important',
    },
    img: {
        maxWidth: '1000px',
    },
    inputPerson: {
        marginTop: '15px',
        fontSize: "18px"
    },
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        color: theme.palette.grey[500],
        padding: 0.1
    },
    errorLabel: {
        color: "red",
        padding: 0,
        paddingLeft: 34,
        paddingTop: 5
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(2),
        position: 'relative',
        // paddingBottom: 20

    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #ff670080',
        width: 400,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 4, 3),
        transform: `translate(0%, -90%)`,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function ModalSelect({ isOpen, setopenMondal, name, isMulti, list, value, setValue, valueMulti, onchangeMulti, setValueMulti, handledClick, clear }) {

    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const classes = useStyles();

    const exitModal = () => {
        clear();
        setopenMondal(false)

    }


    return (
        <div>
            <Modal
                open={isOpen}
                onClose={exitModal}
                className={classes.modal}
                aria-labelledby="keep-mounted-modal-title"
                TransitionComponent={Transition}
                aria-describedby="keep-mounted-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >

                <Fade in={isOpen}>
                    <div className={classes.paper}>
                        <div id="modal-title" style={{ textAlign: "right", paddingTop: 10 }} >
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={exitModal}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ textAlign: "center" }}>Seleccionar {name}</Typography>
                        </div>

                        <div className={classes.wrapper}>
                            <Grid container justify="center" style={{ marginTop: '35px' }}>
                                <Button
                                    // disabled={loading}
                                    variant="contained"
                                    color="primary"
                                    onClick={handledClick}
                                    style={{ textTransform: "none", width: "inherit" }}>
                                    Guardar {name}
                                </Button>
                            </Grid>
                            {/* {loading && <CircularProgress size={24} className={classes.buttonProgress} />} */}
                        </div>

                        <Grid container spacing={1} alignItems="flex-end" textAlign="center" style={{ paddingBottom: "20px" }}>
                            <Grid item xs={12}>
                                {isMulti ?
                                    (
                                        <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                            <Grid item>
                                                <PlaylistAddIcon color="primary" />
                                            </Grid>
                                            <Grid item md={true} sm={true} xs={true} >
                                                {/* <InputLabel htmlFor="outlined-adornment-password">{name}</InputLabel> */}
                                                <ReactMultiSelectCheckboxes
                                                    name="estados-filter"
                                                    options={[{ label: "Seleccionar todos", value: "*" }, ...list]}
                                                    placeholderButtonLabel={`...${name}`}
                                                    // getDropdownButtonLabel={getDropdownButton}
                                                    value={valueMulti}
                                                    onChange={onchangeMulti}
                                                    setState={setValueMulti}
                                                    getDropdownButtonSelect={{ width: "800px" }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <Grid container spacing={1} alignItems="flex-end" className={classes.inputPerson} >
                                            <Grid item>
                                                <PlaylistAddIcon color="primary" />
                                            </Grid>
                                            <Grid item md={true} sm={true} xs={true} >
                                                <FormControl align="left" style={{ width: "100%" }}>
                                                    <InputLabel id="input-select">{name}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        name={name}
                                                        align="Left"
                                                        autocomplete="off"
                                                        fullWidth
                                                        defaultValue={value}
                                                        value={value}
                                                        {...register(`${name}`, {
                                                            required: "Requerido",
                                                        })}
                                                        onChange={(e) => { setValue(e.target.value) }}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Tipo</em>
                                                        </MenuItem>
                                                        {list.map((item) =>
                                                            <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                                        )}

                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                <div className={classes.errorLabel}>{errors.password__new?.message}</div>


                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}