
//React
import React, { useState, useEffect } from 'react';

//Redux
import { USER_ROL } from '../../app/api-client/constants';

//Icons Material
import {
    Clear,
} from '@material-ui/icons';

//Components Material
import {
    Grid,
    TextField,
    Button,
    Modal,
    IconButton,
    Backdrop,
    Fade,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

//Local Componet
import { writeNumber } from '../utils/validation';
import Autocomplete from '@material-ui/lab/Autocomplete';

//My style
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #ff670080',
        width: 400,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 4, 3),
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
    },
    selectEmpty: {
        // marginTop: theme.spacing(2),
    },
}));

export default function ModalAsignarMiSolicitud({
    isOpen,
    setopenMondal,
    errorFile,
    handeldClick,
    succesFile,
    setuserDni,
    userDni,
    messageErro,
    listVendedor,
    setUsuerComerce,
    vendorDNI,
    sucursalID,
    listSucursal,
    setSucursalID,
    data,
    setlistSucursalComer
}) {

    const classes = useStyles();
    let ROL = localStorage.getItem(USER_ROL)
    const [selectSucursal, setSelectSucursal] = useState({});
    const [vendedor, setVendedor] = useState("")
    const [sucursal, setSucursal] = useState("")
    const [user, setUser] = useState("")

    const clearModal = () => {
        setuserDni("");
        setSucursalID("");
        setopenMondal(false);
    }

    useEffect(() => {
      setVendedor(vendorDNI)
      setSucursal(sucursalID)
      setUser(userDni)
      setSelectSucursal(vendorDNI)
    }, [vendorDNI, sucursalID, userDni, setlistSucursalComer])
    

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={isOpen}
                onClose={(e) => {
                    clearModal();
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}

            >

                <Fade in={isOpen}>
                    <div className={classes.paper}>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            alignContent="stretch"
                            wrap="nowrap"

                        >
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                alignContent="stretch"
                                wrap="nowrap"

                            >
                                <Grid xs={3}></Grid>
                                <Grid
                                    item xs={8}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="nowrap"

                                >
                                    <h2 id="transition-modal-title">Asignar Cliente </h2></Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        color="error"
                                        aria-label="upload picture"
                                        component="span"
                                        onClick={((e) => {
                                            clearModal();
                                        })}
                                    >
                                        <Clear />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item md={true} sm={true} xs={true} >
                                <TextField
                                    id="document"
                                    label="Cedula Cliente"
                                    variant="outlined"
                                    fullWidth
                                    disabled={ROL === "ROLE_SELLER"}
                                    value={user}
                                    defaultValue={user}
                                    required
                                    onChange={((e) => {
                                        e.target.value = writeNumber(e.target.value)
                                        setuserDni(e.target.value)
                                    })}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        {ROL != "ROLE_SELLER" && <Grid container spacing={1} alignItems="flex-end">
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Grid item md={true} sm={true} xs={true} >
                                    <InputLabel htmlFor="outlined-age-native-simple">Vendedores</InputLabel>
                                    <Select
                                        native
                                        value={vendedor}
                                        defaultValue={vendedor}
                                        label="Vendedores"
                                        onChange={(e) => { setUsuerComerce(e.target.value) }}
                                        fullWidth
                                        inputProps={{
                                            name: 'Vendedores',
                                            id: 'outlined-age-native-simple',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {listVendedor.map((item) =>
                                            <option value={item.id}>{item.nombreCompleto}</option>
                                        )}
                                    </Select>
                                </Grid>
                            </FormControl>
                        </Grid>}
                        <br />
                        {ROL != "ROLE_SELLER" && <Grid container spacing={1} alignItems="flex-end">
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Grid item md={true} sm={true} xs={true} >
                                    <Autocomplete
                                        id="controllable-states-demo"
                                        options={listSucursal}
                                        getOptionLabel={(option) => option.nombre}
                                        value={selectSucursal}
                                        defaultValue={selectSucursal}
                                        onChange={(e, newValue) => {                                           
                                           setSelectSucursal(newValue);                                         
                                        }}
                                        renderInput={(params) => 
                                        <TextField {...params} label="Sucursal" variant="outlined" />}
                                    >
                                        </Autocomplete>
                                    {/*<Select
                                        native
                                        value={sucursal}
                                        defaultValue={sucursal}
                                        label="Vendedores"
                                        onChange={(e) => { setSucursalID(e.target.value) }}
                                        fullWidth
                                        inputProps={{
                                            name: 'Sucursal',
                                            id: 'outlined-age-native-simple',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {listSucursal.map((item) =>
                                            <option value={item.id}>{item.nombre}</option>
                                        )}
                                        </Select>*/}
                                </Grid>
                            </FormControl>
                        </Grid>}
                        {ROL === "ROLE_SELLER" && <Grid container spacing={1} alignItems="flex-end">
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Grid item md={true} sm={true} xs={true} >
                                    <InputLabel htmlFor="outlined-age-native-simple">Sucursal</InputLabel>
                                   <Autocomplete
                                        id="controllable-states-demo"
                                        options={listSucursal}
                                        getOptionLabel={(option) => option.nombre}
                                        value={selectSucursal}
                                        defaultValue={selectSucursal}
                                        onChange={(e, newValue) => {
                                            setSelectSucursal(newValue);
                                            setSucursalID(e.target.value)
                                        }}
                                        renderInput={(params) => 
                                        <TextField {...params} label="Sucursal" variant="outlined" />}
                                    >
                                        </Autocomplete>
                                    {/*<Select
                                        native
                                        value={sucursal}
                                        defaultValue={sucursal}
                                        label="Vendedores"
                                        onChange={(e) => { setSucursalID(e.target.value) }}
                                        fullWidth
                                        inputProps={{
                                            name: 'Sucursal',
                                            id: 'outlined-age-native-simple',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {listSucursal.map((item) =>
                                            <option value={item.id}>{item.nombre}</option>
                                        )}
                                        </Select>*/}
                                </Grid>
                            </FormControl>
                        </Grid>}
                        <br />
                        <Grid container spacing={1} alignItems="center" textAlign="center">
                            <Grid item md={true} sm={true} xs={true} >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={((e) => { handeldClick({...data, sucursalID:selectSucursal.id})  })}
                                >
                                    Asignar Cliente
                                </Button>
                            </Grid>
                        </Grid>
                        <br />
                        {errorFile && <Alert severity="error">{messageErro}</Alert>}
                        {succesFile && <Alert severity="success">¡Se te asigno la solicitud correctamente!</Alert>}

                    </div>
                </Fade>
            </Modal>
        </div>
    );
}