import React, { useEffect, useState } from 'react';
import { USER_ROL, RECAUDO, BAAS, NAME_COMERCIO } from '../../app/api-client/constants';

export const Menus = () => {

    let ROL = localStorage.getItem(USER_ROL)
    let REC = localStorage.getItem(RECAUDO)
    let baaS = localStorage.getItem(BAAS)
    let nameComercio = localStorage.getItem(NAME_COMERCIO)
    const [rol, setRol] = useState(ROL)
    const [rec, setRecaudo] = useState(REC)
    const [baas, setBaas] = useState(baaS)
    const [namecomerce, setNameComerce] = useState(nameComercio)
    
    let baS = baas == "true" || baas != "" ? namecomerce : 'Prestápolis'

    useEffect(() => {
        setRol(ROL)
        setRecaudo(REC)
        setBaas(baaS)
        setNameComerce(nameComercio)
    }, [ROL], [REC],  [baaS], [nameComercio])
   

    const RenderMenus = () => {     
        if (rec === 'true'){
            switch (rol) {
                case "ROLE_SELLER":
                    const vendedor = [
                        {
                            id: 1,
                            rol: "ROLE_SELLER",
                            icon: "adjust",
                            label: baS,
                            type: "menu",
                            to: "/",
                            component: "Home",
                            submenus: [
                                {
                                    id: 2,
                                    icon: "description",
                                    label: "Solicitudes",
                                    type: "submenu",
                                    show: true,
                                    to: "/solicitudes",
                                    component: "Solicitudes"
                                }, {
                                    id: 3,
                                    icon: "receipt",
                                    label: "Pre solicitudes",
                                    type: "submenu",
                                    show: true,
                                    to: "/Pre-solicitudes",
                                    component: "PreSolicitudes"
                                } ,{
                                    id: 4,
                                    icon: "assignmentTurnedIn",
                                    label: "Solicitudes",
                                    type: "submenu",
                                    show: false,
                                    to: "/misolicitud/asignar/:id",
                                    component: "AsignarSolicitud"
                                }, {
                                    id: 5,
                                    icon: "assignmentTurnedIn",
                                    label: "Mis Clientes ",
                                    type: "submenu",
                                    show: true,
                                    to: "/miSolicitud/",
                                    component: "MisSolicitudes",
                                }, {
                                    id: 6,
                                    icon: "personAdd",
                                    label: "Usuarios",
                                    type: "submenu",
                                    show: false,
                                    to: "/mediciones/user",
                                    component: "UserTable",
                                }
    
                            ],
    
                        },
                       
                        {
                            id: 28,
                            rol: "ROLE_SELLER",
                            icon: "description",
                            label: "Recaudos",
                            type: "submenu",
                            show: true,
                            to: "/recaudos",
                            component: "Recaudos",
                            submenus: [
                                {
                                    id: 26,
                                    icon: "personAdd",
                                    label: "Pago Manual",
                                    type: "submenu",
                                    show: false,
                                    to: "/recaudos/payment/:id",
                                    component: "InfoPayment",
                                },
                                {
                                    id: 80,
                                    icon: "personAdd",
                                    label: "Historico",
                                    type: "submenu",
                                    show: false,
                                    to: "/recaudos/historico/",
                                    component: "Historico",
                                }
                                
                            ],
                        },
                        {
                            id: 62,
                            rol: "ROLE_SELLER",
                            icon: "payment",
                            label: "Medios de pago",
                            type: "menu",
                            to: "/mediosPagos/",
                            component: "MediosPagos",
                            submenus: [
                                
                            ],
    
                        },
                        // {
                        //     id: 7,
                        //     rol: "ROLE_SELLER",
                        //     icon: "dialpad",
                        //     label: "Calculadora",
                        //     type: "menu",
                        //     to: "/calculadora/",
                        //     component: "Calculadora",
                        //     submenus: [
    
                        //     ],
    
                        // },
    
    
                    ]
                    return vendedor
    
                case "ROLE_USER":
    
                    return []
    
                //ROLE_ADMIN    
                case "ROLE_ADMIN":
                    const admin = [
                        
                        {
                            id: 8,
                            rol: "ROLE_ADMIN",
                            icon: "adjust",
                            label: "Home",
                            type: "menu",
                            to: "/",
                            component: "Home",
                            submenus: [
                                
                            ],
    
                        },
                        {
                            id: 9,
                            rol: "ROLE_ADMIN",
                            icon: "visibility",
                            label: "Administrador",
                            type: "menu",
                            to: "/admin-prestapolis/",
                            component: "AdminPrestapolis",
                            submenus: [
    
                            ],
    
                        },
                        {
                            id: 28,
                            rol: "ROLE_ADMIN",
                            icon: "description",
                            label: "Recaudos",
                            type: "submenu",
                            show: true,
                            to: "/recaudos",
                            component: "Recaudos",
                            submenus: [
                                {
                                    id: 26,
                                    icon: "personAdd",
                                    label: "Pago Manual",
                                    type: "submenu",
                                    show: false,
                                    to: "/recaudos/payment/:id",
                                    component: "InfoPayment",
                                },
                                {
                                    id: 81,
                                    icon: "personAdd",
                                    label: "Historico",
                                    type: "submenu",
                                    show: false,
                                    to: "/recaudos/historico/",
                                    component: "Historico",
                                }
                                
                            ],
                        },
                        {
                            id: 62,
                            rol: "ROLE_ADMIN",
                            icon: "payment",
                            label: "Medios de pago",
                            type: "menu",
                            to: "/mediosPagos/",
                            component: "MediosPagos",
                            submenus: [
                                
                            ],
    
                        },
    
                    ]
                    return admin
                
            //ROLE_COMMERCE_ADMIN        
                case "ROLE_COMMERCE_ADMIN":
                    const comercios = [
                        {
                            id: 10,
                            rol: "ROLE_ADMIN",
                            icon: "listAlt",
                            label: "Ventas",
                            type: "menu",
                            to: "/solicitudesCommerce/",
                            component: "ListSolicitudesCommerce",
                            submenus: [
                                
                            ],
    
    
                        },
                        {
                            id: 11,
                            rol: "ROLE_COMMERCE_ADMIN",
                            icon: "adjust",
                            label: baS,
                            type: "menu",
                            to: "/",
                            component: "Home",
                            submenus: [
                                {
                                    id: 12,
                                    icon: "description",
                                    label: "Solicitudes",
                                    type: "submenu",
                                    show: true,
                                    to: "/solicitudes",
                                    component: "Solicitudes"
                                }, {
                                    id: 13,
                                    icon: "assignmentTurnedIn",
                                    label: "Solicitudes",
                                    type: "submenu",
                                    show: false,
                                    to: "/misolicitud/asignar/:id",
                                    component: "AsignarSolicitud"
                                },{
                                    id: 14,
                                    icon: "receipt",
                                    label: "Pre solicitudes",
                                    type: "submenu",
                                    show: true,
                                    to: "/Pre-solicitudes",
                                    component: "PreSolicitudes"
                                } , {
                                    id: 15,
                                    icon: "assignmentTurnedIn",
                                    label: "Mis Clientes ",
                                    type: "submenu",
                                    show: true,
                                    to: "/miSolicitud/",
                                    component: "MisSolicitudes",
                                }, {
                                    id: 16,
                                    icon: "personAdd",
                                    label: "Usuarios",
                                    type: "submenu",
                                    show: false,
                                    to: "/mediciones/user",
                                    component: "UserTable",
                                }
                            ],
                        },
                        {
                            id: 17,
                            rol: "ROLE_ADMIN",
                            icon: "storefront",
                            label: "Sucursales",
                            type: "menu",
                            to: "/sucursales/",
                            component: "Sucursal",
                            isOpen: false,
                            submenus: [
                                {
                                    id: 18,
                                    rol: "ROLE_ADMIN",
                                    icon: "store",
                                    label: "Usuarios Sucursal",
                                    type: "submenu",
                                    show: false,
                                    to: "/vendedores-sucursal/:id",
                                    component: "ListaVendedores",
                                },
                            ],
    
                        },
    
                        {
                            id: 19,
                            rol: "ROLE_ADMIN",
                            icon: "store",
                            label: "Usuarios",
                            type: "menu",
                            to: "/listvendedores-sucursal/",
                            component: "ListaVendedoresComercio",
                            submenus: [
    
                            ],
                        },
    
                        {
                            id: 50,
                            rol:"ROLE_COMMERCE_ADMIN",
                            icon: "description",
                            label: "Recaudos",
                            type: "submenu",
                            show: true,
                            to: "/recaudos",
                            component: "Recaudos",
                            submenus: [
                                {
                                    id: 26,
                                    icon: "personAdd",
                                    label: "Pago Manual",
                                    type: "submenu",
                                    show: false,
                                    to: "/recaudos/payment/:id",
                                    component: "InfoPayment",
                                },
                                {
                                    id: 82,
                                    icon: "personAdd",
                                    label: "Historico",
                                    type: "submenu",
                                    show: false,
                                    to: "/recaudos/historico/",
                                    component: "Historico",
                                }
                                
                            ],
                        },
                        {
                            id: 63,
                            rol: "ROLE_COMMERCE_ADMIN",
                            icon: "payment",
                            label: "Medios de pago",
                            type: "menu",
                            to: "/mediosPagos/",
                            component: "MediosPagos",
                            submenus: [
                                
                            ],
    
                        },
                        // {
                        //     id: 20,
                        //     rol: "ROLE_SELLER",
                        //     icon: "dialpad",
                        //     label: "Calculadora",
                        //     type: "menu",
                        //     to: "/calculadora/",
                        //     component: "Calculadora",
                        //     submenus: [
    
                        //     ],
    
                        // },
    
                    ]
                    return comercios
                  //TERMINA - ROLE_COMMERCE_ADMIN   
    
    
               //ROLE_BRANCH_ADMIN
                case "ROLE_BRANCH_ADMIN":
                    const sucursal = [
                        {
                            id: 21,
                            rol: "ROLE_ADMIN",
                            icon: "adjust",
                            label: baS,
                            type: "menu",
                            to: "/",
                            component: "Home",
                            submenus: [
                                {
                                    id: 22,
                                    icon: "description",
                                    label: "Solicitudes",
                                    type: "submenu",
                                    show: true,
                                    to: "/solicitudes",
                                    component: "Solicitudes"
                                }, {
                                    id: 23,
                                    icon: "receipt",
                                    label: "Pre solicitudes",
                                    type: "submenu",
                                    show: true,
                                    to: "/Pre-solicitudes",
                                    component: "PreSolicitudes"
                                } ,{
                                    id: 24,
                                    icon: "assignmentTurnedIn",
                                    label: "Solicitudes",
                                    type: "submenu",
                                    show: false,
                                    to: "/misolicitud/asignar/:id",
                                    component: "AsignarSolicitud"
                                }, {
                                    id: 25,
                                    icon: "assignmentTurnedIn",
                                    label: "Mis Clientes ",
                                    type: "submenu",
                                    show: true,
                                    to: "/miSolicitud/",
                                    component: "MisSolicitudes",
                                }, {
                                    id: 26,
                                    icon: "personAdd",
                                    label: "Usuarios",
                                    type: "submenu",
                                    show: false,
                                    to: "/mediciones/user",
                                    component: "UserTable",
                                },
    
    
                            ],
    
                        },
                        {
                            id: 27,
                            rol: "ROLE_ADMIN",
                            icon: "store",
                            label: "Usuarios",
                            type: "submenu",
                            show: true,
                            to: "/vendedores/:id",
                            component: "ListaVendedores",
                            submenus: [
    
                            ],
    
                        },
                        {
                            id: 28,
                            rol: "ROLE_BRANCH_ADMIN",
                            icon: "description",
                            label: "Recaudos",
                            type: "submenu",
                            show: true,
                            to: "/recaudos",
                            component: "Recaudos",
                            submenus: [
                                {
                                    id: 26,
                                    icon: "personAdd",
                                    label: "Pago Manual",
                                    type: "submenu",
                                    show: false,
                                    to: "/recaudos/payment/:id",
                                    component: "InfoPayment",
                                },
                                {
                                    id: 83,
                                    icon: "personAdd",
                                    label: "Historico",
                                    type: "submenu",
                                    show: false,
                                    to: "/recaudos/historico/",
                                    component: "Historico",
                                }
                                
                            ],
                        },
                        {
                            id: 28,
                            rol: "ROLE_ADMIN",
                            icon: "payment",
                            label: "Medios de pago",
                            type: "menu",
                            to: "/mediosPagos/",
                            component: "MediosPagos",
                            submenus: [
                                
                            ],
    
                        },
                        // {
                        //     id: 28,
                        //     rol: "ROLE_SELLER",
                        //     icon: "dialpad",
                        //     label: "Calculadora",
                        //     type: "menu",
                        //     to: "/calculadora/",
                        //     component: "Calculadora",
                        //     submenus: [
    
                        //     ],
    
                        // },
    
    
                    ]
                    return sucursal
    
                default:
                    return []
            }
        }else {
            switch (rol) {
                case "ROLE_SELLER":
                    const vendedor = [
                        {
                            id: 1,
                            rol: "ROLE_SELLER",
                            icon: "adjust",
                            label: baS,
                            type: "menu",
                            to: "/",
                            component: "Home",
                            submenus: [
                                {
                                    id: 2,
                                    icon: "description",
                                    label: "Solicitudes",
                                    type: "submenu",
                                    show: true,
                                    to: "/solicitudes",
                                    component: "Solicitudes"
                                }, {
                                    id: 3,
                                    icon: "receipt",
                                    label: "Pre solicitudes",
                                    type: "submenu",
                                    show: true,
                                    to: "/Pre-solicitudes",
                                    component: "PreSolicitudes"
                                } ,{
                                    id: 4,
                                    icon: "assignmentTurnedIn",
                                    label: "Solicitudes",
                                    type: "submenu",
                                    show: false,
                                    to: "/misolicitud/asignar/:id",
                                    component: "AsignarSolicitud"
                                }, {
                                    id: 5,
                                    icon: "assignmentTurnedIn",
                                    label: "Mis Clientes ",
                                    type: "submenu",
                                    show: true,
                                    to: "/miSolicitud/",
                                    component: "MisSolicitudes",
                                }, {
                                    id: 6,
                                    icon: "personAdd",
                                    label: "Usuarios",
                                    type: "submenu",
                                    show: false,
                                    to: "/mediciones/user",
                                    component: "UserTable",
                                },
                                {
                                    id: 60,
                                    rol: "ROLE_ADMIN",
                                    icon: "payment",
                                    label: "Medios de pago",
                                    type: "menu",
                                    to: "/mediosPagos/",
                                    component: "MediosPagos",
                                    submenus: [
                                        
                                    ],
            
                                },
    
                            ],
    
                        },
                       
                     
                        // {
                        //     id: 7,
                        //     rol: "ROLE_SELLER",
                        //     icon: "dialpad",
                        //     label: "Calculadora",
                        //     type: "menu",
                        //     to: "/calculadora/",
                        //     component: "Calculadora",
                        //     submenus: [
    
                        //     ],
    
                        // },
    
    
                    ]
                    return vendedor
    
                case "ROLE_USER":
    
                    return []
    
                //ROLE_ADMIN    
                case "ROLE_ADMIN":
                    const admin = [
                        
                        {
                            id: 8,
                            rol: "ROLE_ADMIN",
                            icon: "adjust",
                            label: "Home",
                            type: "menu",
                            to: "/",
                            component: "Home",
                            submenus: [
                                
                            ],
    
                        },
                        {
                            id: 9,
                            rol: "ROLE_ADMIN",
                            icon: "visibility",
                            label: "Administrador",
                            type: "menu",
                            to: "/admin-prestapolis/",
                            component: "AdminPrestapolis",
                            submenus: [
    
                            ],
    
                        },
                        {
                            id: 61,
                            rol: "ROLE_ADMIN",
                            icon: "payment",
                            label: "Medios de pago",
                            type: "menu",
                            to: "/mediosPagos/",
                            component: "MediosPagos",
                            submenus: [
                                
                            ],
    
                        },
                       
    
                    ]
                    return admin
                
            //ROLE_COMMERCE_ADMIN        
                case "ROLE_COMMERCE_ADMIN":
                    const comercios = [
                        {
                            id: 10,
                            rol: "ROLE_ADMIN",
                            icon: "listAlt",
                            label: "Ventas",
                            type: "menu",
                            to: "/solicitudesCommerce/",
                            component: "ListSolicitudesCommerce",
                            submenus: [
                                
                            ],
    
    
                        },
                        {
                            id: 11,
                            rol: "ROLE_COMMERCE_ADMIN",
                            icon: "adjust",
                            label: baS,
                            type: "menu",
                            to: "/",
                            component: "Home",
                            submenus: [
                                {
                                    id: 12,
                                    icon: "description",
                                    label: "Solicitudes",
                                    type: "submenu",
                                    show: true,
                                    to: "/solicitudes",
                                    component: "Solicitudes"
                                }, {
                                    id: 13,
                                    icon: "assignmentTurnedIn",
                                    label: "Solicitudes",
                                    type: "submenu",
                                    show: false,
                                    to: "/misolicitud/asignar/:id",
                                    component: "AsignarSolicitud"
                                },{
                                    id: 14,
                                    icon: "receipt",
                                    label: "Pre solicitudes",
                                    type: "submenu",
                                    show: true,
                                    to: "/Pre-solicitudes",
                                    component: "PreSolicitudes"
                                } , {
                                    id: 15,
                                    icon: "assignmentTurnedIn",
                                    label: "Mis Clientes ",
                                    type: "submenu",
                                    show: true,
                                    to: "/miSolicitud/",
                                    component: "MisSolicitudes",
                                }, {
                                    id: 16,
                                    icon: "personAdd",
                                    label: "Usuarios",
                                    type: "submenu",
                                    show: false,
                                    to: "/mediciones/user",
                                    component: "UserTable",
                                }
                            ],
                        },
                        {
                            id: 17,
                            rol: "ROLE_ADMIN",
                            icon: "storefront",
                            label: "Sucursales",
                            type: "menu",
                            to: "/sucursales/",
                            component: "Sucursal",
                            isOpen: false,
                            submenus: [
                                {
                                    id: 18,
                                    rol: "ROLE_ADMIN",
                                    icon: "store",
                                    label: "Usuarios Sucursal",
                                    type: "submenu",
                                    show: false,
                                    to: "/vendedores-sucursal/:id",
                                    component: "ListaVendedores",
                                },
                            ],
    
                        },
                        {
                            id: 66,
                            rol: "ROLE_COMMERCE_ADMIN",
                            icon: "payment",
                            label: "Medios de pago",
                            type: "menu",
                            to: "/mediosPagos/",
                            component: "MediosPagos",
                            submenus: [
                                
                            ],
    
                        },
    
                        {
                            id: 19,
                            rol: "ROLE_ADMIN",
                            icon: "store",
                            label: "Usuarios",
                            type: "menu",
                            to: "/listvendedores-sucursal/",
                            component: "ListaVendedoresComercio",
                            submenus: [
    
                            ],
                        },
    
                  
                        // {
                        //     id: 20,
                        //     rol: "ROLE_SELLER",
                        //     icon: "dialpad",
                        //     label: "Calculadora",
                        //     type: "menu",
                        //     to: "/calculadora/",
                        //     component: "Calculadora",
                        //     submenus: [
    
                        //     ],
    
                        // },
    
                    ]
                    return comercios
                  //TERMINA - ROLE_COMMERCE_ADMIN   
    
    
               //ROLE_BRANCH_ADMIN
                case "ROLE_BRANCH_ADMIN":
                    const sucursal = [
                        {
                            id: 21,
                            rol: "ROLE_ADMIN",
                            icon: "adjust",
                            label: baS,
                            type: "menu",
                            to: "/",
                            component: "Home",
                            submenus: [
                                {
                                    id: 22,
                                    icon: "description",
                                    label: "Solicitudes",
                                    type: "submenu",
                                    show: true,
                                    to: "/solicitudes",
                                    component: "Solicitudes"
                                }, {
                                    id: 23,
                                    icon: "receipt",
                                    label: "Pre solicitudes",
                                    type: "submenu",
                                    show: true,
                                    to: "/Pre-solicitudes",
                                    component: "PreSolicitudes"
                                } ,{
                                    id: 24,
                                    icon: "assignmentTurnedIn",
                                    label: "Solicitudes",
                                    type: "submenu",
                                    show: false,
                                    to: "/misolicitud/asignar/:id",
                                    component: "AsignarSolicitud"
                                }, {
                                    id: 25,
                                    icon: "assignmentTurnedIn",
                                    label: "Mis Clientes ",
                                    type: "submenu",
                                    show: true,
                                    to: "/miSolicitud/",
                                    component: "MisSolicitudes",
                                }, {
                                    id: 26,
                                    icon: "personAdd",
                                    label: "Usuarios",
                                    type: "submenu",
                                    show: false,
                                    to: "/mediciones/user",
                                    component: "UserTable",
                                },
                               
    
    
                            ],
    
                        },
                        {
                            id: 27,
                            rol: "ROLE_ADMIN",
                            icon: "store",
                            label: "Usuarios",
                            type: "submenu",
                            show: true,
                            to: "/vendedores/:id",
                            component: "ListaVendedores",
                            submenus: [
    
                            ],
    
                        },
                      
                        {
                            id: 28,
                            rol: "ROLE_ADMIN",
                            icon: "payment",
                            label: "Medios de pago",
                            type: "menu",
                            to: "/mediosPagos/",
                            component: "MediosPagos",
                            submenus: [
                                
                            ],
    
                        },
                        // {
                        //     id: 28,
                        //     rol: "ROLE_SELLER",
                        //     icon: "dialpad",
                        //     label: "Calculadora",
                        //     type: "menu",
                        //     to: "/calculadora/",
                        //     component: "Calculadora",
                        //     submenus: [
    
                        //     ],
    
                        // },
    
    
                    ]
                    return sucursal
    
                default:
                    return []
            }
            

        }
    
       
        }
        
        return (RenderMenus())

}
